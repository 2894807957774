import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit, AddCircle, RemoveRedEye} from "@mui/icons-material";

const RowCommodity = ({ commodity, index, deleteCommodityHandle, setSelectedCommodity, setPage, setShowModalPrice, setShowPrices}) => {
    const handleEdit = () => {
        setSelectedCommodity(commodity);
        setPage('editCommodity');
    }

    const handleAdd = () => {
        setSelectedCommodity(commodity);
        setShowModalPrice(true);
    }

    const handleViewPrices = () => {
        setSelectedCommodity(commodity);
        setShowPrices(true);
    }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{commodity?.category?.name + " " + commodity?.category?.description}</TableCell>
      <TableCell>{commodity?.family}</TableCell>
      <TableCell>{commodity?.description}</TableCell>
      <TableCell>{commodity?.unit}</TableCell>
      <TableCell>
        <div className="flex-center">
            <IconButton onClick={() => handleAdd()}>
                <AddCircle color="primary"/>
            </IconButton>
            <IconButton onClick={() => handleViewPrices()}>
                <RemoveRedEye color="primary"/>
            </IconButton>
            <IconButton onClick={() => handleEdit()}>
                <Edit color="primary"/>
            </IconButton>
              <IconButton onClick={() => deleteCommodityHandle(commodity)}>
                <Delete color="primary" />
              </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default RowCommodity;