import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getUnitCommodities =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "unitCommodities")));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener unidad de commodities")
    }
}

export const createUnitCommodity = async (unitCommodity) => {
    try{
        const docRef = await addDoc(collection(db, "unitCommodities"), unitCommodity);
        return {...unitCommodity, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la unidad de commodity")
    }
}

export const updateUnitCommodity = async (unitCommodity) => {
    try{
        await updateDoc(doc(db, "unitCommodities", unitCommodity.id), unitCommodity);
        return unitCommodity
    }
    catch(error){
        throw new Error("No se pudo actualizar la unidad de commodity")
    }
}

export const deleteUnitCommodity = async (unitCommodity) => {
    try{
        await deleteDoc(doc(db, "unitCommodities", unitCommodity.id));
        return unitCommodity
    }
    catch(error){
        throw new Error("No se pudo eliminar la unidad de commodity")
    }
}