import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {getIncoterms, createIncoterm, updateIncoterm, deleteIncoterm} from "../api/incoterms";
import ListIncoterms from "../components/Incoterms/ListIncoterms";
import NewIncoterm from "../components/Incoterms/NewIncoterm";
import EditIncoterm from "../components/Incoterms/EditIncoterm";
const Incoterms = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [incoterms, setIncoterms] = useState([])
    const [page, setPage] = useState('listIncoterms');
    const [selectedIncoterm, setSelectedIncoterm] = useState(null);
    const {data: dataIncoterms, isLoading: isLoadingIncoterms} = useQuery('incoterms', getIncoterms, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: createIncotermMutation, isLoading: isLoadingCreateIncoterm} = useMutation(createIncoterm, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
            setIncoterms([...incoterms, data])
            queryClient.invalidateQueries('incoterms')
            setPage('listIncoterms')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateIncotermMutation, isLoading: isLoadingUpdateIncoterm} = useMutation(updateIncoterm, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyIncoterms = [...incoterms]
            const index = copyIncoterms.findIndex(p => p.id === data.id)
            copyIncoterms[index] = data
            setIncoterms(copyIncoterms)
            queryClient.invalidateQueries('incoterms')
            setPage('listIncoterms')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: deleteIncotermMutation, isLoading: isLoadingDeleteIncoterm} = useMutation(deleteIncoterm, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyIncoterms = [...incoterms]
            const index = copyIncoterms.findIndex(p => p.id === data.id)
            copyIncoterms.splice(index, 1)
            setIncoterms(copyIncoterms)
            queryClient.invalidateQueries('incoterms')
            setPage('listIncoterms')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if(dataIncoterms){
            setIncoterms(dataIncoterms)
        }
    }, [dataIncoterms]);

    const createIncotermHandle = (incoterm) => {
        const answer = window.confirm(`¿Está seguro de agregar la condición de venta "${incoterm.code}"?`);
        if (!answer) {return}
        createIncotermMutation(incoterm)
    }

    const updateIncotermHandle = (incoterm) => {
        const answer = window.confirm(`¿Está seguro de actualizar la condición de venta "${incoterm.code}"?`);
        if (!answer) {return}
        updateIncotermMutation(incoterm)
    }

    const deleteIncotermHandle = (incoterm) => {
        const answer = window.confirm(`¿Está seguro de eliminar la condición de venta "${incoterm.code}"?`);
        if (!answer) {return}
        deleteIncotermMutation(incoterm)
    }

    return (
        <>
            {page === 'listIncoterms' &&
                <ListIncoterms incoterms={incoterms} isLoading={isLoadingIncoterms || isLoadingDeleteIncoterm} setPage={setPage} setSelectedIncoterm={setSelectedIncoterm} deleteIncotermHandle={deleteIncotermHandle} />
            }
            {page === 'newIncoterm' &&
                <NewIncoterm setPage={setPage} createIncotermHandle={createIncotermHandle} isLoading={isLoadingCreateIncoterm}/>
            }
            {page === 'editIncoterm' &&
                <EditIncoterm incoterm={selectedIncoterm} setPage={setPage} updateIncotermHandle={updateIncotermHandle} isLoading={isLoadingUpdateIncoterm}/>
            }
        </>
    )
}

export default Incoterms