import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem, Button
} from '@mui/material';
import NewProduct from "../components/Products/NewProduct";
import ListProducts from "../components/Products/ListProducts";
import ViewProduct from "../components/Products/ViewProduct";
import EditProduct from "../components/Products/EditProduct";
import {useQuery, useMutation, useQueryClient} from "react-query";
import {getSuppliers} from "../api/suppliers";
import {getProductsBySupplier, createProduct, updateProduct, deleteProduct} from "../api/products";
import {useSnackbar} from "../components/Snackbar/useSnackbar";

const Products = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [supplierId, setSupplierId] = useState("");
    const priceRangesInitial =  {
        "inicial": {from: "1", to: "", price: ""},
        "negocio": {from: "", to: "", price: ""},
        "volumen": {from: "", to: "", price: ""},
        "lista": {from: "", to: "", price: ""},
        "endDate": new Date(),
        "startDate": new Date()
    }

    const {data: dataSuppliers, isLoading: isLoadingSuppliers} = useQuery(
        'suppliers', getSuppliers, {
            staleTime: 3600000,
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
        });
    const {data: dataProductsBySupplier, isLoading: isLoadingProductsBySupplier} = useQuery(
        ['productsBySupplier', supplierId], () => getProductsBySupplier(supplierId), {
            staleTime: 3600000,
            enabled: supplierId !== "",
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
        });
    const {mutate: createProductMutation, isLoading: isLoadingCreateProduct} = useMutation(createProduct, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se creó correctamente', severity: 'success'})
            const productSupplier = data.supplier.id
            setSupplier(data.supplier.name)
            if(supplierId !== productSupplier){
                setSupplierId(productSupplier)
            }
            queryClient.invalidateQueries('productsBySupplier')
            setPage('listProducts')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateProductMutation, isLoading: isLoadingUpdateProduct} = useMutation(updateProduct, {
            onSuccess: (data) => {
                setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
                const productSupplier = data.supplier.id
                setSupplier(data.supplier.name)
                if(supplierId !== productSupplier){
                    setSupplierId(productSupplier)
                }
                queryClient.invalidateQueries('productsBySupplier')
                setRenewPrice(null)
                setPage('listProducts')
            },
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
    });
    const {mutate: deleteProductMutation, isLoading: isLoadingDeleteProduct} = useMutation(deleteProduct, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            queryClient.setQueryData(['productsBySupplier', data.supplier.id], dataProductsBySupplier.filter(product => product.id !== data.id))
            setPage('listProducts')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const [page, setPage] = useState("listProducts");
    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [priceRanges, setPriceRanges] = useState(selectedProduct?.priceRanges || [priceRangesInitial])
    const [renewPrice, setRenewPrice] = useState( null)

    useEffect(() => {
        if(dataSuppliers) {
            const suppliersVisible = dataSuppliers.filter(supplier => !supplier?.hide);
            setSuppliers(suppliersVisible);
            setSupplierId(suppliersVisible[0]?.id)
            setSupplier(suppliersVisible[0]?.name);
        }
    }, [dataSuppliers]);

    useEffect(() => {
        setPriceRanges(selectedProduct?.priceRanges || [priceRangesInitial])
    }, [selectedProduct]);

    const handleSupplier = (e, action) => {
        setSupplier(e.target.value);
        setSupplierId(action?.props?.data?.id || "")
    };

    const addPrices = () => {
        let newPrice = null
        if(typeof selectedProduct?.currentPrice === "object"){
            newPrice = selectedProduct?.currentPrice
        }
        if(typeof selectedProduct?.currentPrice === "string"){
            const copySelectedProducto = JSON.parse(JSON.stringify(selectedProduct?.priceRanges))
            newPrice = copySelectedProducto.slice(-1)[0]
        }
        newPrice["startDate"] = new Date()
        newPrice["endDate"] = new Date()
        setRenewPrice(newPrice)
    }

    const deleteRenewPrice = () => {
        setRenewPrice(null)
    }

    const deleteProductHandle = (product) => {
        const answer = window.confirm(`¿Está seguro de eliminar el producto "${product.name}"?`);
        if (!answer) {return}
        deleteProductMutation(product)
    }

    const updateProductHandle = (product) => {
        const answer = window.confirm(`¿Está seguro de actualizar el producto "${product.name}"?`);
        if (!answer) {return}
        updateProductMutation(product)
    }

    const createProductHandle = (product) => {
        const answer = window.confirm(`¿Está seguro de agregar el producto "${product.name}"?`);
        if (!answer) {return}
        createProductMutation(product)
    }

    const backPage = () => {
        setRenewPrice(null)
        setPage('listProducts')
    }

    const SelectSupplier = () => (
        <FormControl fullWidth required sx={{marginRight: "30px", width: "300px"}}>
            <InputLabel id="supplier-select-label" size="small">Supplier</InputLabel>
            <Select
                labelId="supplier-select-label"
                id="Supplier-select"
                value={supplier}
                label="Supplier"
                onChange={handleSupplier}
                size="small"
            >
                {isLoadingSuppliers ? <MenuItem value=""><em>Cargando "Suppliers"...</em></MenuItem> :
                    suppliers.map(supplierI => (
                        <MenuItem key={"supp"+supplierI.id} value={supplierI.name} data={supplierI}>{supplierI.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )

    const addProduct = () => {
        setPriceRanges([priceRangesInitial])
        setPage("newProduct")
    }

    const RenewPrice = () => (
            renewPrice === null ?
                <Button variant="outlined" sx={{marginTop: "20px", marginRight: "5px"}} onClick={() => addPrices()}>Renovar Precio</Button>
                :
                <Button color="secondary" variant="outlined" sx={{marginTop: "20px", marginRight: "5px"}} onClick={() => deleteRenewPrice()}>Quitar Renovar Precio</Button>
        )

    return <>
        {page === "listProducts" &&
            <ListProducts data={dataProductsBySupplier}
                isLoading={isLoadingProductsBySupplier || isLoadingDeleteProduct || isLoadingSuppliers}
                deleteProductHandle={deleteProductHandle}
                addProduct={addProduct}
                setSelectedProduct={setSelectedProduct}
                SelectSupplier={SelectSupplier}
                supplier={supplier}
                setPage={setPage}
                priceRanges={priceRanges}
            />
        }
        {page === "newProduct" &&
            <NewProduct
                suppliers={suppliers}
                createProductHandle={createProductHandle}
                isLoading={isLoadingCreateProduct}
                setSnackbar={setSnackbar}
                priceRanges={priceRanges}
                setPriceRanges={setPriceRanges}
                renewPrice={renewPrice}
                setRenewPrice={setRenewPrice}
                backPage={backPage}
            />
        }
        {page === "viewProduct" &&
            <ViewProduct
                product={selectedProduct}
                backPage={backPage}
            />
        }
        {page === "editProduct" &&
            <EditProduct
                product={selectedProduct}
                suppliers={suppliers}
                updateProductHandle={updateProductHandle}
                isLoading={isLoadingUpdateProduct}
                setSnackbar={setSnackbar}
                RenewPrice={RenewPrice}
                renewPrice={renewPrice}
                setRenewPrice={setRenewPrice}
                priceRanges={priceRanges}
                setPriceRanges={setPriceRanges}
                backPage={backPage}
            />
        }
    </>
}

export default Products;