import RowIncoterm from "./RowIncoterm";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListIncoterms = (props) => {
  const { incoterms, isLoading, setPage, setSelectedIncoterm, deleteIncotermHandle } = props;

  return (
      <GenericCard className='mt-12' title='Lista de Unidades'>
          <Box>
              <Button className sx={{marginBottom: "30px"}} variant='outlined' onClick={() => setPage('newIncoterm')}>Agregar Condición de Venta</Button>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Nombre (opcional)</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {isLoading ? <TableRow className="m-12"><CircularProgress/>Cargando información...</TableRow> :
                      incoterms?.map((incoterm, index) => <RowIncoterm incoterm={incoterm} index={index} key={incoterm.id} setSelectedIncoterm={setSelectedIncoterm} setPage={setPage} deleteIncotermHandle={deleteIncotermHandle} />)}
                  </TableBody>
              </Table>
          </Box>
      </GenericCard>
  )
}

export default ListIncoterms;