import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getFamilyCommodities =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "familyCommodities")));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener familia de commodities")
    }
}

export const createFamilyCommodity = async (familyCommodity) => {
    try{
        const docRef = await addDoc(collection(db, "familyCommodities"), familyCommodity);
        return {...familyCommodity, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la familia de commodity")
    }
}

export const updateFamilyCommodity = async (familyCommodity) => {
    try{
        await updateDoc(doc(db, "familyCommodities", familyCommodity.id), familyCommodity);
        return familyCommodity
    }
    catch(error){
        throw new Error("No se pudo actualizar la familia de commodity")
    }
}

export const deleteFamilyCommodity = async (familyCommodity) => {
    try{
        await deleteDoc(doc(db, "familyCommodities", familyCommodity.id));
        return familyCommodity
    }
    catch(error){
        throw new Error("No se pudo eliminar la familia de commodity")
    }
}