import {
    CircularProgress,
    TableContainer,
    Table,
    Box,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import RowSupplier from './RowSupplier';
const ListSuppliers = ({suppliers, viewSupplier, disabled, isLoading}) => {
    return <TableContainer component={Box}>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>N.</TableCell>
                <TableCell>Correo</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Página Web</TableCell>
                <TableCell>Oculto</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        {isLoading ? <div className="flex-center gap-12 m-12"><CircularProgress/><span>Cargando información...</span></div> :
            <TableBody>
                {suppliers.map((supplier, index) => <RowSupplier supplier={supplier} index={index} key={supplier.name} viewSupplier={viewSupplier} disabled={disabled} />)}
            </TableBody>
        }
    </Table>
</TableContainer>;
}

export default ListSuppliers;