import React, {useState} from 'react';
import {
    TableCell, FormControl, FormControlLabel, FormLabel, Typography,
    RadioGroup, TextField, Radio, Button, TableRow,
} from '@mui/material';
import {TablePrices} from "../Products/TablePrices";
import {format, parseISO} from "date-fns";

const RowPaymentOrder = (props) => {
    const {order, index, quantity, setIsPrint, updateOrderHandle, setSelectedOrder, setPage, setOrderToPrint} = props;
    const {product} = order;
    const priceFinal = order?.priceFinal || "";
    const [discount, setDiscount] = useState(order?.discount || 0);
    const [typeDiscount, setTypeDiscount] = useState(order?.typeDiscount || "")
    const deliveryDate = order?.deliveryDate ? parseISO(order?.deliveryDate) :  null;
    const volumenFinal = order?.volumenFinal

    const updateOrder = () => {
        const orderInfo = {
            priceFinal,
            discount,
            typeDiscount,
            volumenFinal,
            deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : null,
            id: order.id
        }
        updateOrderHandle(orderInfo, order.product)
    }

    const handleLiquitation = () => {
        setSelectedOrder(order)
        setPage("editLiquitation")
    }

    const handlePrintOrder = () => {
        setSelectedOrder(order)
        setOrderToPrint({index, quantity})
        setIsPrint(true)
    }

    const getState = (state) => {
        let message = "Estado: "
        switch (state) {
            case "open":
                return message += "En Negociacion";
            case "pending":
                return message += "Pendiente envio del banco";
            case "sent":
                return message += "Enviado al banco";
            case "paid":
                return message += "Pagado";
            default:
                return message += " --";
        }
    }

    return <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
            <Typography variant="body2" sx={{marginBottom: "20px"}}>{order?.product?.name}</Typography>
            <Typography variant="body2">{order?.user?.company?.name}</Typography>
            <Typography variant="body2">{order?.user?.name}</Typography>
            <Typography variant="caption">Origen: {order?.orderOrigin === "zoho" ? "Zoho" : "MyAlaxs"}</Typography><br/>
            <Typography variant="caption">{getState(order?.state)}</Typography>
        </TableCell>
        <TableCell>
            {order?.quantity} {order?.product?.unit?.symbol}
        </TableCell>
        <TableCell>
            {typeof order?.currentPrice === "string" ? order?.currentPrice :
                typeof order?.currentPrice === "object" ?
                    <TablePrices data={order?.currentPrice} product={product}/> :
                    product?.priceRanges?.length > 0 && product?.priceRanges?.map((priceRange, i) => (
                        <TablePrices key={"priceRangeV" + i} data={priceRange} product={product}/>
                    ))}
        </TableCell>
        <TableCell>
            <FormControl>
                <FormLabel sx={{fontSize: "14px"}} id="demo-form-control-label-placement">Tipo de descuento:</FormLabel>
                <RadioGroup name="typeDiscount"
                            value={typeDiscount}
                            onChange={(e) => setTypeDiscount(e.target.value)}
                >
                    <FormControlLabel value="currency" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} label="Moneda"/>
                    <FormControlLabel value="percentage" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} label="Porcentaje"/>
                </RadioGroup>
            </FormControl>
            <TextField size="small" label="Ingrese Descuento" value={discount} onChange={(e) => setDiscount(e.target.value)}
                       sx={{marginTop: '20px'}} fullWidth name="discount"/>
        </TableCell>
        <TableCell>
            <Typography variant="caption">
                <strong>Volumen Final: </strong><br/>{order.volumenFinal}<br/>
            </Typography>
            <Typography sx={{marginBottom: "20px"}} variant="caption">
                <strong>Precio Final: </strong><br/>{order.priceFinal ? order.priceFinal : "--"} {order.product?.currency ?? "--"} {order.product?.incoterm}<br/>
            </Typography>
            <Typography sx={{marginTop: "30px"}} variant="caption">
                <strong>Delivery Date: </strong><br/>{order.deliveryDate ? format(parseISO(order.deliveryDate), "PP") : "--"}
            </Typography>
        </TableCell>
        <TableCell>
            <div style={{display: "flex", flexDirection: "column"}} className="hide-print">
                <Button variant='outlined' size="small" onClick={() => handlePrintOrder()}>Imprimir</Button>
                <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => handleLiquitation()}>Liquidacion</Button>
                <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => updateOrder()}>Guardar</Button>
                { order?.state === "open" &&
                    <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => updateOrder()}>Facturar</Button>
                }
            </div>
        </TableCell>
    </TableRow>
}

export default RowPaymentOrder