import { createSlice } from "@reduxjs/toolkit";

export const zohoUserSlice = createSlice({
    name: 'user',
    initialState: {
        id: undefined,
        email: undefined,
        loggedIn: undefined,
        role: undefined,
        profile: undefined,
        active: undefined,
        error: undefined,
        emailVerified: undefined
    },
    reducers: {
        login: (state, action) => {
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.loggedIn = true;
            state.emailVerified = true;
        },
        logout: (state) => {
            state.loggedIn = false;
        },
        setInfo: (state, action) => {
            state.role = action.payload.role;
            state.profile = action.payload.profile;
            state.active = action.payload.active;
        },
        setError: (state, action) => {
            state.error = action.payload.error;
        }
    }
})

export const { login, logout, setInfo, setError } = zohoUserSlice.actions;
export default zohoUserSlice.reducer;