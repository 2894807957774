import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getCommodities =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "commodities")));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener commodities")
    }
}

export const createCommodity = async (commodity) => {
    try{
        const docRef = await addDoc(collection(db, "commodities"), commodity);
        return {...commodity, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar el commodity")
    }
}

export const updateCommodity = async (commodity) => {
    try{
        await updateDoc(doc(db, "commodities", commodity.id), commodity);
        return commodity
    }
    catch(error){
        throw new Error("No se pudo actualizar el commodity")
    }
}

export const deleteCommodity = async (commodity) => {
    try{
        await deleteDoc(doc(db, "commodities", commodity.id));
        return commodity
    }
    catch(error){
        throw new Error("No se pudo eliminar el commodity")
    }
}
