import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {getCommodities, createCommodity, updateCommodity, deleteCommodity} from "../api/commodities";
import ListCommodities from "../components/Commodities/ListCommodities";
import NewCommodity from "../components/Commodities/NewCommodity";
import EditCommodity from "../components/Commodities/EditCommodity";
import FamilyCommodities from "./FamilyCommodities";
import CategoryCommodities from "./CategoryCommodities";
import UnitCommodities from "./UnitCommodities";
import ModalPriceComodity from "../components/Commodities/ModalPriceComodity";
import {Tab, Tabs, SwipeableDrawer, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, Divider} from "@mui/material";
import {Article, Sell} from "@mui/icons-material";

const Commodities = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [commodities, setCommodities] = useState([])
    const [page, setPage] = useState('listCommodities');
    const [selectedCommodity, setSelectedCommodity] = useState(null);
    const [tabIndex, setTabIndex] = useState("commodities");
    const [showModalPrice, setShowModalPrice] = useState(false);
    const [showPricesCommodity, setShowPricesCommodity] = useState(false);
    const {data: dataCommodities, isLoading: isLoadingCommodities} = useQuery('commodities', getCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: createCommodityMutation, isLoading: isLoadingCreateCommodity} = useMutation(createCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
            setCommodities([...commodities, data])
            queryClient.invalidateQueries('commodities')
            setPage('listCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateCommodityMutation, isLoading: isLoadingUpdateCommodity} = useMutation(updateCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyCommodities = [...commodities]
            const index = copyCommodities.findIndex(p => p.id === data.id)
            copyCommodities[index] = data
            setCommodities(copyCommodities)
            queryClient.invalidateQueries('commodities')
            setPage('listCommodities')
            if(showModalPrice) setShowModalPrice(!showModalPrice)
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: deleteCommodityMutation, isLoading: isLoadingDeleteCommodity} = useMutation(deleteCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyCommodities = [...commodities]
            const index = copyCommodities.findIndex(p => p.id === data.id)
            copyCommodities.splice(index, 1)
            setCommodities(copyCommodities)
            queryClient.invalidateQueries('commodities')
            setPage('listCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if(dataCommodities){
            setCommodities(dataCommodities)
        }
    }, [dataCommodities]);

    const createCommodityHandle = (commodity) => {
        const answer = window.confirm(`¿Está seguro de agregar el commodity "${commodity.description}"?`);
        if (!answer) {return}
        createCommodityMutation(commodity)
    }

    const updateCommodityHandle = (commodity) => {
        const answer = window.confirm(`¿Está seguro de actualizar el commodity "${selectedCommodity.category?.name}"?`);
        if (!answer) {return}
        updateCommodityMutation(commodity)
    }

    const deleteCommodityHandle = (commodity) => {
        const answer = window.confirm(`¿Está seguro de eliminar el commodity "${commodity.description}"?`);
        if (!answer) {return}
        deleteCommodityMutation(commodity)
    }

    return (
        <>
            <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                <Tab label="Registros" value="commodities"/>
                <Tab label="Familias" value="families"/>
                <Tab label="Commodities" value="categories"/>
                <Tab label="Unidades" value="units"/>
            </Tabs>
            {showModalPrice &&
                <ModalPriceComodity setShowModalPrice={setShowModalPrice} commodity={selectedCommodity} updateCommodityHandle={updateCommodityHandle} />
            }
            <SwipeableDrawer
                anchor="right"
                open={showPricesCommodity}
                onClose={() => setShowPricesCommodity(false)}
                onOpen={() => setShowPricesCommodity(true)}
            >
                <Box sx={{ width: 300}}>
                    <List>
                        <ListItem>
                            <ListItemIcon><Article/></ListItemIcon>
                            <ListItemText
                                primary={"Commodity:"}
                                secondary={selectedCommodity?.category?.name + " " + selectedCommodity?.category?.description}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <Sell/>
                            </ListItemIcon>
                            <ListItemText primary={"Historial de precios"}/>
                        </ListItem>
                        {selectedCommodity?.prices.map((text, index) => (
                            <ListItem key={text}>
                                <ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar sx={{width: "25px", height: "25px", fontSize: "16px"}}>{index + 1}</Avatar>
                                    </ListItemAvatar>
                                </ListItemIcon>
                                <ListItemText primary={"Valor: " + text?.price + " " + selectedCommodity?.unit}
                                    secondary={"Fecha: " + new Date(text?.date).toLocaleDateString()}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </SwipeableDrawer>
            {tabIndex === 'commodities' &&
                <>
                    {page === 'listCommodities' &&
                        <ListCommodities commodities={dataCommodities} isLoading={isLoadingCommodities || isLoadingDeleteCommodity} setPage={setPage} setSelectedCommodity={setSelectedCommodity} deleteCommodityHandle={deleteCommodityHandle} setShowModalPrice={setShowModalPrice} setShowPrices={setShowPricesCommodity}/>
                    }
                    {page === 'newCommodity' &&
                        <NewCommodity setPage={setPage} createCommodityHandle={createCommodityHandle} isLoading={isLoadingCreateCommodity}/>
                    }
                    {page === 'editCommodity' &&
                        <EditCommodity commodity={selectedCommodity} setPage={setPage} updateCommodityHandle={updateCommodityHandle} isLoading={isLoadingUpdateCommodity}/>
                    }
                </>
            }
            {tabIndex === 'families' &&
                <FamilyCommodities />
            }
            {tabIndex === 'categories' &&
                <CategoryCommodities />
            }
            {tabIndex === 'units' &&
                <UnitCommodities />
            }
        </>
    )
}

export default Commodities;