import RowOrder from "./RowPaymentOrder";
import { Box, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";
const PaymentOrders = (props) => {
    const { SelectClient, isLoading, data, quantity, updateOrderHandle, setSelectedOrder, setPage, setIsPrint, setOrderToPrint} = props;

    return (
        <GenericCard title="Lista de Pedidos">
            <Box sx={{marginTop: "10px"}}>
                <div className="flex flex-row justify-between">
                    <SelectClient/>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N.</TableCell>
                            <TableCell sx={{width: "120px"}}>Producto / Cliente </TableCell>
                            <TableCell sx={{width: "120px"}}>Cantidad</TableCell>
                            <TableCell>Precio Referencial</TableCell>
                            <TableCell sx={{width: "160px"}}>Descuento</TableCell>
                            <TableCell sx={{width: "160px"}}>Factura Final</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {isLoading ?
                        <TableRow className="m-12"><CircularProgress/>Cargando pedidos...</TableRow> :
                        data?.orders?.map((order, i )=>
                            <RowOrder index={i}
                                      order={order}
                                      key={order.id}
                                      quantity={quantity}
                                      setIsPrint={setIsPrint}
                                      updateOrderHandle={updateOrderHandle}
                                      setSelectedOrder={setSelectedOrder}
                                      setPage={setPage}
                                      setOrderToPrint={setOrderToPrint}
                            />)
                    }
                    </TableBody>
                </Table>
            </Box>
        </GenericCard>
    )
}

export default PaymentOrders