import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";

export const TablePrices = ({data, product}) => {

    const RowPrice = ({title, type}) => (
        data?.[type]?.price &&
        <TableRow>
            <TableCell><Typography variant="subtitle2">{title}:</Typography></TableCell>
            <TableCell>
                <Typography variant="body2">{data?.[type]?.from} - {data?.[type]?.to} {product?.unit?.symbol}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2">{Number(data?.[type]?.price).toFixed(2)} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
            </TableCell>
        </TableRow>
    )

    return (
        <Table>
            <TableBody>
                <RowPrice title="Inicial" type="inicial"/>
                <RowPrice title="Negocio" type="negocio"/>
                <RowPrice title="Volumen" type="volumen"/>
                <TableRow>
                    <TableCell colSpan={3}>
                        <Typography variant="subtitle2" component="span">Fecha vigente de Precio: {" "}</Typography>
                        <Typography variant="subtitle2"
                                    component="span">{data?.startDate ? new Date(data?.startDate).toLocaleDateString() : ""} - {data?.endDate ? new Date(data?.endDate).toLocaleDateString() : ""}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
