import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    Grid,
    Card,
    CardHeader,
    CardContent,
    TextField, FormControl, InputLabel, Select, MenuItem
} from "@mui/material"
import GenericCard from "../UI/GenericCard";
import {useSnackbar} from "../Snackbar/useSnackbar";
import {useQuery} from "react-query";
import {getFamilyCommodities} from "../../api/familyComodities";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";

const FormCategoryCommodity = (props) => {
    const {setSnackbar} = useSnackbar();
    const { data: dataFamilyCommodities, isLoading: isLoadingFamilyCommodities } = useQuery('familyCommodities', getFamilyCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const categoriesIntial = {
        name: "",
        description: ""
    }
    const {title, onSubmit, isLoading } = props;
    const [familyCommodities, setFamilyCommodities] = useState([])
    const [familyValue, setFamilyValue] = useState( '');
    const [familyId, setFamilyId] = useState("")
    const [categories, setCategories] = useState( [])

    useEffect(() => {
        if (dataFamilyCommodities) {
            setFamilyCommodities(dataFamilyCommodities)
        }
    }, [dataFamilyCommodities]);

    const handleSubmit = () => {
        let values = {name: familyValue, categories}
        if(familyId) values.id = familyId
        onSubmit(values)
    }

    const handleFamily = (e, action) => {
        if(familyValue !== '') {
            setCategories([])
        }
        setFamilyValue(e.target.value);
        setFamilyId(action?.props?.data?.id || "");
        setCategories(action?.props?.data?.categories || []);
    };

    const handleChangeCategory = (e, action) => {
        const {name, value} = e.target
        const newCategories = [...categories]
        newCategories[action][name] = value;
        setCategories(newCategories)
    }

    const addCategories = () => {
        setCategories([...categories, categoriesIntial])
    }

    const deleteCategoryHandler = index => {
        const newCategories = [...categories]
        newCategories.splice(index, 1)
        setCategories(newCategories)
    }

    return (
        <>
            <GenericCard className='mt-12' title={title}>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                                    }
                                    title="Familia"
                                />
                                <CardContent>
                                    {isLoadingFamilyCommodities ?
                                        <><CircularProgress/><span>Cargando información...</span></> :
                                        <FormControl fullWidth sx={{marginTop: '10px'}}>
                                            <InputLabel id="family" size="small">Familia</InputLabel>
                                            <Select
                                                labelId="family"
                                                id="family-select"
                                                value={familyValue}
                                                label="Familia"
                                                onChange={handleFamily}
                                                size="small"
                                            >
                                                {familyCommodities.map((family, i) => (
                                                    <MenuItem key={'family' + i} data={family} value={family.name}>{family.name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                                    }
                                    title="Commodity"
                                />
                                <CardContent>
                                    <Button variant="outlined" size="small" sx={{margin: "10px 0"}} onClick={() => addCategories()}>Agregar commodity</Button>
                                    {categories.map((category, index) =>
                                        <div key={'categoriE'+index} style={{display:"flex", gap:"10px"}}>
                                            <TextField placeholder="Nombre" size="small" fullWidth value={category.name} sx={{ marginTop: '10px'}} name="name" data={index} onChange={(e) => handleChangeCategory(e, index)} />
                                            <TextField placeholder="Descripción" size="small" fullWidth value={category.description} sx={{ marginTop: '10px'}} name="description" data={index} onChange={(e) => handleChangeCategory(e, index)} />
                                            <IconButton onClick={() => deleteCategoryHandler(index)} ><DeleteIcon color='primary' /></IconButton>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {isLoading ?
                        <CircularProgress />:
                        <Button variant="outlined"  sx={{marginTop: "40px", marginRight: "5px"}} onClick={handleSubmit} disabled={familyValue == ""}>Guardar Commodity</Button>
                    }
                </Box>
            </GenericCard>
        </>

    )
}

export default FormCategoryCommodity