import {Routes, Route} from 'react-router-dom';
import { LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {es} from "date-fns/locale";
import { SnackbarProvider} from "./components/Snackbar/SnackbarProvider";
import Login from './pages/auth/Login';
import Home from './pages/Home';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import firebaseApp from './firebase/firebaseApp';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from './app/userSlice';
import { userComeBack } from './app/userActions';
import Logout from './pages/auth/Logout';
import Layout from './components/Layout';
import EmailNotVerified from './pages/auth/EmailNotVerified';
import ForgotPassword from './pages/auth/ForgotPassword';
import ChangePassword from './pages/auth/ChangePassword';
import Users from './pages/Users';
import ZohoUsers from './pages/ZohoUsers';
import Suppliers from './pages/Suppliers'
import Commodities from './pages/Commodities';
import Filters from './pages/Filters';
import Products from "./pages/Products";
import GeneralFamilies from "./pages/GeneralFamilies";
import Orders from "./pages/Orders";
import './style.scss';

const auth = getAuth(firebaseApp);

function App() {
  const dispatch = useDispatch();

  useEffect(()=> {
    onAuthStateChanged(auth, user => {
      if (user)
      {
        dispatch(userComeBack({id: user.uid, email: user.email, emailVerified: user.emailVerified}));
      }
      else 
      {
        console.log('El usuario no se encuentra logueado');
        dispatch(logout());
      }
    });
  }, [dispatch]);
  
  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <Routes>
          <Route path="/" element={<Layout><Home/></Layout>} />
          <Route path="login" element={<Login/>} />
          <Route path="logout" element={<Logout />} />
          <Route path="forgot" element={<ForgotPassword/>} />
          <Route path="emailnotverified" element={<EmailNotVerified/>} />
          <Route path="changepassword" element={<ChangePassword/>} />
          <Route path="users" element={<Layout><Users/></Layout>} />
          <Route path="zoho-users" element={<Layout><ZohoUsers/></Layout>} />
          <Route path="suppliers" element={<Layout><Suppliers/></Layout>} />
          <Route path="families" element={<Layout><GeneralFamilies/></Layout>} />
          <Route path="filters" element={<Layout><Filters/></Layout>} />
          <Route path="products" element={<Layout><Products/></Layout>} />
          <Route path="orders" element={<Layout><Orders/></Layout>} />
          <Route path="commodities" element={<Layout><Commodities/></Layout>} />
        </Routes>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
