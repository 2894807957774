import React, {useState} from "react";
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    Link,
    Grid,
    Card,
    CardHeader,
    CardContent,
    TextField
} from "@mui/material";
import GenericCard from "../UI/GenericCard";
import BallotIcon from '@mui/icons-material/Ballot';

const FormCurrency = (props) => {
    const {currency, title, setPage, onSubmit, isLoading } = props;
    const [symbol, setSymbol] = useState(currency?.symbol || '');
    const [name, setName] = useState(currency?.name || '');
    const [code, setCode] = useState(currency?.code || '');

    const handleSubmit = () => {
        let values = {symbol, name, code}
        if(currency?.id) values.id = currency.id
        onSubmit(values)
    }

    return (
        <>
            <Link className='mt-12' component='button' onClick={() => setPage('listCurrencies')} sx={{marginBottom: '20px'}}>{'< Regresar'}</Link>
            <GenericCard title={title}>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Símbolo"
                                />
                                <CardContent>
                                    <TextField size="small" label="Ingrese símbolo" fullWidth value={symbol} sx={{ marginTop: '10px'}} onChange={(e) => setSymbol(e.target.value)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Code"
                                />
                                <CardContent>
                                    <TextField size="small" label="Ingrese code" fullWidth value={code} sx={{ marginTop: '10px'}} onChange={(e) => setCode(e.target.value)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Nombre"
                                />
                                <CardContent>
                                    <TextField size="small" label="Ingrese nombre" fullWidth value={name} sx={{ marginTop: '10px'}} onChange={(e) => setName(e.target.value)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {isLoading ?
                        <CircularProgress />:
                        <Button variant="outlined"  sx={{marginTop: "40px", marginRight: "5px"}} onClick={handleSubmit} disabled={!symbol && !name}>Guardar Unidad</Button>
                    }
                </Box>
            </GenericCard>
        </>

    )
}

export default FormCurrency