import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getIncoterms =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "incoterms"), orderBy('code')));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener las condiciones de venta")
    }
}

export const createIncoterm = async (incoterm) => {
    try{
        const docRef = await addDoc(collection(db, "incoterms"), incoterm);
        return {...incoterm, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la condición de venta")
    }
}

export const updateIncoterm = async (incoterm) => {
    try{
        await updateDoc(doc(db, "incoterms", incoterm.id), incoterm);
        return incoterm
    }
    catch(error){
        throw new Error("No se pudo actualizar la condición de venta")
    }
}

export const deleteIncoterm = async (incoterm) => {
    try{
        await deleteDoc(doc(db, "incoterms", incoterm.id));
        return incoterm
    }
    catch(error){
        throw new Error("No se pudo eliminar la condición de venta")
    }
}