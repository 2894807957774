import {TableRow, TableCell, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";

const RowGeneralFamily = ({generalFamily, viewFamily, deleteFamily, index}) => {

    return <TableRow>
        <TableCell>{index+1}</TableCell>
        <TableCell>{generalFamily.name}</TableCell>
        <TableCell>{generalFamily?.tagFamily}</TableCell>
        <TableCell>
            <div className="flex-center">
                <IconButton onClick={() => viewFamily(generalFamily)} ><VisibilityIcon color='primary' /></IconButton>
                <IconButton onClick={() => deleteFamily(generalFamily)} ><DeleteIcon color='primary' /></IconButton>
            </div>
        </TableCell>
    </TableRow>
}

export default RowGeneralFamily