import { useEffect, useState, Fragment } from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { getSuppliers } from '../api/suppliers';
import GenericCard from '../components/UI/GenericCard';
import ListCompanies from '../components/ZohoUsers/ListCompanies';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ViewCompany from '../components/ZohoUsers/ViewCompany';
import {getGeneralFamilies} from "../api/generalFamilies";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {createOrder} from "../api/zohoClients";
import {deleteCompany} from "../api/zohoClients";

const functions = getFunctions(firebaseApp);

const ZohoUsers = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {data: dataSuppliers} = useQuery('suppliers', getSuppliers, {staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {data: dataGeneralFamilies} = useQuery('generalFamilies', getGeneralFamilies, {staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const fetchOpenZohoOrdersByCompanyId = async() => {
        const getOpenZohoOrdersByCompanyId = httpsCallable(functions, 'getOpenZohoOrdersByCompanyId');
        try{
            const result = await getOpenZohoOrdersByCompanyId({companyId: currentCompany.id});
            return result.data
        }
        catch(error){
            throw new Error('Error al cargar los pedidos')
        }
    }

    const fetchUpdateZohoOrderCompany = async(order) => {
        const updateZohoOrderCompany = httpsCallable(functions, 'updateZohoOrderCompany');
        try {
            await updateZohoOrderCompany({orderId: order.id, orderInfo: order});
            return order
        }
        catch(error){
            throw new Error('Error al actualizar la orden')
        }
    }
    const [currentCompany, setCurrentCompany] = useState(undefined);

    const {isLoading: isLoadingOrder} = useQuery(["orders", currentCompany?.id], fetchOpenZohoOrdersByCompanyId, {
        staleTime: 3600000,
        enabled: currentCompany !== undefined,
        onSuccess: (data) => {
            setNewOrders(data);
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const {mutate: createOrderMutation, isLoading: isLoadingCreateOrder} = useMutation(createOrder, {
        onSuccess: (data) => {
            setNewOrders(data)
            queryClient.invalidateQueries('orders')
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const {mutate: updateOrderMutation, isLoading: isLoadingUpdateOrder} = useMutation(fetchUpdateZohoOrderCompany, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            setNewOrders(data)
            queryClient.invalidateQueries('orders')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const {mutate: deleteCompanytMutation} = useMutation(deleteCompany, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyCompanies = [...companies]
            const index = copyCompanies.findIndex(p => p.id === data.id)
            copyCompanies.splice(index, 1)
            setCompanies(copyCompanies)
            setLoading(false)
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const [companies, setCompanies] = useState([]);
    const [viewCompany, setViewCompany] = useState(false);
    const [editCompany, setEditCompany] = useState(false);

    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [suppliers, setSuppliers] = useState([]);
    const [newOrders, setNewOrders] = useState([]);

    const editCompanyHandler = () => {
        setViewCompany(false);
        setEditCompany(true);
        setControlsDisabled(true);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if(dataSuppliers){
            setSuppliers(dataSuppliers.filter(supplier => !supplier?.hide));
        }
    }, [dataSuppliers]);

    const getCompanies = () => {
        const listCompanies = httpsCallable(functions, 'getZohoCompaniesFunc');
        listCompanies().then(result => {
            setCompanies(result.data);
            setLoading(false);
        });
    }

    const viewCompanyHandler = (the_user) => {
        setCurrentCompany(the_user);
        setEditCompany(false);
        setViewCompany(true);
        setControlsDisabled(true);
    }

    const closeViewCompanyHandler = () => {
        setViewCompany(false);
        setControlsDisabled(false);
        getCompanies();
    }

    const createOrderHandle = (order) => {
        const answer = window.confirm(`¿Está seguro de crear la orden "${order?.product?.name + " - " + order?.product?.reference}"?`);
        if (!answer) {return}
        createOrderMutation(order);
    }

    const updateOrderHandle = (order) => {
        const answer = window.confirm(`¿Está seguro de actualizar la orden "${order?.product?.name + " - " + order?.product?.reference}"?`);
        if (!answer) {return}
        updateOrderMutation(order);
    }

    const deleteCompanyHandler = (company) => {
        const answer = window.confirm(`¿Está seguro de eliminar la compañía "${company?.name}"?`);
        if (!answer) {return}
        setLoading(true)
        deleteCompanytMutation(company);
    }

    return <Fragment>
        {!controlsDisabled && <GenericCard title="Lista de Zoho Clientes">
            <ListCompanies
                companies={companies}
                viewCompany={viewCompanyHandler}
                deleteCompanyHandle={deleteCompanyHandler}
                disabled={controlsDisabled}
                loading={loading}
            />
        </GenericCard>}
        {viewCompany &&
            <ViewCompany
                company={currentCompany}
                suppliers={suppliers}
                closeViewCompany={closeViewCompanyHandler}
                editCompany={editCompanyHandler}
                generalFamilies={dataGeneralFamilies}
                createOrderHandle={createOrderHandle}
                updateOrderHandle={updateOrderHandle}
                loading={isLoadingOrder || isLoadingCreateOrder || isLoadingUpdateOrder}
                newOrders={newOrders}
                setNewOrders={setNewOrders}
            />}
    </Fragment>
}

export default ZohoUsers;

