import {db} from '../firebase/firebaseApp'
import {addDoc, collection, deleteDoc, doc} from "firebase/firestore";

export const createOrder = async (order) => {
    try{
        const docRef = await addDoc(collection(db, "orders"), order);
        return {...order, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la orden")
    }
}

export const deleteCompany = async (company) => {
    try{
        await deleteDoc(doc(db, "companies", company.id));
        return company
    }
    catch(error){
        throw new Error("No se pudo eliminar la compañía")
    }
}