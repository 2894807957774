import {db} from '../firebase/firebaseApp'
import {collection, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";

export const getOrders =  async(filters) => {
    let orders = [];

    try{
        let queryOrders = query(collection(db, "orders"));
        filters.forEach((filter) => {
            const {field, operator, value} = filter;
            queryOrders = query(queryOrders, where(field, operator, value))
        })

        const querySnapshot = await getDocs(queryOrders);

        orders = await Promise.all(querySnapshot.docs.map(async (qdoc) => {
            const productRef = doc(db, "products", qdoc.data().product.id);
            let currentPrice = null;

            const productSnapshot = await getDoc(productRef);

            if (productSnapshot.exists()) {
                const productData = productSnapshot.data();
                if (productData?.priceRanges?.length > 0) {
                    const currentDate = new Date().toISOString();
                    currentPrice = productData.priceRanges.find(
                        (priceRange) =>
                            priceRange.startDate <= currentDate &&
                            priceRange.endDate >= currentDate
                    )
                    currentPrice = currentPrice ?? "No hay precio vigente"
                }
            }
            const copyQdoc = qdoc.data()
            copyQdoc.product = productSnapshot.data();
          return {...copyQdoc, id: qdoc.id, currentPrice}

        }))
    }
    catch(error){
        throw new Error("No se pudo obtener las ordenes")
    }
    finally {
        return {orders}
    }
}

export const updateOrder = async (order) => {
    try{
        await updateDoc(doc(db, "orders", order.id), order);
        return order
    }
    catch(error){
        throw new Error("No se pudo actualizar la orden")
    }
}