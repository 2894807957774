import RowCommodity from "./RowCommodity";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListCommodities = (props) => {
  const { commodities, isLoading, setPage, setSelectedCommodity, deleteCommodityHandle, setShowModalPrice, setShowPrices } = props;

  return (
      <GenericCard className='mt-12' title='Lista de Commodities'>
          <Box>
              <Button className sx={{marginBottom: "30px"}} variant='outlined' onClick={() => setPage('newCommodity')}>Agregar Registro</Button>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>Commodity</TableCell>
                          <TableCell>Familia</TableCell>
                          <TableCell>Descripción</TableCell>
                          <TableCell>Unidad</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {!isLoading && commodities?.map((commodity, index) => <RowCommodity commodity={commodity} index={index} key={commodity.id} setSelectedCommodity={setSelectedCommodity} setPage={setPage} deleteCommodityHandle={deleteCommodityHandle} setShowModalPrice={setShowModalPrice} setShowPrices={setShowPrices}/>)}
                  </TableBody>
              </Table>
          </Box>
          {isLoading && <div className="m-12"><CircularProgress/>Cargando información...</div>}
      </GenericCard>
  )
}

export default ListCommodities;