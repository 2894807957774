import {TableRow, TableCell, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RowSupplier = ({supplier, index, viewSupplier, disabled}) => {
    const viewSupplierHandler = () => {
        viewSupplier(supplier);
    }

    return <TableRow>
        <TableCell>{++index}</TableCell>
        <TableCell>{supplier.email}</TableCell>
        <TableCell>{supplier.name}</TableCell>
        <TableCell>{supplier.url}</TableCell>
        <TableCell>{supplier.hide ? 'Si' : 'No'}</TableCell>
        <TableCell><IconButton onClick={viewSupplierHandler} disabled={disabled}><VisibilityIcon color={disabled ? '#ccc' : 'primary'} /></IconButton></TableCell>
    </TableRow>;
}

export default RowSupplier