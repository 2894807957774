import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {getUnitCommodities, createUnitCommodity, updateUnitCommodity, deleteUnitCommodity} from "../api/unitComodities";
import ListUnits from "../components/Units/ListUnits";
import NewUnit from "../components/Units/NewUnit";
import EditUnit from "../components/Units/EditUnit";
const UnitCommodities = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [units, setUnits] = useState([])
    const [page, setPage] = useState('listUnits');
    const [selectedUnit, setSelectedUnit] = useState(null);
    const {data: dataUnits, isLoading: isLoadingUnits} = useQuery('unitCommodities', getUnitCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: createUnitMutation, isLoading: isLoadingCreateUnit} = useMutation(createUnitCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
            setUnits([...units, data])
            queryClient.invalidateQueries('unitCommodities')
            setPage('listUnits')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateUnitMutation, isLoading: isLoadingUpdateUnit} = useMutation(updateUnitCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyUnits = [...units]
            const index = copyUnits.findIndex(p => p.id === data.id)
            copyUnits[index] = data
            setUnits(copyUnits)
            queryClient.invalidateQueries('unitCommodities')
            setPage('listUnits')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: deleteUnitMutation, isLoading: isLoadingDeleteUnit} = useMutation(deleteUnitCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyUnits = [...units]
            const index = copyUnits.findIndex(p => p.id === data.id)
            copyUnits.splice(index, 1)
            setUnits(copyUnits)
            queryClient.invalidateQueries('unitCommodities')
            setPage('listUnits')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if(dataUnits){
            setUnits(dataUnits)
        }
    }, [dataUnits]);

    const createUnitHandle = (unit) => {
        const answer = window.confirm(`¿Está seguro de agregar la unidad "${unit.symbol}"?`);
        if (!answer) {return}
        createUnitMutation(unit)
    }

    const updateUnitHandle = (unit) => {
        const answer = window.confirm(`¿Está seguro de actualizar la unidad "${unit.symbol}"?`);
        if (!answer) {return}
        updateUnitMutation(unit)
    }

    const deleteUnitHandle = (unit) => {
        const answer = window.confirm(`¿Está seguro de eliminar la unidad "${unit.symbol}"?`);
        if (!answer) {return}
        deleteUnitMutation(unit)
    }

    return (
        <>
            {page === 'listUnits' &&
                <ListUnits units={units} isLoading={isLoadingUnits || isLoadingDeleteUnit} setPage={setPage} setSelectedUnit={setSelectedUnit} deleteUnitHandle={deleteUnitHandle} />
            }
            {page === 'newUnit' &&
                <NewUnit setPage={setPage} createUnitHandle={createUnitHandle} isLoading={isLoadingCreateUnit}/>
            }
            {page === 'editUnit' &&
                <EditUnit unit={selectedUnit} setPage={setPage} updateUnitHandle={updateUnitHandle} isLoading={isLoadingUpdateUnit}/>
            }
        </>
    )
}

export default UnitCommodities