import {TableRow, TableCell} from '@mui/material';

const RowCategory = (props) => {
    const {category} = props

    return <TableRow>
        <TableCell>{category}</TableCell>
    </TableRow>
}

export default RowCategory