import {TableContainer, Table, Box, TableHead, TableRow, TableBody, CircularProgress} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
import RowCompany from './RowCompany';

const ListCompanies = (props) => {
    const {companies, viewCompany, disabled, loading, deleteCompanyHandle} = props;
    return <TableContainer component={Box}>
    <Table>
        <TableHead>
            <TableRow>
                <TableHeaderCell>N.</TableHeaderCell>
                <TableHeaderCell>Account Name</TableHeaderCell>
                <TableHeaderCell>Country</TableHeaderCell>
                <TableHeaderCell>Industry</TableHeaderCell>
                <TableHeaderCell>Categoria</TableHeaderCell>
                <TableHeaderCell>Volumen</TableHeaderCell>
                <TableHeaderCell>Estado</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {loading ? <tr><td colSpan={9}><div className="flex-center gap-12 m-12"><CircularProgress/>Cargando información...</div></td></tr> :
            companies?.map((company, index) => <RowCompany company={company} index={index} key={company.id} viewCompany={viewCompany} disabled={disabled} deleteCompanyHandle={deleteCompanyHandle} />)}
        </TableBody>
    </Table>
</TableContainer>;
}

export default ListCompanies;