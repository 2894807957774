import FormProduct from "./FormProduct";

const NewProduct = (props) => {
    const {suppliers, setSnackbar, isLoading, createProductHandle, priceRanges, setPriceRanges, renewPrice, setRenewPrice, backPage} = props

    return <FormProduct
            title="Agregar Producto"
            onSubmit={createProductHandle}
            isLoading={isLoading}
            suppliers={suppliers}
            setSnackbar={setSnackbar}
            priceRanges={priceRanges}
            renewPrice={renewPrice}
            setRenewPrice={setRenewPrice}
            setPriceRanges={setPriceRanges}
            backPage={backPage}
    />
}

export default NewProduct