import GenericCard from "../UI/GenericCard"
import React, { useState } from "react"
import {
    Box,
    Grid,
    Snackbar, Alert, IconButton, Link, Typography
} from "@mui/material"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BallotIcon from '@mui/icons-material/Ballot';
import CardHeader from "@mui/material/CardHeader";

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const ViewGeneralFamily = ({cancel, family}) => {
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});
    const image = family?.image || "";

    const FilterCards = () => {
        return family?.filters?.map((filter, filterIndex) => (
            <Grid item xs={12} md={4} key={"filter"+filterIndex}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                        sx={{backgroundColor: "#eceff1"}}
                        avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                        title={filter.name}
                    />
                    <CardContent>
                        {filter?.options.map((option, i) =>
                            <Typography key={"familyCategry"+i} variant="subtitle1">● {option}</Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        ))
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenedSnack(false);
    }

    const closeHandler = () => {
        cancel()
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link component={'button'} onClick={closeHandler} sx={{marginBottom: '20px'}}>{'< Regresar'} </Link>
        <GenericCard title="Datos de la familia">
            <Snackbar open={openedSnack.show} autoHideDuration={8000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                    {openedSnack.message}
                </Alert>
            </Snackbar>
            <Box sx={{marginTop: "5px"}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Nombre"
                            />
                            <CardContent>
                                <Typography variant="subtitle1">{family?.name}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="TagFamily"
                            />
                            <CardContent>
                                <Typography variant="subtitle1">{family?.tagFamily}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Imágenes"
                            />
                            <CardContent>
                                {image &&
                                    <div className="card-image-text">
                                        <img src={`${STORAGE_BASE_URL}${BUCKET_NAME}/${image}`} />
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {image}</Typography>
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </GenericCard>
    </Box>
}

export default ViewGeneralFamily