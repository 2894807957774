import RowUnit from "./RowUnit";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListUnits = (props) => {
  const { units, isLoading, setPage, setSelectedUnit, deleteUnitHandle } = props;

  return (
      <GenericCard className='mt-12' title='Lista de Unidades'>
          <Box>
              <Button className sx={{marginBottom: "30px"}} variant='outlined' onClick={() => setPage('newUnit')}>Agregar Unidad</Button>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>Símbolo</TableCell>
                          <TableCell>Nombre (opcional)</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {isLoading ? <TableRow className="m-12"><CircularProgress/>Cargando información...</TableRow> :
                      units?.map((unit, index) => <RowUnit unit={unit} index={index} key={unit.id} setSelectedUnit={setSelectedUnit} setPage={setPage} deleteUnitHandle={deleteUnitHandle} />)}
                  </TableBody>
              </Table>
          </Box>
      </GenericCard>
  )
}

export default ListUnits;