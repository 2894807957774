import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit, Visibility} from "@mui/icons-material";
import React from "react";
import {TablePrices} from "./TablePrices";

const RowProduct = (props) => {
    const {product, setSelectedProduct, index, deleteProductHandle, setPage} = props;
    const handleProduct = (page) => {
        setSelectedProduct(product);
        setPage(page);
    }

    return <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{product?.name} <br/> {product?.reference}</TableCell>
        <TableCell>
            {typeof product?.currentPrice === "string" ? product?.currentPrice :
                typeof product?.currentPrice === "object" ?
                    <TablePrices data={product?.currentPrice} product={product}/> :
                    product?.priceRanges?.length > 0 && product?.priceRanges?.map((priceRange, i) => (<TablePrices key={"priceRangeV" + i} data={priceRange}/>))}
        </TableCell>
        <TableCell>
            <div className="flex-center">
                <IconButton onClick={() => handleProduct("viewProduct")}><Visibility color='primary'/></IconButton>
                <IconButton onClick={() => handleProduct("editProduct")}><Edit color='primary'/></IconButton>
                <IconButton onClick={() => deleteProductHandle(product)}><Delete color='primary'/></IconButton>
            </div>
        </TableCell>
    </TableRow>
}

export default RowProduct