import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getUnits =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "units"), orderBy('symbol')));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener las unidades")
    }
}

export const createUnit = async (unit) => {
    try{
        const docRef = await addDoc(collection(db, "units"), unit);
        return {...unit, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la unidad")
    }
}

export const updateUnit = async (unit) => {
    try{
        await updateDoc(doc(db, "units", unit.id), unit);
        return unit
    }
    catch(error){
        throw new Error("No se pudo actualizar la unidad")
    }
}

export const deleteUnit = async (unit) => {
    try{
        await deleteDoc(doc(db, "units", unit.id));
        return unit
    }
    catch(error){
        throw new Error("No se pudo eliminar la unidad")
    }
}