import {
    TableRow,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    Select,
    MenuItem,
    TableContainer, Table, TableHead, TableBody, Button, TableCell, IconButton, InputLabel, FormControl
} from '@mui/material';
import TableHeaderCell from "../UI/TableHeaderCell";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const NewFamily = (props) => {
    const {addCategories, categories, openedDialog, familyValue,
        handleChangeCategory, deleteCategoryHandler, categoryList,
        saveFamilyHandler, loading, generalFamilies, handleCloseNewFamily, handleFamily
    } = props


    return <Dialog open={openedDialog} onClose={() => handleCloseNewFamily()} fullWidth>
        <DialogTitle><Typography sx={{fontWeight: "bold"}}>Agregar Familia</Typography></DialogTitle>
        <DialogContent>
            <Box sx={{marginTop: "5px"}}>
                <FormControl fullWidth sx={{marginTop: '20px'}} required>
                    <InputLabel id="family" size="small">Familia</InputLabel>
                    <Select
                        labelId="family"
                        id="family-select"
                        value={familyValue}
                        label="Familia"
                        onChange={handleFamily}
                        size="small"
                    >
                        <MenuItem value=''>Seleccione</MenuItem>
                        {generalFamilies.map((family, i) => (
                            <MenuItem key={'fam' + i} data={family} value={family.name}>{family.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => addCategories()}>Agregar categorias</Button>
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Categorias</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((category, index) =>
                                <TableRow key={'categoriN'+index}>
                                    <TableCell >
                                        <FormControl fullWidth sx={{marginTop: '20px'}} required>
                                            <InputLabel id="categoryS" size="small">Seleccione categoría</InputLabel>
                                            <Select
                                                labelId="categoryS"
                                                id="categoryS-select"
                                                value={category}
                                                label="Categoria"
                                                onChange={(e) => handleChangeCategory(e, index)}
                                                size="small"
                                            >
                                                <MenuItem value=''>Seleccione</MenuItem>
                                                {categoryList.map((cat, i) => (
                                                    <MenuItem key={'cat'+i} value={cat}>{cat}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => deleteCategoryHandler(index)} ><DeleteIcon color='primary' /></IconButton>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
                {loading ?
                    <CircularProgress />:
                    <>
                        <Button variant="outlined" size="small" sx={{marginTop: "20px", marginRight: "5px"}} onClick={() => saveFamilyHandler()} disabled={!familyValue}>Guardar</Button>
                        <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => handleCloseNewFamily()} color="secondary">Cancelar</Button>
                    </>
                }
            </Box>
        </DialogContent>
    </Dialog>
}

export default NewFamily