import GenericCard from '../components/UI/GenericCard';
import ListUsers from '../components/Users/ListUsers';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Snackbar, Alert } from '@mui/material';
import NewUser from '../components/Users/NewUser';
import ViewUser from '../components/Users/ViewUser';
import EditUser from '../components/Users/EditUser';

const functions = getFunctions(firebaseApp);

const Users = () => {
    
    const [admins, setAdmins] = useState([]);
    const [newUser, setNewUser] = useState(false);
    const [viewUser, setViewUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);
    const [message, setMessage] = useState('');
    const [openedSnack, setOpenedSnack] = useState(false);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const profile = useSelector(state => state.user.profile);

    const newUserHandler = () => {
        setNewUser(true);
        setControlsDisabled(true);
    }

    const createNewUser = (user) => {
        const createAdmin = httpsCallable(functions, 'createAdmin');
        createAdmin(user).then(response => {
            if (response.data.status === false)
            {
                setMessage(response.data.message);
                setOpenedSnack(true);
            }
            
        });
        setNewUser(false);
        setControlsDisabled(false);
    }

    const editUserHandler = () => {
        setViewUser(false);
        setEditUser(true);
        setControlsDisabled(true);
    }

    const cancelNewUserHandler = () => {
        setNewUser(false);
        setControlsDisabled(false);
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setOpenedSnack(false);
    }

    useEffect(() => {
        const listAdmins = httpsCallable(functions, 'listAdmins');
        listAdmins().then(result => {
            setAdmins(result.data);
        });
    }, []);

    const viewUserHandler = (the_user) => {
        setCurrentUser(the_user);
        setEditUser(false);
        setViewUser(true);
        setControlsDisabled(true);
    }

    const closeViewUserHandler = () => {
        setViewUser(false);
        setControlsDisabled(false);
    }

    const updateUserHandler = () => {
        
        setEditUser(false);
        setControlsDisabled(false);
    }

    return <Fragment>
        <Snackbar open={openedSnack} autoHideDuration={3000} onClose={closeSnackHandler}>
            <Alert onClose={closeSnackHandler} severity="error" sx={{ width: '100%' }} variant="filled">
                {message}
            </Alert>
        </Snackbar>
        {!controlsDisabled && <GenericCard title="Lista de Usuarios">
            <ListUsers users={admins} viewUser={viewUserHandler} disabled={controlsDisabled}/>
            {profile === 'administrator' && <Box sx={{paddingTop: '20px'}}><Button variant='outlined' onClick={newUserHandler} disabled={controlsDisabled}>Nuevo Usuario</Button></Box>}
        </GenericCard>}
        {newUser && <NewUser createNewUser={createNewUser} cancel={cancelNewUserHandler}/>}
        {viewUser && <ViewUser user={currentUser} closeViewUser={closeViewUserHandler} editUser={editUserHandler} />}
        {editUser && <EditUser user={currentUser} updateUser={updateUserHandler} viewUser={viewUserHandler} />}
    </Fragment>
}

export default Users;

