import { useState, Fragment } from "react"
import { Table, TableHead, TableRow, TableBody, Button} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
import RowGeneralFamily from "./RowGeneralFamily";

const ListGeneralFamilies = (props) => {
    const { mode, viewFamily, deleteFamily, generalFamilies} = props
    const [openedDialog, setOpenedDialog] = useState(false)

    return <Fragment>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>N</TableHeaderCell>
                    <TableHeaderCell>Nombre</TableHeaderCell>
                    <TableHeaderCell>Tag Family</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {generalFamilies.map((generalFamily, i )=> <RowGeneralFamily index={i} generalFamily={generalFamily} key={"gfamily"+i} viewFamily={viewFamily} deleteFamily={deleteFamily} />)}
            </TableBody>
        </Table>
        {(mode === "edit") && <Button variant="outlined" sx={{marginTop: "20px"}} onClick={() => setOpenedDialog(true)}>Agregar Producto</Button>}
    </Fragment>
}

export default ListGeneralFamilies