import GenericCard from "../UI/GenericCard";
import { Box, Button, Divider, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from 'react';

const NewUser = ({ createNewUser, cancel }) => {
    const [email, setEmail] = useState('');
    const [profile, setProfile] = useState('');
    const [active, setActive] = useState(true);

    const handleEmail = e => {
        setEmail(e.target.value);
    }
    
    const handleProfile= (e) => {
        setProfile(e.target.value);
    };

    const handleActive= (e) => {
        setActive(e.target.value);
    };

    const createUserHandler = () => {
        if (email.trim() === '')
        {
            return;
        }
        if (profile.trim() === '')
        {
            return;
        }
        const user = {
            email: email,
            profile: profile,
            active: active
        };
        createNewUser(user);
    };

    const closeHandler = () => {
        cancel();
    }

    return <Box sx={{marginTop: '20px'}}>
        <GenericCard title="Nuevo Usuario">
            <TextField label="Correo" size="small" fullWidth required onChange={handleEmail} type="email" />
            <FormControl fullWidth sx={{marginTop: '20px'}} required>
                <InputLabel id="profile" size="small">Perfil</InputLabel>
                <Select
                    labelId="profile"
                    id="profile-select"
                    value={profile}
                    label="Perfil"
                    onChange={handleProfile}
                    size="small"
                >
                    <MenuItem value={'administrator'}>administrator</MenuItem>
                    <MenuItem value={'operator'}>operator</MenuItem>
                    <MenuItem value={'reader'}>reader</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{marginTop: '20px'}} required>
                <InputLabel id="active" size="small">Activo</InputLabel>
                <Select
                    labelId="active"
                    id="active-select"
                    value={active}
                    label="Activo"
                    onChange={handleActive}
                    size="small"
                    
                >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </FormControl>
            <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
            <Button variant="outlined" onClick={createUserHandler}>Crear Usuario</Button>
            <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cancelar</Button>
        </GenericCard>
    </Box>
}

export default NewUser;