import GenericCard from "../UI/GenericCard";
import { Box, TextField, Button, Divider, Tab, Typography, Stack, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from "react";
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ListUsers from "./ListUsers";
import ListFamilies from "../Families/ListFamilies"
import ListProductsSupplier from "./ListProductsSupplier";
import Switch from '@mui/material/Switch';

const ViewSupplier = ({supplier, closeViewSupplier, editSupplier}) => {
    const profile = useSelector(state => state.user.profile);
    const [tabValue, setTabValue] = useState("users")

    const editSupplierHandler = () => {
        editSupplier(supplier);
    }

    const closeHandler = () => {
        closeViewSupplier();
    }

    const changeTab = (e, value) => {
        setTabValue(value)
    }

    return <Box sx={{margin: '20px 0'}}>
        <Link component={'button'} onClick={closeHandler} sx={{marginBottom: '20px'}}>{'< Regresar'} </Link>
        <GenericCard title="Datos del Supplier">
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="end" sx={{marginTop: '10px'}}>
            <Typography sx={{color: '#00000061'}}>Ocultar proveedor</Typography>
            <Typography sx={{color: '#00000061'}}>No</Typography>
            <Switch disabled checked={supplier?.hide} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography sx={{color: '#00000061'}}>Si</Typography>
        </Stack>
         <TextField label="Correo" size="small" fullWidth type="email" disabled value={supplier.email} sx={{ marginTop: '10px'}}/>
         <TextField label="Nombre" size="small" fullWidth type="text" disabled value={supplier.name} sx={{ marginTop: '20px'}}/>
         <TextField label="Pagina Web" size="small" fullWidth type="text" disabled value={supplier.url} sx={{ marginTop: '20px'}}/>
         <TextField label="País" size="small" fullWidth type="text" disabled value={supplier.countryInfo.name} sx={{ marginTop: '20px'}} />
         <TextField label="Provincia" size="small" fullWidth type="text" disabled value={supplier.province} sx={{ marginTop: '20px'}} />
         <TextField label="Ciudad" size="small" fullWidth type="text" disabled value={supplier.city} sx={{ marginTop: '20px'}} />
         <TextField label="Código ZIP" size="small" fullWidth type="text" disabled value={supplier.zipCode} sx={{ marginTop: '20px' }} />
         <TextField label="Puertos" size="small" fullWidth type="text" disabled value={supplier.ports} sx={{ marginTop: '20px' }} />
         <TextField label="Dirección" size="small" fullWidth type="text" disabled value={supplier.address} sx={{ marginTop: '20px' }} />
         <TextField label="Página Web" size="small" fullWidth type="text" disabled value={supplier.url} sx={{ marginTop: '20px' }} />
         <TextField label="Año" size="small" fullWidth type="text" disabled value={supplier.year} sx={{ marginTop: '20px' }} />
         <TextField label="Teléfono" size="small" fullWidth type="text" disabled value={supplier.phone} sx={{ marginTop: '20px' }} />
         <TextField label="Fax" size="small" fullWidth type="text" disabled value={supplier.fax} sx={{ marginTop: '20px' }} />
         <TextField label="Resumen" size="small" fullWidth type="text" disabled value={supplier.overview} sx={{ marginTop: '20px' }} multiline />
         <TextField label="Ingresos Anuales" size="small" fullWidth type="text" disabled value={supplier.annualRevenue} sx={{ marginTop: '20px' }} />
         <TextField label="Lenguajes" size="small" fullWidth type="text" disabled value={supplier.languages} sx={{ marginTop: '20px' }} />
         <TextField label="Empleados" size="small" fullWidth type="text" disabled value={supplier.employees} sx={{ marginTop: '20px' }} />
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom:1, borderColor: 'divider', marginTop: "20px"}}>
                <TabList onChange={changeTab}>
                    <Tab value="users" label="Usuarios" />
                    <Tab value="families" label="Familias" />
                    <Tab value="products" label="Products" />
                    <Tab value="markets" label="Mercados" />
                    <Tab value="photos" label="Fotos" />
                </TabList>
            </Box>
            <TabPanel value="users">
                <ListUsers users={supplier.users} mode="view"/>
            </TabPanel>
            <TabPanel value="families">
                <ListFamilies families={supplier.families} mode="view"/>
            </TabPanel>
            <TabPanel value="products">
                <ListProductsSupplier supplierId={supplier.id} mode="view"/>
            </TabPanel>
            <TabPanel value="markets">
                <span>Mercados</span>
            </TabPanel>
            <TabPanel value="photos">
                <span>Fotos</span>
            </TabPanel>
         </TabContext>
         <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
         {(profile === 'administrator' || profile === 'operator') && <Button variant="outlined" onClick={editSupplierHandler}>Editar Supplier</Button>}
         <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cerrar</Button>
    </GenericCard>
 </Box>
}

export default ViewSupplier