import { TableContainer, Table, Box, TableHead, TableRow, TableBody, TableFooter} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
import RowUser from './RowUser';


const ListUsers = ({users, viewUser, disabled}) => {
    return <TableContainer component={Box}>
    <Table>
        <TableHead>
            <TableRow>
                <TableHeaderCell>Correo</TableHeaderCell>
                <TableHeaderCell>Perfil</TableHeaderCell>
                <TableHeaderCell>Activo</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {users.map(user => <RowUser user={user} key={user.email} viewUser={viewUser} disabled={disabled} />)}
        </TableBody>
        <TableFooter>
            
        </TableFooter>
    </Table>
</TableContainer>;
}

export default ListUsers;