import RowProduct from "./RowProduct";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress, TablePagination } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListProducts = (props) => {
    const { SelectSupplier, priceRanges, deleteProductHandle, isLoading, setPage, setSelectedProduct, data, supplier, addProduct, handleChangePage} = props;

    return (
        <GenericCard title="Lista de Productos por Supplier">
            <Box sx={{marginTop: "10px"}}>
                <div className="flex flex-row justify-between">
                    <SelectSupplier/>
                    <Button sx={{marginBottom: "30px"}} variant='outlined' onClick={addProduct}>Agregar Producto</Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N.</TableCell>
                            <TableCell>Nombre / Referencia</TableCell>
                            <TableCell>Precios</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            <TableRow className="m-12"><TableCell colSpan={4}><CircularProgress/>Cargando productos...</TableCell></TableRow> :
                            data?.products?.length === 0 ? <TableRow className="m-12"><TableCell colSpan={4} className="mt-12">No hay productos registrados para el supplier "{supplier}"</TableCell></TableRow> :
                                data?.products?.map((product, i )=>
                                <RowProduct index={i}
                                            product={product}
                                            key={product.id}
                                            deleteProductHandle={deleteProductHandle}
                                            setSelectedProduct={setSelectedProduct}
                                            setPage={setPage}
                                            priceRanges={priceRanges}
                                />)
                        }
                    </TableBody>
                </Table>
                {/*{isLoading ? "" :
                    <TablePagination
                        component="div"
                        count={data?.totalProducts}
                        page={0}
                        rowsPerPage={25}
                        rowsPerPageOptions={[25]}
                        onPageChange={handleChangePage}
                        labelRowsPerPage=""
                    />
                }*/}

            </Box>
        </GenericCard>
    )
}

export default ListProducts