import RowCurrency from "./RowCurrency";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListCurrencies = (props) => {
  const { currencies, isLoading, setPage, setSelectedCurrency, deleteCurrencyHandle } = props;

  return (
      <GenericCard className='mt-12' title='Lista de Unidades'>
          <Box>
              <Button className sx={{marginBottom: "30px"}} variant='outlined' onClick={() => setPage('newCurrency')}>Agregar Moneda</Button>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>Símbolo</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {!isLoading && currencies?.map((currency, index) => <RowCurrency currency={currency} index={index} key={currency.id} setSelectedCurrency={setSelectedCurrency} setPage={setPage} deleteCurrencyHandle={deleteCurrencyHandle} />)}
                  </TableBody>
              </Table>
          </Box>
          {isLoading && <div className="m-12"><CircularProgress/>Cargando información...</div>}
      </GenericCard>
  )
}

export default ListCurrencies;