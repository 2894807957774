import { useQuery } from 'react-query';
import {getSuppliers} from "../api/suppliers";
import ListSuppliers from "../components/Suppliers/ListSuppliers";
import GenericCard from '../components/UI/GenericCard';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Snackbar, Alert, LinearProgress } from '@mui/material';
import NewSupplier from '../components/Suppliers/NewSupplier';
import ViewSupplier from '../components/Suppliers/ViewSupplier';
import EditSupplier from '../components/Suppliers/EditSupplier';

const functions = getFunctions(firebaseApp);

const Suppliers = () => {
    const {data: dataSuppliers, isLoading: isLoadingSuppliers} = useQuery('suppliers', getSuppliers, {
            staleTime: 3600000,
            onError: (error) => {
                setOpenedSnack({show: true, message: error?.message, severity: 'error'})
                setShowProgress(false)
            }
        }
    );
    const [suppliers, setSuppliers] = useState([]);
    const [newSupplier, setNewSupplier] = useState(false);
    const [viewSupplier, setViewSupplier] = useState(false);
    const [editSupplier, setEditSupplier] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(undefined);
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});

    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [showProgress, setShowProgress] = useState(false)
    const profile = useSelector(state => state.user.profile);

    const newSupplierHandler = () => {
        setNewSupplier(true);
        setControlsDisabled(true);
    }

    const createNewSupplier = (supplier) => {
        setShowProgress(true)
        const createSupplier = httpsCallable(functions, 'createSupplier');
        createSupplier(supplier).then(response => {
            if (response.data.status === false)
            {
                setOpenedSnack({show: true, message: response.data.message, severity: 'error'})
            }
            else {
                const copySuppliers = [...suppliers]
                copySuppliers.push({
                    id: response.data,
                    name: supplier.name,
                    url: supplier.url,
                    email: supplier.email
                })
            }
            setShowProgress(false)
        });
        setNewSupplier(false);
        setControlsDisabled(false);
    }

    const updateSupplierHandler = (supplier) => {
        setShowProgress(true)
        const updateSupplier = httpsCallable(functions, 'updateSupplierV2')
        updateSupplier(supplier).then(response => {
            if (response.data.status === false) {
                setOpenedSnack({show: true, message: response.data.message, severity: 'error'})
            }
            const copySuppliers = [...suppliers]
            const updatedSupplier = copySuppliers.find(p => p.id === currentSupplier.id)
            updatedSupplier.email = supplier.email
            updatedSupplier.name = supplier.name
            updatedSupplier.url = supplier.url
            updatedSupplier.hide = supplier.hide
            setShowProgress(false)
            setSuppliers(copySuppliers)
        })
        setEditSupplier(false)
        setControlsDisabled(false)
    }

    const editSupplierHandler = () => {
        setViewSupplier(false);
        setEditSupplier(true);
        setControlsDisabled(true);
    }

    const cancelNewSupplierHandler = () => {
        setNewSupplier(false);
        setControlsDisabled(false);
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setOpenedSnack(false);
    }

    useEffect(() => {
        if(dataSuppliers){
            setSuppliers(dataSuppliers)
        }
    }, [dataSuppliers]);

    const viewSupplierHandler = (the_supplier) => {
        setCurrentSupplier(the_supplier);
        setEditSupplier(false);
        setViewSupplier(true);
        setControlsDisabled(true);
    }

    const closeViewSupplierHandler = () => {
        setViewSupplier(false);
        setControlsDisabled(false);
    }

    return <Fragment>
        <Snackbar open={openedSnack.show} autoHideDuration={3000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                {openedSnack.message}
            </Alert>
        </Snackbar>
        {!controlsDisabled && <GenericCard title="Lista de Suppliers">
            <ListSuppliers suppliers={suppliers} viewSupplier={viewSupplierHandler} disabled={controlsDisabled} isLoading={isLoadingSuppliers}/>
            {showProgress && <LinearProgress />}
            {(profile === 'administrator' || profile === 'operator') && <Box sx={{paddingTop: '20px'}}><Button variant='outlined' onClick={newSupplierHandler} disabled={controlsDisabled}>Nuevo Supplier</Button></Box>}
        </GenericCard>}
        {newSupplier && <NewSupplier createNewSupplier={createNewSupplier} cancel={cancelNewSupplierHandler}/>}
        {viewSupplier && <ViewSupplier supplier={currentSupplier} closeViewSupplier={closeViewSupplierHandler} editSupplier={editSupplierHandler} />}
        {editSupplier && <EditSupplier supplier={currentSupplier} updateSupplier={updateSupplierHandler} viewSupplier={viewSupplierHandler} />}
    </Fragment>
}

export default Suppliers;