import GenericCard from "../UI/GenericCard"
import React from "react"
import {
    Box,
    Grid,
    IconButton,
    Link,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Accordion,
    AccordionSummary, AccordionDetails, Alert
} from "@mui/material"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BallotIcon from '@mui/icons-material/Ballot';
import CardHeader from "@mui/material/CardHeader";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const ViewProduct = (props) => {
    const {product, backPage} = props;
    const images = product?.images || [];
    const docs = product?.docs || [];
    const priceRanges = product?.priceRanges || [];

    const FilterCards = () => {
        return product?.filters?.map((filter, filterIndex) => (
            <>
                {(filter?.show === undefined ? true : filter?.show) &&
                    <Grid item xs={12} md={4} key={"filter" + filterIndex}>
                        <Card sx={{maxWidth: 345}}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                                title={"Filtro "}
                                subheader={filter.name}
                            />
                            <CardContent>
                                <Typography variant="subtitle2">{filter?.option}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {filter?.subfilters?.map((subFilter, subFilterIndex) => (
                    <>
                        <Grid item xs={12} md={4} key={"subfilter"+subFilterIndex+filterIndex}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title={"Filtro: " + filter.name}
                                    subheader={"Subfiltro: "+ subFilter.name}
                                />
                                <CardContent>
                                    <Typography variant="subtitle2">{subFilter?.option}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {subFilter?.subfilters?.map((nestedSubFilter, nestedSubFilterIndex) => (
                            <Grid item xs={12} md={4} key={"subfil"+filterIndex+nestedSubFilterIndex+subFilterIndex}>
                                <Card key={'subFilterName'+filterIndex+nestedSubFilterIndex+subFilterIndex}>
                                    <CardHeader
                                        sx={{backgroundColor: "rgba(58,58,59,0.09)"}}
                                        avatar={<IconButton aria-label="settings"><BallotOutlinedIcon /></IconButton>}
                                        title={"Filtro: " + filter.name}
                                        subheader={"Subfiltro de "+ subFilter.name + ": "+ nestedSubFilter.name}
                                    />
                                    <CardContent>
                                        <Typography variant="subtitle2">{nestedSubFilter?.option}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </>
                    )
                )}
            </>

        ))
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link className='mt-12' component='button' onClick={backPage} sx={{marginBottom: '20px'}}>{'< Regresar'}</Link>
        <GenericCard title="I. INFORMACIÓN GENERAL">
            <Box sx={{marginTop: "5px"}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <TextField size="small" label="Supplier" fullWidth value={product?.supplier?.name} InputProps={{readOnly: true,}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField size="small" label="Descripción del producto" fullWidth value={product?.name} InputProps={{readOnly: true,}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField size="small" label="Referencia" fullWidth value={product?.reference} InputProps={{readOnly: true,}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Imágenes"
                            />
                            <CardContent>
                                {images.length > 0 ? images.map((item, imageIndex) => {
                                    return <div className="card-image-text" key={"img"+imageIndex}>
                                        <img src={`${STORAGE_BASE_URL}${BUCKET_NAME}/${item}`} alt="images product" />
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item}</Typography>
                                    </div>
                                }) : <Typography variant="subtitle2">No hay imágenes adjuntas</Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Especificaciones"
                            />
                            <CardContent>
                                {docs.length > 0 ? docs.map((item, docIndex) => <div  className="card-text-icon" key={"docs"+docIndex}>
                                        <Typography variant="caption"><strong>Nombre archivo: </strong>
                                            <a rel="noreferrer" target="_blank" href={`${STORAGE_BASE_URL}${BUCKET_NAME}/${item}`} download className="file-name">{item}</a>
                                        </Typography>
                                    </div>
                                ) : <Typography variant="subtitle2">No hay documentos adjuntos</Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </GenericCard>
        <GenericCard title="II. FAMILIA / CATEGORÍAS">
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                            title="Familia"
                        />
                        <CardContent>
                            <Typography variant="subtitle2">{product?.family}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                            title="Categoría"
                        />
                        <CardContent>
                            <Typography variant="subtitle2">{product?.category}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                { FilterCards()}
            </Grid>
        </GenericCard>

        <GenericCard title="III. INFORMACIÓN DE PRECIOS">
            <Grid container spacing={4} sx={{marginBottom: "30px"}}>
                <Grid item md={3}>
                    <TextField size="small" label="Moneda" fullWidth value={product?.currency} InputProps={{readOnly: true}}/>
                </Grid>
                <Grid item md={3}>
                    <TextField size="small" label="Condición de venta" fullWidth value={product?.incoterm} InputProps={{readOnly: true}}/>
                </Grid>
                <Grid item md={3}>
                    <TextField size="small" label="Unidad de precio" fullWidth value={product?.priceUnit?.symbol} InputProps={{readOnly: true}}/>
                </Grid>
                <Grid item md={3}>
                    <TextField size="small" label="Unidad" fullWidth value={product?.unit?.symbol} InputProps={{readOnly: true}}/>
                </Grid>
            </Grid>
            {product?.currentPrice && <Typography sx={{margin:"20px 0 10px"}} color="primary" variant="subtitle2">HISTORIAL PRECIOS:</Typography>}
            {product?.priceRanges?.map((priceRange, i) => (
                product?.currentPrice ?
                    <Accordion key={"AccpriceRange" + i} defaultExpanded={priceRanges.length < 2 } sx={{border: "1px solid #eeeded"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography sx={{ width: '200px', flexShrink: 0 }}>{i+1}. Detalle precio</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Fecha vigencia precio:
                                {priceRange?.startDate ? new Date(priceRange?.startDate).toLocaleDateString(): ""} -
                                {priceRange?.endDate ? new Date(priceRange?.endDate).toLocaleDateString(): ""}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{background: "#eceff1"}}>
                                            <TableCell>Tipo precio</TableCell>
                                            <TableCell>Rango Cantidades</TableCell>
                                            <TableCell>Precio</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        <TableRow>
                                            <TableCell>Inicial</TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                <div style={{display: "flex", gap: "10px"}}>
                                                    <Typography variant="subtitle2">Desde: {priceRange?.inicial?.from} {product?.unit?.symbol}</Typography>
                                                    <Typography variant="subtitle2">Hasta: {priceRange?.inicial?.to} {product?.unit?.symbol}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Precio: {priceRange?.inicial?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Negocio</TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                <div style={{display: "flex", gap: "10px"}}>
                                                    <Typography variant="subtitle2">Desde: {priceRange?.negocio?.from} {product?.unit?.symbol}</Typography>
                                                    <Typography variant="subtitle2">Hasta: {priceRange?.negocio?.to} {product?.unit?.symbol}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Precio: {priceRange?.negocio?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Volumen</TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                <div style={{display: "flex", gap: "10px"}}>
                                                    <Typography variant="subtitle2">Desde: {priceRange?.volumen?.from} {product?.unit?.symbol}</Typography>
                                                    <Typography variant="subtitle2">Hasta: {priceRange?.volumen?.to} {product?.unit?.symbol}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Precio: {priceRange?.volumen?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Lista</TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                <div style={{display: "flex", gap: "10px"}}>
                                                    <Typography variant="subtitle2">Desde: {priceRange?.lista?.from} {product?.unit?.symbol}</Typography>
                                                    <Typography variant="subtitle2">Hasta: {priceRange?.lista?.to} {product?.unit?.symbol}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle2">Precio: {priceRange?.lista?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    : ""
            ))}
            {typeof product?.currentPrice === "string" && <Alert sx={{marginTop: "20px"}} severity="warning">{product?.currentPrice}</Alert>}
        </GenericCard>
    </Box>
}

export default ViewProduct