import React from "react";
import FormIncoterm from "./FormIncoterm";

const NewIncoterm = (props) => {
    const { setPage, createIncotermHandle, isLoading } = props;

    return (
        <FormIncoterm
            title='Agregar Condición de Venta'
            setPage={setPage}
            onSubmit={createIncotermHandle}
            isLoading={isLoading}
        >
        </FormIncoterm>

    )
}

export default NewIncoterm