import { useQuery } from 'react-query';
import { getGeneralFamilies } from '../api/generalFamilies';
import GenericCard from '../components/UI/GenericCard';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Snackbar,
    Alert,
    LinearProgress,
    Grid,
    Tab,
    Tabs,
} from '@mui/material';
import NewGeneralFamily from "../components/GeneralFamily/NewGeneralFamily";
import ListGeneralFamilies from "../components/GeneralFamily/ListGeneralFamilies";
import ViewGeneralFamily from "../components/GeneralFamily/ViewGeneralFamily";
import Units from "./Units";
import Currencies from "./Currencies";
import Incoterms from "./Incoterms";

const functions = getFunctions(firebaseApp);

const GeneralFamilies = () => {
    const {data: dataGeneralFamilies, isLoading: isLoadingGeneralFamilies} = useQuery('generalFamilies', getGeneralFamilies, {staleTime: 3600000});
    const [tabIndex, setTabIndex] = useState("family");
    const [generalFamilies, setGeneralFamilies] = useState([])
    const [newFamily, setNewFamily] = useState(false);
    const [viewFamily, setViewFamily] = useState(false);
    const [currentFamily, setCurrentFamily] = useState(undefined);
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [showProgress, setShowProgress] = useState(false)
    const profile = useSelector(state => state.user.profile);

    useEffect(() => {
        if(dataGeneralFamilies){
            setGeneralFamilies(dataGeneralFamilies)
        }
    }, [dataGeneralFamilies]);

    const newFamilyHandler = () => {
        setNewFamily(true);
        setControlsDisabled(true);
    }

    const cancelNewFamilyHandler = () => {
        setNewFamily(false);
        setControlsDisabled(false);
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setOpenedSnack(false);
    }

    const viewFamilyHandler = (the_product) => {
        setCurrentFamily(the_product);
        setViewFamily(true);
        setControlsDisabled(true);
    }

    const closeViewFamilyHandler = () => {
        setViewFamily(false);
        setControlsDisabled(false);
    }

    const deleteFamilyHandler = (family) => {
        const answer = window.confirm(`¿Está seguro de eliminar el producto ${family.name}?`);
        if (!answer) {
            setShowProgress(false)
            return
        }
        const deleteGeneralFamily = httpsCallable(functions, 'deleteGeneralFamily');
        deleteGeneralFamily({id: family.id}).then(result => {
            setOpenedSnack({show: true, message:'Se eliminó correctamente', severity: 'success'})
            const copyGeneralFamilies = [...generalFamilies]
            const index = copyGeneralFamilies.findIndex(p => p.id === family.id)
            copyGeneralFamilies.splice(index, 1)
            setGeneralFamilies(copyGeneralFamilies)
        }).catch(error => {
                setOpenedSnack({show: true, message: "Hubo un error al guardar, intente nuevamente.", severity: 'error'})
        });
    }


    return <Fragment>
        <Snackbar open={openedSnack.show} autoHideDuration={3000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                {openedSnack.message}
            </Alert>
        </Snackbar>
        <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
            <Tab label="Familias" value="family"/>
            <Tab label="Unidades" value="units"/>
            <Tab label="Monedas" value="currency"/>
            <Tab label="Condición de venta" value="incoterm"/>
        </Tabs>
        <div hidden={tabIndex !== "family"} className="mt-12">
            {!controlsDisabled &&
            <GenericCard title="Lista de Familias">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {(profile === 'administrator' || profile === 'operator') &&
                            <>
                                <Button variant='outlined' onClick={newFamilyHandler} sx={{marginBottom: "20px"}} disabled={controlsDisabled}>Nueva Familia</Button>
                                <ListGeneralFamilies generalFamilies={generalFamilies} viewFamily={viewFamilyHandler} deleteFamily={deleteFamilyHandler} disabled={controlsDisabled}/>
                            </>
                        }
                    </Grid>
                </Grid>
            </GenericCard>}
            {isLoadingGeneralFamilies && <LinearProgress />}
            {newFamily && <NewGeneralFamily cancel={cancelNewFamilyHandler} setGeneralFamilies={setGeneralFamilies} generalFamilies={generalFamilies}/>}
            {viewFamily && <ViewGeneralFamily family={currentFamily} closeViewFamily={closeViewFamilyHandler} cancel={closeViewFamilyHandler}/>}
        </div>
        {tabIndex === 'units' &&
            <Units />
        }
        {tabIndex === 'currency' &&
            <Currencies />
        }
        {tabIndex === 'incoterm' &&
            <Incoterms />
        }
    </Fragment>
}

export default GeneralFamilies;