// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD68lWbc3NjfH2o4SYg3mSPp9Qqmbi2vK4",
  authDomain: "myalaxs-firebase.firebaseapp.com",
  projectId: "myalaxs-firebase",
  storageBucket: "myalaxs-firebase.appspot.com",
  messagingSenderId: "438502362160",
  appId: "1:438502362160:web:90c7b6e67d638f5954f1f5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app, "gs://myalaxs-images");

const functions = getFunctions(getApp());
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

export const db = getFirestore(app);
export default app;