import React from "react";
import FormUnit from "./FormUnit";

const NewUnit = (props) => {
    const { setPage, createUnitHandle, isLoading } = props;

    return (
        <FormUnit
            title='Agregar unidad'
            setPage={setPage}
            onSubmit={createUnitHandle}
            isLoading={isLoading}
        >
        </FormUnit>

    )
}

export default NewUnit