import { useEffect, useState, Fragment } from "react"
import { DialogTitle, DialogContent, Typography, TableContainer, Table, TableHead, TableRow, TableFooter, Dialog, Box, TableBody, Button, LinearProgress} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
    import RowProduct from "./RowProductSupplier";
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from "../../firebase/firebaseApp";

const functions = getFunctions(firebaseApp);

const ListProductsSupplier = (props) => {
    const {supplierId, mode} = props
    const [products, setProducts] = useState([])
    const [showProgress, setShowProgress] = useState(false)
    const [openedDialog, setOpenedDialog] = useState(false)

    useEffect(() => {
        setShowProgress(true)
        const fetchProducts = () => {
            const listProducts = httpsCallable(functions, 'listProductsBySupplier')
            listProducts({id: supplierId, limit: 10}).then(result => {
                setProducts(result.data);
                setShowProgress(false)
            });
        }
        fetchProducts()
    }, [supplierId])

    return <Fragment>
        {showProgress && <LinearProgress/>}
        {!showProgress && <TableContainer component={Box}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>N</TableHeaderCell>
                    <TableHeaderCell>Nombre</TableHeaderCell>
                    <TableHeaderCell>Supplier</TableHeaderCell>
                    <TableHeaderCell>Familia</TableHeaderCell>
                    <TableHeaderCell>Categoria</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map((product, i) => <RowProduct index={i} product={product} key={product.id} />)}
            </TableBody>
            <TableFooter>

            </TableFooter>
        </Table>
        </TableContainer>}
        <Dialog open={openedDialog} onClose={() => setOpenedDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle><Typography sx={{fontWeight: "bold"}}>Agregar Producto</Typography></DialogTitle>
            <DialogContent>
                <Box sx={{marginTop: "5px"}}>
                    <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => setOpenedDialog(false)}>Agregar Producto</Button>
                    <Button variant="outlined" size="small" sx={{marginTop: "20px", marginLeft: "5px"}} onClick={() => setOpenedDialog(false)} color="secondary">Cancelar</Button>
                </Box>
            </DialogContent>
        </Dialog>
        {(mode === "edit") && <Button variant="outlined" sx={{marginTop: "20px"}} onClick={() => setOpenedDialog(true)}>Agregar Producto</Button>}
    </Fragment>
}

export default ListProductsSupplier