import {
    TableRow,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    TextField, TableContainer, Table, TableHead, TableBody, Button, TableCell, IconButton
} from '@mui/material';
import TableHeaderCell from "../UI/TableHeaderCell";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

const EditFamily = (props) => {
    const {addCategories, categories, openedDialog, setOpenedDialog, setFamilyValue, familyValue, handleChangeCategory, deleteCategoryHandler, updateFamilyHandler, loading, deleteFamilyHandler} = props

    return <Dialog open={openedDialog} onClose={() => setOpenedDialog(false)} fullWidth>
        <DialogTitle><Typography sx={{fontWeight: "bold"}}>Actualizar Familia</Typography></DialogTitle>
        <DialogContent>
            <Box sx={{marginTop: "5px"}}>
                <TextField label="Nombre" fullWidth size="small" required value={familyValue} onChange={(e) => setFamilyValue(e.target.value)}  />
                <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => addCategories()}>Agregar categorias</Button>
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Categorias</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((category, index) =>
                                <TableRow key={'categoriE'+index}>
                                    <TableCell >
                                        <TextField size="small" fullWidth value={category} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeCategory(e, index)} />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => deleteCategoryHandler(index)} ><DeleteIcon color='primary' /></IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loading ?
                    <CircularProgress />:
                    <>
                        <Button variant="outlined" size="small" sx={{marginTop: "20px", marginRight: "5px"}} onClick={() => updateFamilyHandler()}>Guardar</Button>
                        <Button variant="outlined" color="error" size="small" sx={{marginTop: "20px", marginRight: "5px"}} onClick={() => deleteFamilyHandler()}>Eliminar</Button>
                        <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => setOpenedDialog(false)} color="secondary">Cerrar</Button>
                    </>
                }
            </Box>
        </DialogContent>
    </Dialog>
}

export default EditFamily