import FormCategoryCommodity from "./FormCategoryCommodity";

const EditCategoryCommodity = (props) => {
    const {updateFamilyCommodityHandle, isLoading } = props;

    return (
        <FormCategoryCommodity
            title='Agregar Commodity'
            onSubmit={updateFamilyCommodityHandle}
            isLoading={isLoading}
        />
    )
}

export default EditCategoryCommodity