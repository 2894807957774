import GenericCard from "../components/UI/GenericCard"
import React, { useEffect, useState } from "react"
import {
    Box,
    Grid,
    FormControl, InputLabel, Select, MenuItem, Button, IconButton, Snackbar, Alert, TextField, Typography, Divider
} from "@mui/material";
import {useSnackbar} from "../components/Snackbar/useSnackbar";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import firebaseApp from '../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import {useQuery} from "react-query";
import {getGeneralFamilies} from "../api/generalFamilies";

const functions = getFunctions(firebaseApp)
const Filters = () => {
    const {setSnackbar} = useSnackbar();
    const { data: dataGeneralFamilies, isLoading: isLoadingGeneralFamilies } = useQuery('generalFamilies', getGeneralFamilies, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const [generalFamilies, setGeneralFamilies] = useState([])
    const [familyValue, setFamilyValue] = useState("")
    const [familyId, setFamilyId] = useState("")
    const [categories, setCategories] = useState([])
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});
    const [loading, setLoading] = useState(false)
    const [filterAll, setFilterAll] = useState([])
    const subFilterField = {optionName: "", name: "", options: []}

    useEffect(() => {
        if (dataGeneralFamilies) {
            setGeneralFamilies(dataGeneralFamilies)
        }
    }, [dataGeneralFamilies]);

    const handleFamily = (e, action) => {
        if(familyValue !== '') {
            setCategories([])
        }
        setFamilyValue(e.target.value);
        setFamilyId(action?.props?.data?.id || "");
        setCategories(action?.props?.data?.categories || []);
        setFilterAll(action?.props?.data?.filters || [])
    };

    const saveFamilyFilters =  () => {
        const isFilterNameEmpty = filterAll.filter(item => item.name === "").length > 0;

        if(isFilterNameEmpty) {
            setOpenedSnack({show: true, message: 'Debe ingresar un nombre para cada filtro', severity: 'error'})
            return;
        }

        setLoading(true)
        const request = {
            id: familyId,
            categories: categories.filter(item => item !== ""),
            filters: filterAll.map(item => ({
                ...item,
                options: item.options.filter(option => option !== '')
            }))
        }

        setFilterAll(request.filters)

        const updateGeneralFamily = httpsCallable(functions, 'updateGeneralFamily');
        updateGeneralFamily(request).then(response => {
            if (response.data.status === false) {
                setOpenedSnack({show: true, message: "Hubo un error al guardar, intente nuevamente.", severity: 'error'})
            } else {
                setOpenedSnack({show: true, message:'Se guardó correctamente', severity: 'success'})
            }
            setLoading(false)
        });
    }

    const handleChangeCategory = (e, action) => {
        const newCategories = [...categories]
        newCategories[action] = e.target.value;
        setCategories(newCategories)
    }

    const addCategories = () => {
        setCategories([...categories, ""])
    }

    const deleteCategoryHandler = index => {
        const newCategories = [...categories]
        newCategories.splice(index, 1)
        setCategories(newCategories)
    }

    const addFilter = () => {
        let newFilters = [...filterAll]
        newFilters.push({name: "", options: []})
        setFilterAll(newFilters)
    }

    const addSubFilter = (filterIndex) => {
        let newFilters = [...filterAll]
        if(newFilters[filterIndex].subfilters){
            newFilters[filterIndex].subfilters.push(subFilterField)
        }
        else {
            newFilters[filterIndex].subfilters = [subFilterField]
        }
        setFilterAll(newFilters)
    }

    const addNestedSubFilter = (filterIndex, subfiltersIndex) => {
        let newFilters = [...filterAll]
        if(newFilters[filterIndex].subfilters[subfiltersIndex].subfilters){
            newFilters[filterIndex].subfilters[subfiltersIndex].subfilters.push(subFilterField)
        }
        else {
            newFilters[filterIndex].subfilters[subfiltersIndex].subfilters = [subFilterField]
        }
        setFilterAll(newFilters)
    }

    const deleteFilter = (filterIndex) => {
        let newFilters = [...filterAll]
        newFilters.splice(filterIndex, 1)
        setFilterAll(newFilters)
    }

    const deleteSubFilter = (filterIndex, subFilterIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters.splice(subFilterIndex, 1)
        setFilterAll(newFilters)
    }

    const deleteNestedSubFilter = (filterIndex, subFilterIndex, nestedSubFilterIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters.splice(nestedSubFilterIndex, 1)
        setFilterAll(newFilters)
    }

    const handleChangeFilterName = (e, filterIndex) => {
        const newFilters = [...filterAll]
        const filterName = e.target.value
        const filtersSame = newFilters.filter(item => item.name === filterName);
        if(filtersSame.length > 0){
            const message = 'Ya existe un filtro con el nombre "' + filterName + '"'
            setOpenedSnack({show: true, message, severity: 'error'})
            return;
        }
        newFilters[filterIndex].name = e.target.value;
        setFilterAll(newFilters)
    };

    const handleChangeSubFilterSelect = (e, filterIndex, subFilterIndex) => {
        const newFilters = [...filterAll]
        const filterName = e.target.value
        const subFiltersSame = newFilters[filterIndex].subfilters.filter(item => item.optionName === filterName);
        if(subFiltersSame.length > 0){
            const message = 'Ya existe un subfiltro con la opción "' + filterName + '"'
            setOpenedSnack({show: true, message, severity: 'error'})
            return;
        }
        newFilters[filterIndex].subfilters[subFilterIndex].optionName = e.target.value;
        setFilterAll(newFilters)
    };

    const handleChangeNestedSubFilterSelect = (e, filterIndex, subFilterIndex, nestedSubFilterIndex) => {
        const newFilters = [...filterAll]
        const filterName = e.target.value
        const subFiltersSame = newFilters[filterIndex].subfilters[subFilterIndex].subfilters.filter(item => item.optionName === filterName);
        if(subFiltersSame.length > 0){
            const message = 'Ya existe un subfiltro con la opción "' + filterName + '"'
            setOpenedSnack({show: true, message, severity: 'error'})
            return;
        }
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].optionName = e.target.value;
        setFilterAll(newFilters)
    };

    const handleChangeSubFilterName = (e, filterIndex, subFilterIndex) => {
        const newFilters = [...filterAll]
        const filterName = e.target.value
        const subFiltersSame = newFilters[filterIndex].subfilters.filter(item => item.name === filterName);
        if(subFiltersSame.length > 0){
            const message = 'Ya existe un subfiltro con el nombre "' + filterName + '"'
            setOpenedSnack({show: true, message, severity: 'error'})
            return;
        }
        newFilters[filterIndex].subfilters[subFilterIndex].name = e.target.value;
        setFilterAll(newFilters)
    };

    const handleChangeNestedSubFilterName = (e, filterIndex, subFilterIndex, nestedSubFilterIndex) => {
        const newFilters = [...filterAll]
        const filterName = e.target.value
        const subFiltersSame = newFilters[filterIndex].subfilters[subFilterIndex].subfilters.filter(item => item.name === filterName);
        if(subFiltersSame.length > 0){
            const message = 'Ya existe un subfiltro con el nombre "' + filterName + '"'
            setOpenedSnack({show: true, message, severity: 'error'})
            return;
        }
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].name = e.target.value;
        setFilterAll(newFilters)
    };

    const handleChangeFilterOption = (e, filterIndex, optionIndex) => {
        const newFilters = [...filterAll]
        newFilters[filterIndex].options[optionIndex] = e.target.value;
        setFilterAll(newFilters)
    }

    const handleChangeSubFilterOption = (e, filterIndex, subFilterIndex, optionIndex) => {
        const newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].options[optionIndex] = e.target.value;
        setFilterAll(newFilters)
    }

    const handleChangeNestedSubFilterOption = (e, filterIndex, subFilterIndex, nestedSubFilterIndex, optionIndex) => {
        const newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].options[optionIndex] = e.target.value;
        setFilterAll(newFilters)
    }

    const addFilterOption = (index) => {
        let newFilters = [...filterAll]
        newFilters[index].options.push("")
        setFilterAll(newFilters)
    }

    const addSubFilterOption = (filterIndex, subFilterIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].options.push("")
        setFilterAll(newFilters)
    }

    const addNestedSubFilterOption = (filterIndex, subFilterIndex, nestedSubFilterIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].options.push("")
        setFilterAll(newFilters)
    }

    const deleteFilterOption = (filterIndex, optionIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].options.splice(optionIndex, 1)
        setFilterAll(newFilters)
    }

    const deleteSubFilterOption = (filterIndex, subFilterIndex, optionIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].options.splice(optionIndex, 1)
        setFilterAll(newFilters)
    }

    const deleteNestedSubFilterOption = (filterIndex, subFilterIndex, nestedSubFilterIndex, optionIndex) => {
        let newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].options.splice(optionIndex, 1)
        setFilterAll(newFilters)
    }

    const FilterCards = () => {
        return filterAll.map((filter, filterIndex) => (
            <>
                <Grid item xs={12} md={4} key={"filter"+filterIndex}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                            }
                            title={"Filtro " + (filterIndex+3)}
                            subheader={filter.name}
                            action={
                                <IconButton aria-label="settings" onClick={() => deleteFilter(filterIndex)}>
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <TextField size="small" label="Nombre del filtro" fullWidth value={filter.name} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeFilterName(e, filterIndex)} />
                            <Button variant="outlined" size="small" sx={{margin: "20px 0"}} onClick={() => addFilterOption(filterIndex)}>Agregar opciones</Button><br/>
                            {filter.options.map((option, optionIndex) =>
                                <div key={'filterName'+filterIndex+optionIndex} style={{display:"flex", alignItems:"baseline"}}>
                                    <Typography variant="body" sx={{marginRight:"10px"}}>{optionIndex+1}.</Typography>
                                    <TextField size="small" fullWidth value={option} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeFilterOption(e, filterIndex, optionIndex)} />
                                    <IconButton onClick={() => deleteFilterOption(filterIndex, optionIndex)} ><DeleteIcon color='primary' /></IconButton>
                                </div>
                            )}
                            <Divider sx={{marginTop: "20px"}}/>
                            <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => addSubFilter(filterIndex)}>Agregar subfiltros</Button>
                        </CardContent>
                    </Card>
                </Grid>
                {filter?.subfilters?.map((subFilter, subFilterIndex) =>
                    <>
                        <Grid item xs={12} md={4} key={"subfil"+filterIndex+subFilterIndex}>
                            <Card key={'subFilterName'+filterIndex+subFilterIndex}>
                                <CardHeader
                                    sx={{backgroundColor: "rgba(58,58,59,0.09)"}}
                                    avatar={<IconButton aria-label="settings"><FilterAltOutlinedIcon /></IconButton>}
                                    title={"Filtro " + (filterIndex+3) + ": " + filter.name}
                                    subheader={"Subfiltro " + (subFilterIndex+1) + ": "+ subFilter.name}
                                    action={
                                        <IconButton aria-label="settings" onClick={() => deleteSubFilter(filterIndex, subFilterIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <TextField label="Nombre de subfiltro" size="small" fullWidth value={subFilter.name} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeSubFilterName(e, filterIndex, subFilterIndex)} />
                                    <FormControl fullWidth required>
                                        <InputLabel id={subFilter.name + filterIndex} size="small" label="sub" sx={{ marginTop: '20px'}}>Seleccione Subfiltro</InputLabel>
                                        <Select
                                            sx={{ marginTop: '20px'}}
                                            value={subFilter.optionName}
                                            onChange={(e) => handleChangeSubFilterSelect(e, filterIndex, subFilterIndex)}
                                            size="small"
                                        >
                                            {filter?.options.map((option, optionIndex) =>
                                                <MenuItem key={'subfilterName'+filterIndex+option+optionIndex} data={{
                                                    filterIndex,
                                                    subFilterIndex,
                                                    optionIndex
                                                }} value={option}>{option}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Button variant="outlined" size="small" sx={{margin: "20px 0"}} onClick={() => addSubFilterOption(filterIndex, subFilterIndex)}>Agregar opciones</Button><br/>
                                    {subFilter?.options?.map((option, optionIndex) =>
                                        <div key={'filterName'+filterIndex+optionIndex} style={{display:"flex", alignItems:"baseline"}}>
                                            <Typography variant="body" sx={{marginRight:"10px"}}>{optionIndex+1}.</Typography>
                                            <TextField size="small" fullWidth value={option} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeSubFilterOption(e, filterIndex, subFilterIndex, optionIndex)} />
                                            <IconButton onClick={() => deleteSubFilterOption(filterIndex, subFilterIndex, optionIndex)} ><DeleteIcon color='primary' /></IconButton>
                                        </div>
                                    )}
                                    <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={() => addNestedSubFilter(filterIndex,subFilterIndex)}>Agregar subfiltros</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        {subFilter?.subfilters?.map((nestedSubFilter, nestedSubFilterIndex) =>
                            <Grid item xs={12} md={4} key={"subfil"+filterIndex+nestedSubFilterIndex+subFilterIndex}>
                                <Card key={'subFilterName'+filterIndex+nestedSubFilterIndex+subFilterIndex}>
                                    <CardHeader
                                        sx={{backgroundColor: "rgba(58,58,59,0.09)"}}
                                        avatar={<IconButton aria-label="settings"><FilterAltOutlinedIcon /></IconButton>}
                                        title={"Filtro " + (filterIndex+3) + ": " + filter.name}
                                        subheader={"Subfiltro de "+ subFilter.name + ": "+ nestedSubFilter.name}
                                        action={
                                            <IconButton aria-label="settings" onClick={() => deleteNestedSubFilter(filterIndex, subFilterIndex, nestedSubFilterIndex)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                    />
                                    <CardContent>
                                        <TextField label="Nombre de subfiltro" size="small" fullWidth value={nestedSubFilter.name} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeNestedSubFilterName(e, filterIndex, subFilterIndex, nestedSubFilterIndex)} />
                                        <FormControl fullWidth required>
                                            <InputLabel id={nestedSubFilter.name + filterIndex} size="small" label="sub" sx={{ marginTop: '20px'}}>Seleccione Subfiltro</InputLabel>
                                            <Select
                                                sx={{ marginTop: '20px'}}
                                                value={nestedSubFilter.optionName}
                                                onChange={(e) => handleChangeNestedSubFilterSelect(e, filterIndex, subFilterIndex, nestedSubFilterIndex)}
                                                size="small"
                                            >
                                                {subFilter?.options.map((option, optionIndex) =>
                                                    <MenuItem key={'subfilterName'+filterIndex+option+optionIndex+nestedSubFilterIndex} data={{
                                                        filterIndex,
                                                        subFilterIndex,
                                                        nestedSubFilterIndex,
                                                        optionIndex
                                                    }} value={option}>{option}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <Button variant="outlined" size="small" sx={{margin: "20px 0"}} onClick={() => addNestedSubFilterOption(filterIndex, subFilterIndex, nestedSubFilterIndex)}>Agregar opciones</Button><br/>
                                        {nestedSubFilter?.options?.map((option, optionIndex) =>
                                            <div key={'filterName'+filterIndex+optionIndex} style={{display:"flex", alignItems:"baseline"}}>
                                                <Typography variant="body" sx={{marginRight:"10px"}}>{optionIndex+1}.</Typography>
                                                <TextField size="small" fullWidth value={option} sx={{ marginTop: '10px'}} onChange={(e) => handleChangeNestedSubFilterOption(e, filterIndex, subFilterIndex, nestedSubFilterIndex, optionIndex)} />
                                                <IconButton onClick={() => deleteNestedSubFilterOption(filterIndex, subFilterIndex, nestedSubFilterIndex, optionIndex)} ><DeleteIcon color='primary' /></IconButton>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </>

                )}
            </>
        ))
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenedSnack(false);
    }

    return <GenericCard title="Lista de Filtros">
        <Snackbar open={openedSnack.show} autoHideDuration={8000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                {openedSnack.message}
            </Alert>
        </Snackbar>
        <Box sx={{marginTop: "5px"}}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                            }
                            subheader="Familia"
                            title="Filtro 1"
                        />
                        <CardContent>
                            {isLoadingGeneralFamilies ?
                                <><CircularProgress/><span>Cargando información...</span></> :
                                <FormControl fullWidth sx={{marginTop: '20px'}} required>
                                    <InputLabel id="family" size="small">Familia</InputLabel>
                                    <Select
                                        labelId="family"
                                        id="family-select"
                                        value={familyValue}
                                        label="Familia"
                                        onChange={handleFamily}
                                        size="small"
                                    >
                                        <MenuItem value=''>Seleccione</MenuItem>
                                        {generalFamilies.map((family, i) => (
                                            <MenuItem key={'family' + i} data={family} value={family.name}>{family.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                            }
                            subheader="Categoría"
                            title="Filtro 2"
                        />
                        <CardContent>
                            <Button variant="outlined" size="small" sx={{margin: "20px 0"}} onClick={() => addCategories()}>Agregar categorías</Button>
                            {categories.map((category, index) =>
                                <div key={'categoriE'+index} style={{display:"flex"}}>
                                    <TextField size="small" fullWidth value={category} sx={{ marginTop: '10px'}} data={index} onChange={(e) => handleChangeCategory(e, index)} />
                                    <IconButton onClick={() => deleteCategoryHandler(index)} ><DeleteIcon color='primary' /></IconButton>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                { FilterCards()}
            </Grid>
            {loading ?
                <CircularProgress />:
                <>
                    <Button variant="outlined" sx={{marginTop: "40px", marginRight: "5px"}} onClick={() => addFilter()} disabled={!familyValue}>Agregar Nuevo Filtro</Button>
                    <Button variant="outlined" sx={{marginTop: "40px", marginRight: "5px"}} onClick={() => saveFamilyFilters()} disabled={!familyValue}>Guardar Filtros</Button>
                </>
            }
        </Box>
    </GenericCard>
}

export default Filters