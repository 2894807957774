import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {getCurrencies, createCurrency, updateCurrency, deleteCurrency} from "../api/currencies";
import ListCurrencies from "../components/Currencies/ListCurrencies";
import NewCurrency from "../components/Currencies/NewCurrency";
import EditCurrency from "../components/Currencies/EditCurrency";
const Currencies = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [currencies, setCurrencies] = useState([])
    const [page, setPage] = useState('listCurrencies');
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const {data: dataCurrencies, isLoading: isLoadingCurrencies} = useQuery('currencies', getCurrencies, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: createCurrencyMutation, isLoading: isLoadingCreateCurrency} = useMutation(createCurrency, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
            setCurrencies([...currencies, data])
            queryClient.invalidateQueries('currencies')
            setPage('listCurrencies')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateCurrencyMutation, isLoading: isLoadingUpdateCurrency} = useMutation(updateCurrency, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyCurrencies = [...currencies]
            const index = copyCurrencies.findIndex(p => p.id === data.id)
            copyCurrencies[index] = data
            setCurrencies(copyCurrencies)
            queryClient.invalidateQueries('currencies')
            setPage('listCurrencies')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: deleteCurrencyMutation, isLoading: isLoadingDeleteCurrency} = useMutation(deleteCurrency, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyCurrencies = [...currencies]
            const index = copyCurrencies.findIndex(p => p.id === data.id)
            copyCurrencies.splice(index, 1)
            setCurrencies(copyCurrencies)
            queryClient.invalidateQueries('currencies')
            setPage('listCurrencies')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if(dataCurrencies){
            setCurrencies(dataCurrencies)
        }
    }, [dataCurrencies]);

    const createCurrencyHandle = (currency) => {
        const answer = window.confirm(`¿Está seguro de agregar la unidad "${currency.name}"?`);
        if (!answer) {return}
        createCurrencyMutation(currency)
    }

    const updateCurrencyHandle = (currency) => {
        const answer = window.confirm(`¿Está seguro de actualizar la unidad "${currency.name}"?`);
        if (!answer) {return}
        updateCurrencyMutation(currency)
    }

    const deleteCurrencyHandle = (currency) => {
        const answer = window.confirm(`¿Está seguro de eliminar la unidad "${currency.name}"?`);
        if (!answer) {return}
        deleteCurrencyMutation(currency)
    }

    return (
        <>
            {page === 'listCurrencies' &&
                <ListCurrencies currencies={currencies} isLoading={isLoadingCurrencies || isLoadingDeleteCurrency} setPage={setPage} setSelectedCurrency={setSelectedCurrency} deleteCurrencyHandle={deleteCurrencyHandle} />
            }
            {page === 'newCurrency' &&
                <NewCurrency setPage={setPage} createCurrencyHandle={createCurrencyHandle} isLoading={isLoadingCreateCurrency}/>
            }
            {page === 'editCurrency' &&
                <EditCurrency currency={selectedCurrency} setPage={setPage} updateCurrencyHandle={updateCurrencyHandle} isLoading={isLoadingUpdateCurrency}/>
            }
        </>
    )
}

export default Currencies;
