import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit} from "@mui/icons-material";

const RowIncoterm = ({ incoterm, index, deleteIncotermHandle, setSelectedIncoterm, setPage}) => {
    const handleEdit = () => {
        setSelectedIncoterm(incoterm);
        setPage('editIncoterm');
    }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{incoterm?.code}</TableCell>
      <TableCell>{incoterm?.name}</TableCell>
      <TableCell>
        <div className="flex-center">
            <IconButton onClick={() => handleEdit(incoterm)}>
                <Edit color="primary"/>
            </IconButton>
              <IconButton onClick={() => deleteIncotermHandle(incoterm)}>
                <Delete color="primary" />
              </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default RowIncoterm;