import GenericCard from "../UI/GenericCard"
import React, {useState, useEffect} from "react"
import {
    Box, Button, TextField, IconButton, Link, TableContainer,
    Table, TableHead, TableRow, TableCell, TableFooter, TableBody, Typography
} from "@mui/material"
import {storage} from '../../firebase/firebaseApp'
import CircularProgress from "@mui/material/CircularProgress";
import {
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import {v4} from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";

const EditLiquitation = (props) => {
    const {order, product, onSubmit, isLoading, title, backPage} = props

    const liquitationInitial =  {
        "supplier": {name: "", docs: "", price: "", amount: ""},
        "alaxs": {name: "LatAm Trading Ltd.", docs: "", price: "", amount: ""},
        "tic": {name: "SBE - Qima", docs: "", price: "", amount: ""},
        "freight": {name: "Magaya Corp.", docs: "", price: "", amount: ""},
        "insurance": {name: "Zuru Latam", docs: "", price: "", amount: ""}
    }

    const uploadDocsInitial = {
        "supplier": {docs: []},
        "alaxs": {docs: []},
        "tic": {docs: []},
        "freight": {docs: []},
        "insurance": {docs: []}
    }
    const [uploadDocs, setUploadDocs] = useState(order?.liquitation || uploadDocsInitial);
    const [liquitation, setLiquitation] = useState(order?.liquitation || liquitationInitial)
    const [grandTotal, setGrandTotal] = useState(0)

    useEffect(() => {
        let total = 0
        Object.keys(liquitation).forEach(key => {
            total += parseFloat(liquitation[key].amount) || 0
        })
        setGrandTotal(total)
    }, [liquitation])

    const handleEditLiquitation = (e, type) => {
        const copyLiquitation = {...liquitation}
        copyLiquitation[type][e.target.name] = e.target.value
        setLiquitation(copyLiquitation)
    }

    const addDocChange = (e, type) => {
        let newDocs = {...uploadDocs}
        newDocs[type]["docs"] = [...newDocs[type]["docs"], {file: e.target.files[0], name: e.target.files[0].name}]
        setUploadDocs(newDocs)
    }

    const deleteDoc = (docIndex, type) => {
        let newDocs = {...uploadDocs}
        newDocs[type]["docs"].splice(docIndex, 1)
        setUploadDocs(newDocs)
    }

    const uploadFiles = async (fileType) => {
        let fileUrls = [];
        if (fileType == null) return fileUrls

        fileType.forEach(item => {
            if (item.name === undefined) {
                fileUrls.push(item)
            } else {
                const fileName = v4() + item.name;
                const fileRef = ref(storage, `${fileName}`);
                fileUrls.push(fileName);
                try {
                    uploadBytes(fileRef, item.file).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {});
                    });
                } catch (error) {
                    console.error('Error al subir el archivo:', error);
                }
            }

        })
        return fileUrls;
    };

    const updateOrder = async () => {
        const copyLiquidation = {...liquitation}

        for (let key in copyLiquidation) {
            if (copyLiquidation.hasOwnProperty(key)) {
                if(key !== "grandTotal"){
                    copyLiquidation[key].docs = await uploadFiles(uploadDocs[key].docs)
                }
            }
        }

        copyLiquidation["grandTotal"] = grandTotal

        const request = {
            id: order.id,
            liquitation: copyLiquidation
        }

        onSubmit(request, order.product)
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link className='mt-12' component='button' onClick={backPage}
              sx={{marginBottom: '20px'}}>{'< Regresar'}</Link>
        <Typography variant="button" display="block" sx={{marginBottom: "15px"}}><strong>{title}</strong></Typography>
        <GenericCard title={"Producto: " + order.product.name}>
            <Typography color="primary" variant="subtitle2">{"ID ORDER: " + order.id}</Typography>
            {order?.finalAmount && <Typography variant="subtitle2">Monto final referencial: {new Intl.NumberFormat('es-MX',  {minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(order.finalAmount)}</Typography>}
            <TableContainer sx={{marginTop: "20px"}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{background: "#eceff1"}}>
                            <TableCell sx={{width: "200px"}}>WHO</TableCell>
                            <TableCell sx={{width: "200px"}}>NAME</TableCell>
                            <TableCell sx={{width: "200px"}}>USD</TableCell>
                            <TableCell sx={{width: "300px"}}>DOC</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Supplier</TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" sx={{margin: '10px 0'}}>{order?.product?.supplier?.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField size="small" label="Ingrese" fullWidth
                                           name="amount" value={liquitation?.supplier?.amount}
                                           onChange={e => handleEditLiquitation(e, "supplier")}/>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" component="label">
                                    Adjuntar Docs
                                    <input
                                        type="file"
                                        onChange={(e) => addDocChange(e, "supplier")}
                                        hidden
                                    />
                                </Button>
                                {uploadDocs?.supplier?.docs?.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex, "supplier")}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>ALAXS</TableCell>
                            <TableCell>
                                <TextField size="small" name="name"
                                           value={liquitation?.alaxs?.name}
                                           onChange={e => handleEditLiquitation(e, "alaxs")}/>
                            </TableCell>
                            <TableCell>
                                <TextField size="small" label="Ingrese" fullWidth
                                           name="amount" value={liquitation?.alaxs?.amount}
                                           onChange={e => handleEditLiquitation(e, "alaxs")}/>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" component="label">
                                    Adjuntar Docs
                                    <input
                                        type="file"
                                        onChange={(e) => addDocChange(e, "alaxs")}
                                        hidden
                                    />
                                </Button>
                                {uploadDocs?.alaxs?.docs?.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex, "alaxs")}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>TIC</TableCell>
                            <TableCell>
                                <TextField size="small" name="name"
                                           value={liquitation?.tic?.name}
                                           onChange={e => handleEditLiquitation(e, "tic")}/>
                            </TableCell>
                            <TableCell>
                                <TextField size="small" label="Ingrese" fullWidth
                                           name="amount" value={liquitation?.tic?.amount}
                                           onChange={e => handleEditLiquitation(e, "tic")}/>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" component="label">
                                    Adjuntar Docs
                                    <input
                                        type="file"
                                        onChange={(e) => addDocChange(e, "tic")}
                                        hidden
                                    />
                                </Button>
                                {uploadDocs?.tic?.docs?.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex, "tic")}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>FREIGHT</TableCell>
                            <TableCell>
                                <TextField size="small" name="name"
                                           value={liquitation?.freight?.name}
                                           onChange={e => handleEditLiquitation(e, "freight")}/>
                            </TableCell>
                            <TableCell>
                                <TextField size="small" label="Ingrese" fullWidth
                                           name="amount" value={liquitation?.freight?.amount}
                                           onChange={e => handleEditLiquitation(e, "freight")}/>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" component="label">
                                    Adjuntar Docs
                                    <input
                                        type="file"
                                        onChange={(e) => addDocChange(e, "freight")}
                                        hidden
                                    />
                                </Button>
                                {uploadDocs?.freight?.docs?.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex, "freight")}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>INSURANCE</TableCell>
                            <TableCell>
                                <TextField size="small" name="name"
                                           value={liquitation?.insurance?.name}
                                           onChange={e => handleEditLiquitation(e, "insurance")}/>
                            </TableCell>
                            <TableCell>
                                <TextField size="small" label="Ingrese" fullWidth
                                           name="amount" value={liquitation?.insurance?.amount}
                                           onChange={e => handleEditLiquitation(e, "insurance")}/>
                            </TableCell>
                            <TableCell>
                                <Button variant="outlined" component="label">
                                    Adjuntar Docs
                                    <input
                                        type="file"
                                        onChange={(e) => addDocChange(e, "insurance")}
                                        hidden
                                    />
                                </Button>
                                {uploadDocs?.insurance?.docs?.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex, "insurance")}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant="subtitle2" sx={{margin: '10px 0'}}>GRAN TOTAL</Typography>
                            </TableCell>
                            <TableCell>
                                {grandTotal}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </GenericCard>
        {isLoading ?
            <CircularProgress/> :
            <Button variant="outlined" sx={{marginTop: "40px", marginRight: "5px"}}
                    onClick={() => updateOrder()} >Actualizar Pedido</Button>
        }
    </Box>
}

export default EditLiquitation


