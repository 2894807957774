import React from "react";
import FormCurrency from "./FormCurrency";

const NewCurrency = (props) => {
    const { setPage, createCurrencyHandle, isLoading } = props;

    return (
        <FormCurrency
            title='Agregar Moneda'
            setPage={setPage}
            onSubmit={createCurrencyHandle}
            isLoading={isLoading}
        >
        </FormCurrency>

    )
}

export default NewCurrency