import FormUnit from "./FormUnit";

const EditUnit = (props) => {
    const {unit, setPage, updateUnitHandle, isLoading } = props;

    return (
        <FormUnit
            title='Editar Unidad'
            setPage={setPage}
            onSubmit={updateUnitHandle}
            isLoading={isLoading}
            unit={unit}
        />
    )
}

export default EditUnit