import GenericCard from "../UI/GenericCard";
import {Box, TextField, Button, Divider} from '@mui/material';

const ViewUser = ({user, closeViewUser, editUser}) => {
    const editUserHandler = () => {
        editUser(user);
    }

    const closeHandler = () => {
        closeViewUser();
    }

    return <Box sx={{marginTop: '20px'}}>
       <GenericCard title="Datos del Usuario">
            <TextField label="Correo" size="small" fullWidth type="email" disabled value={user.email} sx={{ marginTop: '10px'}}/>
            <TextField label="Perfil" size="small" fullWidth type="text" disabled value={user.profile} sx={{ marginTop: '20px'}}/>
            <TextField label="Activo" size="small" fullWidth type="text" disabled value={user.active ? "Si" : "No"} sx={{ marginTop: '20px'}}/>
            
            <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
            <Button variant="outlined" onClick={editUserHandler}>Editar Usuario</Button>
            <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cerrar</Button>
       </GenericCard>
    </Box>
}

export default ViewUser;