import { Box, TextField, Divider, Button } from "@mui/material"
import GenericCard from "../UI/GenericCard"
import { useState } from "react"

const NewSupplier = ({createNewSupplier, cancel}) => {
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [email, setEmail] = useState('')

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    const changeUrlHandler = e => {
        setUrl(e.target.value)
    }

    const changeEmailHandler = e => {
        setEmail(e.target.value)
    }

    const createSupplierHandler = () => {
        if (name.trim() !== '' && url.trim() !== '') {
            const supplier = {
                name: name,
                url: url,
                email: email
            }
            createNewSupplier(supplier)
            cancel()
        }
    }

    const closeHandler = () => {
        cancel()
    }

    return <Box sx={{marginTop: '20px'}}>
        <GenericCard title="Nuevo Supplier">
            <TextField label="Correo" size="small" fullWidth required type="email" value={email} onChange={changeEmailHandler}/>
            <TextField label="Nombre" size="small" fullWidth required type="text" value={name} onChange={changeNameHandler} sx={{marginTop: '20px'}} />
            <TextField label="Url" size="small" fullWidth required type="url" value={url} onChange={changeUrlHandler} sx={{marginTop: '20px'}} />
            <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
            <Button variant="outlined" onClick={createSupplierHandler}>Crear Supplier</Button>
            <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cancelar</Button>
        </GenericCard>
    </Box>
}

export default NewSupplier