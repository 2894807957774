import React, {forwardRef} from 'react';
import RowOrder from "./RowOrder";
export const ComponentPrint = forwardRef((props, ref) => {

    const {order, orderToPrint} = props;

    return (
        <div ref={ref}>
           <RowOrder order={order}
                     index={orderToPrint.index}
                     quantity={orderToPrint.quantity}
           />
        </div>
    )
})