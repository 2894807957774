import FormCurrency from "./FormCurrency";

const EditCurrency = (props) => {
    const {currency, setPage, updateCurrencyHandle, isLoading } = props;

    return (
        <FormCurrency
            title='Editar Moneda'
            setPage={setPage}
            onSubmit={updateCurrencyHandle}
            isLoading={isLoading}
            currency={currency}
        />
    )
}

export default EditCurrency