import GenericCard from "../UI/GenericCard"
import React, {useEffect, useState} from "react"
import {useQuery} from 'react-query';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    FormControl, InputLabel, Select, MenuItem, Button, TextField, IconButton, Link, TableContainer,
    Table, TableHead, TableRow, TableCell, TableFooter, TableBody, Typography, Switch, Stack
} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers";
import {storage} from '../../firebase/firebaseApp'
import CircularProgress from "@mui/material/CircularProgress";
import {
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import {v4} from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import BallotIcon from '@mui/icons-material/Ballot';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getGeneralFamilies} from "../../api/generalFamilies";
import {getUnits} from "../../api/units";
import {getCurrencies} from "../../api/currencies";
import {getIncoterms} from "../../api/incoterms";

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const FormProduct = (props) => {
    const {product, suppliers, onSubmit, setSnackbar, isLoading, title, RenewPrice, priceRanges, setPriceRanges, renewPrice, setRenewPrice, backPage} = props
    const {
        data: dataGeneralFamilies,
        isLoading: isLoadingGeneralFamilies
    } = useQuery('generalFamilies', getGeneralFamilies, {staleTime: 3600000});
    const {data: dataUnits, isLoading: isLoadingUnits} = useQuery('units', getUnits, {staleTime: 3600000});
    const {
        data: dataCurrencies,
        isLoading: isLoadingCurrencies
    } = useQuery('currencies', getCurrencies, {staleTime: 3600000});
    const {
        data: dataIncoterms,
        isLoading: isLoadingIncoterms
    } = useQuery('incoterms', getIncoterms, {staleTime: 3600000});

    const [supplier, setSupplier] = useState(product?.supplier || [])
    const [familyValue, setFamilyValue] = useState(product?.family || "")
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(product?.category || "")
    const [name, setName] = useState(product?.name || "")
    const [filterAll, setFilterAll] = useState([])
    const [optionSelected, setOptionSelected] = useState(product?.filters || [])
    const [images, setImages] = useState(product?.images || []);
    const [docs, setDocs] = useState(product?.docs || []);
    const [incoterm, setIncoterm] = useState(product?.incoterm || "")
    const [unit, setUnit] = useState(product?.unit?.id || "")
    const [priceUnit, setPriceUnit] = useState(product?.priceUnit?.id || "")
    const [currency, setCurrency] = useState(product?.currency || "")
    const [reference, setReference] = useState(product?.reference || "")

    useEffect(() => {
        if (familyValue !== "" && dataGeneralFamilies?.length > 0) {
            const copyDataGeneralFamilies = [...dataGeneralFamilies]
            const findFamily = copyDataGeneralFamilies.find(family => family.name === familyValue)
            setCategories(findFamily.categories)
            setFilterAll(findFamily.filters)
        }
    }, [dataGeneralFamilies])

    const handlePriceRanges = (e, type, i) => {
        const newPriceRanges = [...priceRanges]
        newPriceRanges[i][type][e.target.name] = e.target.value
        setPriceRanges(newPriceRanges)
    }

    const handleRenewPriceRange = (e, type) => {
        const renewPriceRanges = {...renewPrice}
        renewPriceRanges[type][e.target.name] = e.target.value
        setRenewPrice(renewPriceRanges)
    }

    const handleImagesChange = (e) => {
        let newImages = [...images]
        newImages.push({
            file: e.target.files[0],
            name: e.target.files[0].name,
            url: [URL.createObjectURL(e.target.files[0])]
        })
        setImages(newImages)
    }

    const deleteImage = (imageIndex) => {
        let newImages = [...images]
        newImages.splice(imageIndex, 1)
        setImages(newImages)
    }

    const addDocChange = (e) => {
        let newDocs = [...docs]
        newDocs.push({file: e.target.files[0], name: e.target.files[0].name})
        setDocs(newDocs)
    }

    const deleteDoc = (docIndex) => {
        let newDocs = [...docs]
        newDocs.splice(docIndex, 1)
        setDocs(newDocs)
    }

    const uploadFiles = async (fileType) => {
        let fileUrls = [];
        if (fileType == null) return fileUrls

        fileType.forEach(item => {
            if (item.name === undefined) {
                fileUrls.push(item)
            } else {
                const fileName = v4() + item.name;
                const fileRef = ref(storage, `${fileName}`);
                fileUrls.push(fileName);
                try {
                    uploadBytes(fileRef, item.file).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                        });
                    });
                } catch (error) {
                    console.error('Error al subir el archivo:', error);
                }
            }

        })
        return fileUrls;
    };

    const handleFamily = (e, action) => {
        if (familyValue !== '') {
            setCategories([])
            setOptionSelected([])
        }
        setFamilyValue(e.target.value);
        setCategories(action?.props?.data?.categories || []);
        setFilterAll(action?.props?.data?.filters || [])
        setOptionSelected(action?.props?.data?.filters || [])
    };

    const handleSupplier = (e, action) => {
        setSupplier({name: e.target.value, id: action?.props?.data?.id || ""});
    };

    const handleDatePrice = (e, i, type) => {
        const updatePriceRanges = [...priceRanges]
        updatePriceRanges[i][type] = e
        setPriceRanges(updatePriceRanges)
    }

    const handleDateRenewPrice = (e, type) => {
        const updateRenewPriceRanges = {...renewPrice}
        updateRenewPriceRanges[type] = e
        setRenewPrice(updateRenewPriceRanges)
    }

    const saveProduct = () => {
        const isFilterNameEmpty = filterAll?.filter(item => item.name === "").length > 0;

        if (isFilterNameEmpty || familyValue === "" || category === "" || name === "" || !supplier.name) {
            setSnackbar({show: true, message: 'Debe completar los datos requeridos', severity: 'error'})
            return;
        }
        let updatedFilters = []
        if (optionSelected?.length > 0) {
            updatedFilters = optionSelected?.map(filter => {
                const {options, ...rest} = filter;
                if (rest.subfilters) {
                    rest.subfilters = rest.subfilters.filter(item => item.option).map(subfilter => {
                        const {options, ...subRest} = subfilter;
                        if (subRest.subfilters) {
                            subRest.subfilters = subRest.subfilters.filter(subitem => subitem.option).map(nestedSubfilter => {
                                const {options, ...nestedSubRest} = nestedSubfilter;
                                return nestedSubRest;
                            });
                        }
                        return subRest;
                    });
                }
                return rest;
            });
        }
        let copyRenewPrice
        if(renewPrice !== null) {
            copyRenewPrice = {...renewPrice}
            copyRenewPrice["startDate"] = new Date(copyRenewPrice["startDate"]).toISOString()
            copyRenewPrice["endDate"] = new Date(copyRenewPrice["endDate"]).toISOString()
        }

        uploadFiles(images).then((imagesUrl) => {
            uploadFiles(docs).then((docsUrl) => {
                const request = {
                    family: familyValue,
                    category,
                    filters: updatedFilters,
                    name,
                    supplier,
                    images: imagesUrl,
                    docs: docsUrl,
                    currency,
                    reference,
                    priceRanges: renewPrice !== null ? [...priceRanges, copyRenewPrice] : priceRanges,
                    incoterm,
                    unit: dataUnits.find(u => u.id === unit),
                    priceUnit: dataUnits.find(u => u.id === priceUnit)
                }
                if (product?.id) {
                    request.id = product.id
                }
                onSubmit(request)
            })
        })
    }

    const handleChangeCategory = (e) => {
        setCategory(e.target.value)
    }

    const handleChangeFilterOption = (e, action) => {
        const {filterIndex} = action?.props?.data;
        const newFilters = [...filterAll]
        newFilters[filterIndex].name = filterAll[filterIndex].name;
        newFilters[filterIndex].option = e.target.value;
        setOptionSelected(newFilters)
    }

    const handleChangeSubFilterOption = (e, filterIndex, subFilterIndex) => {
        const newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].name = filterAll[filterIndex].subfilters[subFilterIndex].name;
        newFilters[filterIndex].subfilters[subFilterIndex].optionName = filterAll[filterIndex].subfilters[subFilterIndex].optionName;
        newFilters[filterIndex].subfilters[subFilterIndex].option = e.target.value;
        setOptionSelected(newFilters)
    }

    const handleChangeNestedSubFilterOption = (e, filterIndex, subFilterIndex, nestedSubFilterIndex) => {
        const newFilters = [...filterAll]
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].name = filterAll[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].name;
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].optionName = filterAll[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].optionName;
        newFilters[filterIndex].subfilters[subFilterIndex].subfilters[nestedSubFilterIndex].option = e.target.value;
        setOptionSelected(newFilters)
    }

    const hideFilter = (checked, filterIndex) => {
        const newFilters = [...optionSelected]
        newFilters[filterIndex].show = checked;
        setOptionSelected(newFilters)
    }

    const FilterCards = () => {
        return filterAll?.map((filter, filterIndex) => (
            <>
                <Grid item xs={12} md={4} key={"filter" + filterIndex}>
                    <Card sx={{maxWidth: 345}}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1", justifyContent:"center"}}
                            avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                            title={filter.name}
                            action={
                                <Stack direction="column" alignItems="baseline" justifyContent="end">
                                    <Typography variant="caption" sx={{color: '#00000061'}}>Mostrar filtro</Typography>
                                    <div>
                                        <Typography variant="caption" sx={{color: '#00000061'}}>No</Typography>
                                        <Switch checked={optionSelected[filterIndex]?.show === undefined ? true : optionSelected[filterIndex]?.show} size="small" onChange={e => hideFilter(e.target.checked, filterIndex)} />
                                        <Typography variant="caption" sx={{color: '#00000061'}}>Si</Typography>
                                    </div>
                                </Stack>
                            }
                        />
                        <CardContent>
                            <FormControl fullWidth required>
                                <InputLabel id={filter.name + filterIndex} size="small">Seleccione</InputLabel>
                                <Select
                                    value={optionSelected[filterIndex]?.option || ''}
                                    label={filter.name + filterIndex}
                                    onChange={handleChangeFilterOption}
                                    size="small"
                                >
                                    {filter?.options.map((option, optionIndex) =>
                                        <MenuItem key={'filterName' + filterIndex + optionIndex} data={{filterIndex, optionIndex}} value={option}>{option}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
                {filter?.subfilters?.map((subFilter, subFilterIndex) => (
                    optionSelected[filterIndex]?.option === subFilter.optionName && (
                        <>
                            <Grid item xs={12} md={4} key={"subfil" + filterIndex + subFilterIndex}>
                                <Card key={'subFilterName' + filterIndex + subFilterIndex}>
                                    <CardHeader
                                        sx={{backgroundColor: "rgba(58,58,59,0.09)"}}
                                        avatar={<IconButton aria-label="settings"><BallotOutlinedIcon/></IconButton>}
                                        title={"Filtro: " + filter.name}
                                        subheader={"Subfiltro: " + subFilter.name}
                                    />
                                    <CardContent>
                                        <FormControl fullWidth required>
                                            <InputLabel id={subFilter.name} size="small">Seleccione</InputLabel>
                                            <Select
                                                value={optionSelected[filterIndex]?.subfilters[subFilterIndex]?.option || ''}
                                                onChange={(e) => handleChangeSubFilterOption(e, filterIndex, subFilterIndex)}
                                                size="small"
                                            >
                                                {subFilter?.options.map((option, optionIndex) =>
                                                    <MenuItem key={'subfilterName' + filterIndex + option + optionIndex}
                                                              value={option}>{option}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {subFilter?.subfilters?.map((nestedSubFilter, nestedSubFilterIndex) => (
                                optionSelected[filterIndex]?.subfilters[subFilterIndex]?.option === nestedSubFilter.optionName && (
                                    <Grid item xs={12} md={4}
                                          key={"subfil" + filterIndex + nestedSubFilterIndex + subFilterIndex}>
                                        <Card
                                            key={'subFilterName' + filterIndex + nestedSubFilterIndex + subFilterIndex}>
                                            <CardHeader
                                                sx={{backgroundColor: "rgba(58,58,59,0.09)"}}
                                                avatar={<IconButton
                                                    aria-label="settings"><BallotOutlinedIcon/></IconButton>}
                                                title={"Filtro: " + filter.name}
                                                subheader={"Subfiltro de " + subFilter.name + ": " + nestedSubFilter.name}
                                            />
                                            <CardContent>
                                                <FormControl fullWidth required>
                                                    <InputLabel id={nestedSubFilter.name + filterIndex} size="small">Seleccione
                                                        Subfiltro</InputLabel>
                                                    <Select
                                                        value={optionSelected[filterIndex]?.subfilters[subFilterIndex].subfilters[nestedSubFilterIndex]?.option || ''}
                                                        onChange={(e) => handleChangeNestedSubFilterOption(e, filterIndex, subFilterIndex, nestedSubFilterIndex)}
                                                        size="small"
                                                    >
                                                        {nestedSubFilter?.options.map((option, optionIndex) =>
                                                            <MenuItem key={'subfilterName' + filterIndex + option + optionIndex + nestedSubFilterIndex}
                                                                value={option}>{option}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            ))}
                        </>
                    )
                ))}
            </>
        ))
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link className='mt-12' component='button' onClick={backPage}
              sx={{marginBottom: '20px'}}>{'< Regresar'}</Link>
        <Typography variant="button" display="block" sx={{marginBottom: "15px"}}><strong>{title}</strong></Typography>
        <GenericCard title="I. INFORMACIÓN GENERAL">
            <Box sx={{marginTop: "5px"}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth required>
                            <InputLabel id="Supplier" size="small">Supplier</InputLabel>
                            <Select
                                labelId="Supplier"
                                id="Supplier-select"
                                value={supplier.name}
                                label="Proveedor"
                                onChange={handleSupplier}
                                size="small"
                            >
                                {suppliers.map((supplierI) =>
                                    <MenuItem key={"supp" + supplierI.id} value={supplierI.name}
                                              data={supplierI}>{supplierI.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField size="small" label="Descripción del producto" fullWidth value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField size="small" label="Referencia" fullWidth value={reference}
                                   onChange={(e) => setReference(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{maxWidth: 345}}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                                title="Agregar imágenes"
                            />
                            <CardContent>
                                <Button sx={{marginBottom: "10px"}}
                                        variant="contained"
                                        component="label"
                                >
                                    Seleccionar imagen
                                    <input
                                        type="file"
                                        onChange={handleImagesChange}
                                        hidden
                                    />
                                </Button>
                                {images.map((item, imageIndex) =>
                                    <div key={"img" + imageIndex} className="card-image-text-icon">
                                        <img src={item.url || STORAGE_BASE_URL + BUCKET_NAME + "/" + item}/>
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton className="delete" aria-label="settings"
                                                    onClick={() => deleteImage(imageIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{maxWidth: 345}}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                                title="Agregar especificaciones"
                            />
                            <CardContent>
                                <Button sx={{marginBottom: "10px"}}
                                        variant="contained"
                                        component="label"
                                >
                                    Seleccionar SPECS
                                    <input
                                        type="file"
                                        onChange={addDocChange}
                                        hidden
                                    />
                                </Button>
                                {docs.map((item, docIndex) =>
                                    <div key={"docs" + docIndex} className="card-text-icon">
                                        <Typography variant="caption"><strong>Nombre archivo:</strong> {item.name || item}</Typography>
                                        <IconButton aria-label="settings" onClick={() => deleteDoc(docIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </GenericCard>
        <GenericCard title="II. FAMILIA / CATEGORÍAS">
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card sx={{maxWidth: 345}}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                            title="Familia"
                        />
                        <CardContent>
                            <FormControl fullWidth required>
                                <InputLabel id="family" size="small">Seleccione</InputLabel>
                                <Select
                                    labelId="family"
                                    id="family-select"
                                    value={familyValue}
                                    label="Familia"
                                    onChange={handleFamily}
                                    size="small"
                                >
                                    {isLoadingGeneralFamilies ?
                                        <MenuItem value=""><em>Cargando "Familias"...</em></MenuItem> :
                                        dataGeneralFamilies?.map((family, i) => <MenuItem key={'fam' + i} data={family} value={family.name}>{family.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{maxWidth: 345}}>
                        <CardHeader
                            sx={{backgroundColor: "#eceff1"}}
                            avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                            title="Categoría"
                        />
                        <CardContent>
                            <FormControl fullWidth required>
                                <InputLabel id="Categoría" size="small">Seleccione</InputLabel>
                                <Select
                                    id="Category-select"
                                    value={category}
                                    label="Categoría"
                                    onChange={handleChangeCategory}
                                    size="small"
                                >
                                    {categories.map((categoryI, i) => <MenuItem key={'catE' + i}
                                                                                value={categoryI}>{categoryI}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
                {FilterCards()}
            </Grid >
        </GenericCard>
        <GenericCard title="III. INFORMACIÓN DE PRECIOS">
            <Grid container spacing={4} sx={{marginBottom: "30px"}}>
                <Grid item md={3}>
                    <FormControl fullWidth sx={{marginTop: '20px'}}>
                        <InputLabel id="currency-label" size="small">Moneda</InputLabel>
                        <Select
                            labelId="currency-label"
                            id="currency"
                            value={currency}
                            size="small"
                            label="moneda"
                            onChange={(e) => setCurrency(e.target.value)}
                        >
                            {isLoadingCurrencies ?
                                <MenuItem value=""><em>Cargando "Monedas"...</em></MenuItem> :
                                dataCurrencies?.map((currency, i) => <MenuItem key={'currency' + i}
                                                                               value={currency?.code}>{currency?.symbol} {currency?.code}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3}>
                    <FormControl fullWidth sx={{marginTop: '20px'}}>
                        <InputLabel id="incoterm-label" size="small">Condición de venta</InputLabel>
                        <Select
                            labelId="incoterm-label"
                            id="incoterm"
                            value={incoterm}
                            size="small"
                            label="Condición de venta"
                            onChange={(e) => setIncoterm(e.target.value)}
                        >
                            {isLoadingIncoterms ?
                                <MenuItem value=""><em>Cargando "Condición de venta"...</em></MenuItem> :
                                dataIncoterms?.map((incoterm, i) => <MenuItem key={'incoterm' + i} value={incoterm?.code}>{incoterm?.code}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3}>
                    <FormControl fullWidth sx={{marginTop: '20px'}}>
                        <InputLabel id="priceUnit" size="small">Unidad de precio</InputLabel>
                        <Select
                            labelId="priceUnit"
                            id="priceUnit-select"
                            value={priceUnit}
                            label="Unidad de precio"
                            onChange={(e) => setPriceUnit(e.target.value)}
                            size="small"
                        >
                            {isLoadingUnits ?
                                <MenuItem value=""><em>Cargando "Unidades de precio"...</em></MenuItem> :
                                dataUnits?.map((priceUnit, i) => <MenuItem key={'priceUnit' + i} value={priceUnit.id}>{priceUnit?.symbol}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3}>
                    <FormControl fullWidth sx={{marginTop: '20px'}}>
                        <InputLabel id="unit" size="small">Unidad de cantidad</InputLabel>
                        <Select
                            labelId="unit"
                            id="unit-select"
                            value={unit}
                            label="Unidad de cantidad"
                            onChange={(e) => setUnit(e.target.value)}
                            size="small"
                        >
                            {isLoadingUnits ?
                                <MenuItem value=""><em>Cargando "Unidades"...</em></MenuItem> :
                                dataUnits?.map((unit, i) =>
                                    <MenuItem key={'unit' + i} value={unit.id}>{unit?.symbol}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {product?.currentPrice && <Typography sx={{margin:"20px 0 10px"}} color="primary" variant="subtitle2">HISTORIAL PRECIOS:</Typography>}
            {priceRanges?.map((priceRange, i) => (
                product?.currentPrice ?
                    <Accordion key={"AccpriceRange" + i} defaultExpanded={priceRanges.length < 2 } sx={{border: "1px solid #eeeded"}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography sx={{ width: '200px', flexShrink: 0 }}>{i+1}. Detalle precio</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Fecha vigencia precio:
                                    {priceRange?.startDate ? new Date(priceRange?.startDate).toLocaleDateString(): ""} -
                                    {priceRange?.endDate ? new Date(priceRange?.endDate).toLocaleDateString(): ""}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{background: "#eceff1"}}>
                                                <TableCell>Tipo precio</TableCell>
                                                <TableCell>Rango Cantidades</TableCell>
                                                <TableCell>Precio</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            <TableRow>
                                                <TableCell>Inicial</TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Typography variant="subtitle2">Desde: {priceRange?.inicial?.from} {product?.unit?.symbol}</Typography>
                                                        <Typography variant="subtitle2">Hasta: {priceRange?.inicial?.to} {product?.unit?.symbol}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">Precio: {priceRange?.inicial?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Negocio</TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Typography variant="subtitle2">Desde: {priceRange?.negocio?.from} {product?.unit?.symbol}</Typography>
                                                        <Typography variant="subtitle2">Hasta: {priceRange?.negocio?.to} {product?.unit?.symbol}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">Precio: {priceRange?.negocio?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Volumen</TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Typography variant="subtitle2">Desde: {priceRange?.volumen?.from} {product?.unit?.symbol}</Typography>
                                                        <Typography variant="subtitle2">Hasta: {priceRange?.volumen?.to} {product?.unit?.symbol}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">Precio: {priceRange?.volumen?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Lista</TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" sx={{ margin: '10px 0'}}>Cantidad: </Typography>
                                                    <div style={{display: "flex", gap: "10px"}}>
                                                        <Typography variant="subtitle2">Desde: {priceRange?.lista?.from} {product?.unit?.symbol}</Typography>
                                                        <Typography variant="subtitle2">Hasta: {priceRange?.lista?.to} {product?.unit?.symbol}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2">Precio: {priceRange?.lista?.price} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    :
                    <TableContainer sx={{marginTop: "20px"}} key={"priceRange" + i}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{background: "#eceff1"}}>
                                    <TableCell>Tipo precio</TableCell>
                                    <TableCell>Rango Cantidades</TableCell>
                                    <TableCell>Precio</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Inicial</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={priceRange?.inicial?.from}
                                                       onChange={e => handlePriceRanges(e, "inicial", i)}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={priceRange?.inicial?.to}
                                                       onChange={e => handlePriceRanges(e, "inicial", i)}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={priceRange?.inicial?.price}
                                                   onChange={e => handlePriceRanges(e, "inicial", i)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Negocio</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={priceRange?.negocio?.from}
                                                       onChange={e => handlePriceRanges(e, "negocio", i)}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={priceRange?.negocio?.to}
                                                       onChange={e => handlePriceRanges(e, "negocio", i)}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={priceRange?.negocio?.price}
                                                   onChange={e => handlePriceRanges(e, "negocio", i)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Volumen</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={priceRange?.volumen?.from}
                                                       onChange={e => handlePriceRanges(e, "volumen", i)}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={priceRange?.volumen?.to}
                                                       onChange={e => handlePriceRanges(e, "volumen", i)}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={priceRange?.volumen?.price}
                                                   onChange={e => handlePriceRanges(e, "volumen", i)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lista</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={priceRange?.lista?.from}
                                                       onChange={e => handlePriceRanges(e, "lista", i)}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={priceRange?.lista?.to}
                                                       onChange={e => handlePriceRanges(e, "lista", i)}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={priceRange?.lista?.price}
                                                   onChange={e => handlePriceRanges(e, "lista", i)}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        <DatePicker size="small" label="Fecha inicial de precio"
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            name: "startDate",
                                                            format: "dd/MM/yyyy"
                                                        }
                                                    }}
                                                    sx={{marginTop: '20px', width: "200px"}}
                                                    value={priceRange?.startDate}
                                                    onChange={(e) => handleDatePrice(e, i, "startDate")}/>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{width: "140px"}}>
                                            <DatePicker size="small" label="Fecha final de precio"
                                                        slotProps={{
                                                            textField: {
                                                                size: "small",
                                                                name: "endDate",
                                                                format: "dd/MM/yyyy"
                                                            }
                                                        }}
                                                        sx={{marginTop: '20px', width: "200px"}}
                                                        value={priceRange?.endDate}
                                                        onChange={(e) => handleDatePrice(e, i, "endDate")}/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
            ))}
            {typeof product?.currentPrice === "string" && <Alert sx={{marginTop: "20px"}} severity="warning">{product?.currentPrice}</Alert>}
            {renewPrice !== null &&
                <>
                    <Typography sx={{margin:"20px 0 10px"}} color="primary" variant="subtitle2">RENOVACIÓN DE PRECIOS:</Typography>
                    <TableContainer sx={{marginTop: "20px"}}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{background: "#eceff1"}}>
                                    <TableCell>Tipo precio</TableCell>
                                    <TableCell>Rango Cantidades</TableCell>
                                    <TableCell>Precio</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Inicial</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={renewPrice?.inicial?.from}
                                                       onChange={e => handleRenewPriceRange(e, "inicial")}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={renewPrice?.inicial?.to}
                                                       onChange={e => handleRenewPriceRange(e, "inicial")}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={renewPrice?.inicial?.price}
                                                   onChange={e => handleRenewPriceRange(e, "inicial")}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Negocio</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={renewPrice?.negocio?.from}
                                                       onChange={e => handleRenewPriceRange(e, "negocio")}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={renewPrice?.negocio?.to}
                                                       onChange={e => handleRenewPriceRange(e, "negocio")}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={renewPrice?.negocio?.price}
                                                   onChange={e => handleRenewPriceRange(e, "negocio")}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Volumen</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={renewPrice?.volumen?.from}
                                                       onChange={e => handleRenewPriceRange(e, "volumen")}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={renewPrice?.volumen?.to}
                                                       onChange={e => handleRenewPriceRange(e, "volumen")}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={renewPrice?.volumen?.price}
                                                   onChange={e => handleRenewPriceRange(e, "volumen")}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lista</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2"
                                                    sx={{margin: '10px 0'}}>Cantidad: </Typography>
                                        <div style={{display: "flex", gap: "10px"}}>
                                            <TextField size="small" label="Desde" name="from"
                                                       value={renewPrice?.lista?.from}
                                                       onChange={e => handleRenewPriceRange(e, "lista")}/>
                                            <TextField size="small" label="Hasta" name="to"
                                                       value={renewPrice?.lista?.to}
                                                       onChange={e => handleRenewPriceRange(e, "lista")}/>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField size="small" label="Ingrese Precio" fullWidth
                                                   name="price" value={renewPrice?.lista?.price}
                                                   onChange={e => handleRenewPriceRange(e, "lista")}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        <DatePicker size="small" label="Fecha inicial de precio"
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            name: "startDateR",
                                                            format: "dd/MM/yyyy"
                                                        }
                                                    }}
                                                    sx={{marginTop: '20px', width: "200px"}}
                                                    value={renewPrice?.startDate}
                                                    onChange={(e) => handleDateRenewPrice(e, "startDate")}/>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{width: "140px"}}>
                                            <DatePicker size="small" label="Fecha final de precio"
                                                        slotProps={{
                                                            textField: {
                                                                size: "small",
                                                                name: "endDateR",
                                                                format: "dd/MM/yyyy"
                                                            }
                                                        }}
                                                        sx={{marginTop: '20px', width: "200px"}}
                                                        value={renewPrice?.endDate}
                                                        onChange={(e) => handleDateRenewPrice(e, "endDate")}/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            }
            {RenewPrice && <RenewPrice/>}
        </GenericCard>
        {isLoading ?
            <CircularProgress/> :
            <Button variant="outlined" sx={{marginTop: "40px", marginRight: "5px"}}
                    onClick={() => saveProduct()} disabled={!familyValue}>Guardar Producto</Button>
        }
    </Box>
}

export default FormProduct