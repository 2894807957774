import GenericCard from "../UI/GenericCard";
import { Box, Button, Divider, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from 'react';

const EditUser = ({ user, updateUser, viewUser  }) => {
    const [profile, setProfile] = useState(user.profile);
    const [active, setActive] = useState(user.active);

    const handleProfile= (e) => {
        setProfile(e.target.value);
    };

    const handleActive= (e) => {
        setActive(e.target.value);
    };

    const updateUserHandler = () => {
        if (user.email.trim() === '')
        {
            return;
        }
        if (profile.trim() === '')
        {
            return;
        }
        const userInfo = {
            email: user.email,
            profile: profile,
            active: active
        };
        updateUser(userInfo);
    };

    const closeHandler = () => {
        viewUser(user);
        // cancel();
    }

    return <Box sx={{marginTop: '20px'}}>
        <GenericCard title="Editar Usuario">
            <TextField label="Correo" size="small" fullWidth required type="email" value={user.email} />
            <FormControl fullWidth sx={{marginTop: '20px'}} required>
                <InputLabel id="profile" size="small">Perfil</InputLabel>
                <Select
                    labelId="profile"
                    id="profile-select"
                    value={profile}
                    label="Perfil"
                    onChange={handleProfile}
                    size="small"
                >
                    <MenuItem value={'administrator'}>administrator</MenuItem>
                    <MenuItem value={'operator'}>operator</MenuItem>
                    <MenuItem value={'reader'}>reader</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{marginTop: '20px'}} required>
                <InputLabel id="active" size="small">Activo</InputLabel>
                <Select
                    labelId="active"
                    id="active-select"
                    value={active}
                    label="Activo"
                    onChange={handleActive}
                    size="small"
                    
                >
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </FormControl>
            <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
            <Button variant="outlined" onClick={updateUserHandler}>Actualizar Usuario</Button>
            <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cancelar</Button>
        </GenericCard>
    </Box>
}

export default EditUser;