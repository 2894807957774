import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    DialogActions,
    Grid,
    FormControl, InputLabel, Select, MenuItem,
    TextField,
    Table,
    TableContainer,
    TableHead, TableRow, Paper, TableCell, TableBody
} from "@mui/material";
import {getDateOrder} from './utilsOrders';
import {getProductsByCategory, getSuppliersByProduct} from "../../api/products";
import {useQuery} from "react-query";
import {useSnackbar} from "../Snackbar/useSnackbar";

const ModalOrderNew = (props) => {
    const {
        showOrder, closeModal, totalQuantity, setTotalQuantity, category, setCategory, family, setFamily,
        product, setProduct, supplier, setSupplier, confirmOrder, typeAction, generalFamilies
    } = props;
    const {setSnackbar} = useSnackbar();
    const [monthlyOrder, setMonthlyOrder] = useState([]);
    const [categoryList, setCategoryList] = useState(generalFamilies.filter(item => item.name === family)[0]?.categories || []);

    const {data: dataProductsByCategory, isLoading: isLoadingProductsByCategory} = useQuery(
        ['productsByCategory', category, family], () => getProductsByCategory(family, category), {
            staleTime: 3600000,
            enabled: category !== "" && family !== "",
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
        });
    const {data: dataSuppliersByProduct, isLoading: isLoadingSuppliersByProduct} = useQuery(
        ['suppliersByProduct', product.name + " - " + product.reference], () => getSuppliersByProduct(product), {
            staleTime: 3600000,
            enabled: product !== "",
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
        });

    useEffect(() => {
            const listMonthlyOrder = [];
            const quantity = (totalQuantity / 12).toFixed(2);
            for (let i = 0; i < 12; i++) {
                const {nextDate, monthAndYear} = getDateOrder(i);
                listMonthlyOrder.push({
                    'date': nextDate,
                    'quantity': parseFloat(quantity),
                    'state': 'enabled',
                    'label': monthAndYear
                })
            }
            setMonthlyOrder(listMonthlyOrder);
    }, [])

    useEffect(() => {
        const reduce = monthlyOrder.reduce((acc, current) => parseFloat(acc) + current?.quantity, 0);
        setTotalQuantity(reduce.toFixed(2))
    }, [monthlyOrder])

    const changeMonthlyQuantity = (e, index) => {
        let {value} = e.target;
        if (value !== '') {
            value = parseFloat(value);
        }
        let listTemp = monthlyOrder;
        listTemp[index].quantity = value;
        setMonthlyOrder([...listTemp]);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        confirmOrder(
            {
                monthlyOrder: monthlyOrder,
                product: product,
                creationDate: new Date().toISOString(),
                state: 'open',
                orderOrigin: 'zoho',
                deliveryDate: "",
                priceFinal: 0,
                quantity: parseFloat(totalQuantity)
            }
        );
    }

    const handleSupplier = (e, action) => {
        setSupplier(action?.props?.data);
    };

    const handleFamily = (e) => {
        const familyValue = e.target.value;
        setCategory('');
        setProduct('');
        setCategoryList([]);
        setFamily(familyValue);
        setCategoryList(generalFamilies.filter(item => item.name === familyValue)[0]?.categories || []);
    };

    const handleProduct = (e, action) => {
        setProduct(action?.props?.data);
    };

    const handleCategory = (e) => {
        setCategory(e.target.value);
        setSupplier('');
    };

    return <Dialog open={showOrder} sx={{'& .MuiDialog-paper': {maxWidth: '900px'}}}>
        <DialogTitle>{typeAction === "new" ? "Nuevo" : "Editar"} pedido</DialogTitle>
        <form onSubmit={e => onSubmit(e)}>
            <DialogContent>
                <DialogContentText>
                    Especifique los siguentes datos para confirmar su orden: <br/>
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{marginTop: '20px'}} required>
                            <InputLabel id="family" size="small">Familia</InputLabel>
                            <Select
                                labelId="family"
                                id="family-select"
                                value={family}
                                label="Familia"
                                onChange={handleFamily}
                                size="small"
                            >
                                {generalFamilies.map((family, i) => (
                                    <MenuItem key={'fam'+i} value={family.name}>{family.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{marginTop: '20px'}} required>
                            <InputLabel id="category" size="small">Categoria</InputLabel>
                            <Select
                                labelId="category"
                                id="category-select"
                                value={category}
                                label="Categoria"
                                onChange={handleCategory}
                                size="small"
                            >
                                {categoryList?.map((categoryI, i) => (
                                    <MenuItem key={'category'+i} value={categoryI}>{categoryI}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <DialogContentText>
                            <br/>
                            <b>Cantidad Anual:</b> {totalQuantity} Unidades
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{marginTop: '20px'}} required>
                            <InputLabel id="product" size="small">Producto</InputLabel>
                            <Select
                                labelId="product"
                                id="product-select"
                                value={product.name + " - " + product.reference}
                                label="Producto"
                                onChange={handleProduct}
                                size="small"
                            >
                                {isLoadingProductsByCategory && <MenuItem value="">Cargando productos...</MenuItem>}
                                {dataProductsByCategory?.map((item) => (
                                    <MenuItem key={item.id} data={item} value={item.name + " - " + item.reference}>{item.name + " - " + item.reference}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{marginTop: '20px'}} required>
                            <InputLabel id="supplier" size="small">Proveedor</InputLabel>
                            <Select
                                labelId="supplier"
                                id="supplier-select"
                                value={supplier?.name}
                                label="Proveedor"
                                onChange={handleSupplier}
                                size="small"
                            >
                                {isLoadingSuppliersByProduct && <MenuItem value="">Cargando suppliers...</MenuItem>}
                                {dataSuppliersByProduct?.map((supplier) => (
                                    <MenuItem key={supplier.id} value={supplier.name} data={supplier}>{supplier.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
                <br/>
                <div>
                    <DialogContentText>
                        Meses Tentativos de compra:
                    </DialogContentText>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{monthlyOrder[0]?.label}</TableCell>
                                <TableCell>{monthlyOrder[1]?.label}</TableCell>
                                <TableCell>{monthlyOrder[2]?.label}</TableCell>
                                <TableCell>{monthlyOrder[3]?.label}</TableCell>
                                <TableCell>{monthlyOrder[4]?.label}</TableCell>
                                <TableCell>{monthlyOrder[5]?.label}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[0]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 0)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[1]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 1)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[2]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 2)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[3]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 3)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[4]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 4)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[5]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 5)}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{monthlyOrder[6]?.label}</TableCell>
                                <TableCell>{monthlyOrder[7]?.label}</TableCell>
                                <TableCell>{monthlyOrder[8]?.label}</TableCell>
                                <TableCell>{monthlyOrder[9]?.label}</TableCell>
                                <TableCell>{monthlyOrder[10]?.label}</TableCell>
                                <TableCell>{monthlyOrder[11]?.label}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[6]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 6)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[7]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 7)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[8]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 8)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[9]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 9)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[10]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 10)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField placeholder={0} value={monthlyOrder[11]?.quantity}
                                               onChange={(e) => changeMonthlyQuantity(e, 11)}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeModal}>Cancelar</Button>
                <Button variant='contained' type={"submit"}>Confirmar</Button>
            </DialogActions>
        </form>
    </Dialog>
}

export default ModalOrderNew;
