import {TableRow, TableCell, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

const RowFamily = ({family, familyHandler, mode, index}) => {
    const Icon = mode === "edit" ? EditIcon: VisibilityIcon

    return <TableRow>
        <TableCell>{family.name}</TableCell>
        <TableCell><IconButton onClick={() => familyHandler(family, index)} ><Icon color='primary' /></IconButton></TableCell>
    </TableRow>
}

export default RowFamily