import React, {useState} from 'react';
import {
    TableCell, FormControl, FormControlLabel, FormLabel, Typography,
    RadioGroup, TextField, Radio, Button, TableRow,
} from '@mui/material';
import {getQuarter} from './utilsOrdersV2';
import {TablePrices} from "../Products/TablePrices";
import {DatePicker} from "@mui/x-date-pickers";
import {parseISO} from "date-fns";

const {currentQuarter, nextQuarter, lastQuarter, proxQuarter} = getQuarter();

const RowOrder = (props) => {
    const {order, index, quantity, setIsPrint, updateOrderHandle, setSelectedOrder, setPage, setOrderToPrint, createPendingOrderHandle} = props;
    const {product, orderOrigin} = order;
    const [priceFinal, setPriceFinal] = useState(order?.priceFinal || "");
    const [discount, setDiscount] = useState(order?.discount || 0);
    const [typeDiscount, setTypeDiscount] = useState(order?.typeDiscount || "")
    const [deliveryDate, setDeliveryDate] = useState(order?.deliveryDate ? parseISO(order?.deliveryDate) :  null);
    const [volumenFinal, setVolumenFinal] = useState(order?.volumenFinal)
    const codeFamily = (family) => {
        switch (family) {
            case 'PUMP':
            case 'PUMPS':
                return 'BO';
            case 'VALVES':
                return 'VA';
            case 'HARDWARE':
                return 'FE';
            case 'STEEL':
                return 'FA';
            case 'Plásticos / Plastics':
                return 'PL';
            default:
                return 'MM';
        }
    }

    const updateOrder = () => {
        const orderInfo = {
            priceFinal,
            discount,
            typeDiscount,
            volumenFinal,
            deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : null,
            id: order.id
        }
        updateOrderHandle(orderInfo, order.product)
    }

    const createPendingOrder = () => {
        const currentDate = new Date();
        const getMonth = currentDate.getMonth() + 1;
        const getYear = currentDate.getFullYear();
        const getCodeFamily = codeFamily(order.product.family);
        const numDoc = `#${getYear}.${getMonth}.${getCodeFamily}.00000000`

        const orderInfo = {
            priceFinal,
            discount,
            typeDiscount,
            volumenFinal,
            orderOrigin,
            quantity: volumenFinal,
            deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : null,
            id: order.id,
            invoice: 'PI' + numDoc,
            package: 'PK' + numDoc
        }
        createPendingOrderHandle(orderInfo, order.product, volumenFinal)
    }

    const handleLiquitation = () => {
        setSelectedOrder(order)
        setPage("editLiquitation")
    }

    const getQuarterQuantity = (i) => {
        if (quantity[i]?.currentQuarterQuantity > 0) {
            return {quantity: quantity[i]?.currentQuarterQuantity, index: 3};
        }
        if (quantity[i]?.nextQuarterQuantity > 0) {
            return {quantity: quantity[i]?.nextQuarterQuantity, index: 6};
        }
        if (quantity[i]?.lastQuarterQuantity > 0) {
            return {quantity: quantity[i]?.lastQuarterQuantity, index: 9};
        }
        if (quantity[i]?.proxQuarterQuantity > 0) {
            return {quantity: quantity[i]?.proxQuarterQuantity, index: 12};
        }
        return {quantity: undefined};
    }

    const handlePrintOrder = () => {
        setSelectedOrder(order)
        setOrderToPrint({index, quantity})
        setIsPrint(true)
    }

    const getState = (state) => {
        let message = "Estado: "
        switch (state) {
            case "open":
                return message += "En Negociacion";
            case "pending":
                return message += "Pendiente envio del banco";
            case "sent":
                return message += "Enviado al banco";
            case "paid":
                return message += "Pagado";
            default:
                return message += " --";
        }
    }

    return <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
            <Typography variant="body2" sx={{marginBottom: "20px"}}>{order?.product?.name}</Typography>
            <Typography variant="body2">{order?.user?.company?.name}</Typography>
            <Typography variant="body2">{order?.user?.name}</Typography>
            <Typography variant="caption">Origen: {order?.orderOrigin === "zoho" ? "Zoho" : "MyAlaxs"}</Typography><br/>
            <Typography variant="caption">{getState(order?.state)}</Typography>
        </TableCell>
        <TableCell>
            {getQuarterQuantity(index).quantity ?
                <>
                    Mensual: <br/>
                    {quantity[index]?.currentMonthQuantity !== 0 && quantity[index].currentMonthQuantity}
                    <br/><br/>Trimestral:<br/>
                    {quantity[index]?.currentQuarterQuantity !== 0 &&
                        <>Q{currentQuarter.q + '-' + currentQuarter.year + ': '}<br/>{(quantity[index].currentQuarterQuantity).toFixed(2)}</>
                    }
                    {quantity[index]?.nextQuarterQuantity !== 0 &&
                        <p>Q{nextQuarter.q + '-' + nextQuarter.year + ': '}<br/>{(quantity[index].nextQuarterQuantity).toFixed(2)}
                        </p>
                    }
                    {quantity[index]?.lastQuarterQuantity !== 0 &&
                        <p>Q{lastQuarter.q + '-' + lastQuarter.year + ': '}<br/>{(quantity[index].lastQuarterQuantity).toFixed(2)}
                        </p>
                    }
                    {quantity[index]?.proxQuarterQuantity !== 0 &&
                        <p>Q{proxQuarter.q + '-' + proxQuarter.year + ': '}<br/>{(quantity[index].proxQuarterQuantity).toFixed(2)}
                        </p>
                    }
                </>
                : order?.quantity
            } {order?.product?.unit?.symbol}
        </TableCell>
        <TableCell>
            {typeof order?.currentPrice === "string" ? order?.currentPrice :
                typeof order?.currentPrice === "object" ?
                    <TablePrices data={order?.currentPrice} product={product}/> :
                    product?.priceRanges?.length > 0 && product?.priceRanges?.map((priceRange, i) => (
                        <TablePrices key={"priceRangeV" + i} data={priceRange} product={product}/>
                    ))}
        </TableCell>
        <TableCell>
            <FormControl>
                <FormLabel sx={{fontSize: "14px"}} id="demo-form-control-label-placement">Tipo de descuento:</FormLabel>
                <RadioGroup name="typeDiscount"
                            value={typeDiscount}
                            onChange={(e) => setTypeDiscount(e.target.value)}
                >
                    <FormControlLabel value="currency" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} label="Moneda"/>
                    <FormControlLabel value="percentage" control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 18}}}/>} label="Porcentaje"/>
                </RadioGroup>
            </FormControl>
            <TextField size="small" label="Ingrese Descuento" value={discount} onChange={(e) => setDiscount(e.target.value)}
                       sx={{marginTop: '20px'}} fullWidth name="discount"/>
        </TableCell>
        <TableCell>
            <TextField size="small" label="Volumen Final" value={volumenFinal}
                       onChange={(e) => setVolumenFinal(e.target.value)}
                       sx={{marginTop: '14px'}} fullWidth name="volumenFinal"/>
            <TextField size="small" label="Precio Final" value={priceFinal}
                       onChange={(e) => setPriceFinal(e.target.value)}
                       sx={{marginTop: '14px'}} fullWidth name="priceFinal"
                       InputProps={{
                        endAdornment: <Typography variant="caption">{order?.product?.incoterm ?? "-"}</Typography>,
                       }}
            />
            <DatePicker size="small" label="Delivery Date"
                        slotProps={{
                            textField: {
                                size: "small",
                                name: "deliveryDate",
                                format: "dd/MM/yyyy"
                            }
                        }}
                        value={deliveryDate}
                        onChange={(newValue) => setDeliveryDate(newValue)}
                        sx={{marginTop: '14px', width: "160px"}}

                        />
        </TableCell>
        <TableCell>
            <div style={{display: "flex", flexDirection: "column"}} className="hide-print">
                <Button variant='outlined' size="small" onClick={() => handlePrintOrder()}>Imprimir</Button>
                <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => handleLiquitation()}>Liquidacion</Button>
                <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => updateOrder()}>Guardar</Button>
                { order?.state === "open" &&
                    <Button sx={{marginTop: "10px"}} variant='outlined' size="small" onClick={() => createPendingOrder()}
                            disabled={!volumenFinal || !priceFinal || !deliveryDate}
                    >Facturar</Button>
                }
            </div>
        </TableCell>
    </TableRow>
}

export default RowOrder