import GenericCard from "../UI/GenericCard";
import {Box, Button, Divider, TextField, Tab, Stack, Typography, Switch, Link} from "@mui/material";
import { useState, useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ListUsers from "./ListUsers";
import ListProductsSupplier from "./ListProductsSupplier";
import ListFamilies from "../Families/ListFamilies";
import {getFunctions, httpsCallable} from "firebase/functions";
import firebaseApp from "../../firebase/firebaseApp";

const functions = getFunctions(firebaseApp);

const EditSupplier = ({ supplier, updateSupplier, viewSupplier  }) => {
    const [name, setName] = useState(supplier.name)
    const [url, setUrl] = useState(supplier.url)
    const [email, setEmail] = useState(supplier.email)
    const [hide, setHide] = useState(supplier?.hide)
    const [tabValue, setTabValue] = useState("users")
    const [generalFamilies, setGeneralFamilies] = useState([])

    useEffect(() => {
        const fetchFamilies = () => {
            const listFamilies = httpsCallable(functions, 'listGeneralFamilies');
            listFamilies().then(result => {
                setGeneralFamilies(result.data);
            });
        }
        fetchFamilies();
    }, []);

    const changeTab = (e, value) => {
        setTabValue(value)
    }

    const handleName= (e) => {
        setName(e.target.value);
    };

    const handleUrl= (e) => {
        setUrl(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const updateSupplierHandler = () => {
        if (name.trim() === '' || url.trim() === '' || email.trim() === '')
        {
            return;
        }
        const body = {
            id: supplier.id,
            email,
            name,
            url,
            hide
        }
        updateSupplier(body);
    };

    const closeHandler = () => {
        viewSupplier(supplier);
        // cancel();
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link component={'button'} onClick={closeHandler} sx={{marginBottom: '20px'}}>{'< Regresar'} </Link>
        <GenericCard title="Editar Supplier">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="end" sx={{marginTop: '10px'}}>
                <Typography>Ocultar proveedor</Typography>
                <Typography sx={{color: '#00000061'}}>No</Typography>
                <Switch checked={hide} onChange={(e) => setHide(e.target.checked)} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography sx={{color: '#00000061'}}>Si</Typography>
            </Stack>
            <TextField label="Correo" size="small" fullWidth required type="email" value={email} onChange={handleEmail} sx={{marginTop: '10px'}}/>
            <TextField label="Nombre" size="small" fullWidth required type="text" value={name} onChange={handleName} sx={{marginTop: '20px'}}/>
            <TextField label="Url" size="small" fullWidth required type="url" value={url} onChange={handleUrl} sx={{marginTop: '20px'}}/>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom:1, borderColor: 'divider', marginTop: "20px"}}>
                    <TabList onChange={changeTab}>
                        <Tab value="users" label="Usuarios" />
                        <Tab value="families" label="Familias" />
                        <Tab value="products" label="Productos" />
                    </TabList>
                </Box>
                <TabPanel value="users">
                    <ListUsers supplierId={supplier.id} users={supplier.users} mode="edit"/>
                </TabPanel>
                <TabPanel value="families">
                    <ListFamilies supplierId={supplier.id} families={supplier.families} mode="edit" generalFamilies={generalFamilies}/>
                </TabPanel>
                <TabPanel value="products">
                    <ListProductsSupplier supplierId={supplier.id} mode="edit"/>
                </TabPanel>
            </TabContext>
            <Divider sx={{marginTop: '10px',marginBottom: '20px'}}/>
            <Button variant="outlined" onClick={updateSupplierHandler}>Actualizar Supplier</Button>
            <Button variant="outlined" onClick={closeHandler} sx={{ marginLeft: '5px' }} color="secondary">Cancelar</Button>
        </GenericCard>
    </Box>
}

export default EditSupplier