import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit} from "@mui/icons-material";

const RowUnit = ({ unit, index, deleteUnitHandle, setSelectedUnit, setPage}) => {
    const handleEdit = () => {
        setSelectedUnit(unit);
        setPage('editUnit');
    }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{unit?.symbol}</TableCell>
      <TableCell>{unit?.name}</TableCell>
      <TableCell>
        <div className="flex-center">
            <IconButton onClick={() => handleEdit(unit)}>
                <Edit color="primary"/>
            </IconButton>
              <IconButton onClick={() => deleteUnitHandle(unit)}>
                <Delete color="primary" />
              </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default RowUnit;