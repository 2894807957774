import React from "react";
import FormCommodity from "./FormCommodity";

const NewCommodity = (props) => {
    const { setPage, createCommodityHandle, isLoading } = props;

    return (
        <FormCommodity
            typeForm='new'
            title='Agregar Commodity'
            setPage={setPage}
            onSubmit={createCommodityHandle}
            isLoading={isLoading}
        >
        </FormCommodity>

    )
}

export default NewCommodity