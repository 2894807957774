import firebaseApp from '../firebase/firebaseApp'
import {getFunctions, httpsCallable} from 'firebase/functions'

const functions = getFunctions(firebaseApp)

export const getSuppliers =  async() => {
    const listSuppliers = httpsCallable(functions, 'listSuppliers');
    try{
        const result = await listSuppliers();
        return result.data
    }
    catch(error){
        throw new Error("No se pudo obtener los suppliers")
    }
}