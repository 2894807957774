import firebaseApp from '../../firebase/firebaseApp';
import {getFunctions, httpsCallable} from 'firebase/functions';
import ModalZohoOrder from "./ModalZohoOrder";
import GenericCard from "../UI/GenericCard";
import {
    Box,
    TextField,
    Button,
    Link,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Snackbar,
    CircularProgress
} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
import {useState, useEffect} from "react";
import EditIcon from '@mui/icons-material/Edit';
import {useSnackbar} from "../Snackbar/useSnackbar";

const functions = getFunctions(firebaseApp);
const ViewCompany = (props) => {
    const {setSnackbar} = useSnackbar();
    const {
        company,
        closeViewCompany,
        suppliers,
        generalFamilies,
        createOrderHandle,
        loading,
        newOrders,
        updateOrderHandle
    } = props;
    const {Q_Volume, First_Name, Last_Name, Title} = company;
    const initialValues = {
        firstName: First_Name ?? '',
        lastName: Last_Name ?? '',
        title: Title ?? '',
        dateBirth: company.Date_of_Birth ?? new Date(),
        phone: company.Phone ?? '',
        mobile: company.Mobile ?? '',
        linkedin: company.LinkedInUrl ?? '',
        accountName: company.name ?? '',
        industry: company.Industry ?? '',
        website: company.Website ?? '',
        employees: company.Employees ?? '',
        country: company.Billing_Country ?? '',
        city: company.Billing_City ?? '',
        street: company.Billing_Street ?? '',
        moneda: company.$currency ?? '',
    }

    const [values, setValues] = useState(initialValues);
    const [supplier, setSupplier] = useState('');
    const [family, setFamily] = useState(company?.Industry ?? '');
    const [product, setProduct] = useState('');
    const [category, setCategory] = useState(company?.Categories ?? '');
    const [totalQuantity, setTotalQuantity] = useState(Q_Volume ?? 0);
    const [showOrder, setShowOrder] = useState(false);
    const {
        firstName,
        lastName,
        title,
        dateBirth,
        phone,
        mobile,
        linkedin,
        accountName,
        industry,
        website,
        employees,
        country,
        city,
        street,
        moneda
    } = values;
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [typeAction, setTypeAction] = useState('');

    const fetchUpdateZohoCompany = (body) => {
        setLoadingOrder(true);
        const updateZohoCompany = httpsCallable(functions, 'updateZohoCompany');
        updateZohoCompany(body).then(result => {
            setSnackbar({show: true, message: 'Se ha actualizaron los datos correctamente'});
        }).catch(error => {
            setSnackbar({show: true, message: 'Error al actualizar los datos', severity: 'error'});
        }).then(() => {
            setLoadingOrder(false);
        })
    }

    const getDate = (creationDate) => {
        let date = 'Sin actualizar'
        if (creationDate !== "") {
            date = 'Fecha de orden: ' + new Date(creationDate).toLocaleDateString()
        }
        return date
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const closeHandler = () => {
        closeViewCompany();
    }

    const confirmOrder = (order) => {
        setShowOrder(false);
        if (orderId) {
            order["id"] = orderId;
            updateOrderHandle(order);
        }
        // Si no hay orderId, es un nuevo pedido
        else {
            order["user"] = {
                code: company.id,
                name: accountName,
            };
            createOrderHandle(order)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            companyId: company.id,
            companyInfo: {
                First_Name: firstName,
                Last_Name: lastName,
                Title: title,
                Date_of_Birth: dateBirth,
                Phone: phone,
                Mobile: mobile,
                LinkedInUrl: linkedin,
                name: accountName,
                Industry: industry,
                Website: website,
                Employees: employees,
                Billing_Country: country,
                Billing_City: city,
                Billing_Street: street,
                $currency: moneda,
                updateInfo: new Date()
            }
        }
        fetchUpdateZohoCompany(body);
    }

    const handleEditOrder = (order) => {
        setSupplier(order.product?.supplier);
        setFamily(order.product?.family);
        setProduct(order.product);
        setCategory(order.product?.category);
        setTotalQuantity(order.quantity);
        setOrderId(order.id);
        setShowOrder(true);
        setTypeAction('edit');
    }

    return <Box sx={{marginTop: '20px'}}>
        <Link component={'button'} onClick={closeHandler} sx={{marginBottom: '20px'}}>{'< Regresar'} </Link>
        {showOrder &&
            <ModalZohoOrder showOrder={showOrder}
                            closeModal={() => setShowOrder(false)}
                            setCategory={setCategory}
                            category={category}
                            family={family}
                            typeAction={typeAction}
                            setFamily={setFamily}
                            product={product}
                            setProduct={setProduct}
                            supplier={supplier}
                            setSupplier={setSupplier}
                            confirmOrder={confirmOrder}
                            totalQuantity={totalQuantity}
                            setTotalQuantity={setTotalQuantity}
                            suppliers={suppliers}
                            generalFamilies={generalFamilies}
            />
        }
        <form onSubmit={onSubmit}>
            <GenericCard title="Datos del Usuario">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Correo" size="small" fullWidth type="email" value={company.email} disabled
                                   InputLabelProps={{shrink: true,}}
                                   sx={{marginTop: '10px'}}/>
                        <TextField name='firstName' onChange={handleInputChange} label="First Name" size="small"
                                   InputLabelProps={{shrink: true,}}
                                   fullWidth type="text" value={values.firstName} sx={{marginTop: '20px'}}/>
                        <TextField name='lastName' onChange={handleInputChange} label="Last Name" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.lastName} sx={{marginTop: '20px'}}/>
                        <TextField name='title' onChange={handleInputChange} label="Title" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.title} sx={{marginTop: '20px'}}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name='dateBirth' onChange={handleInputChange} label="Date Birth" size="small"
                                   InputLabelProps={{shrink: true,}}
                                   fullWidth type="date" value={values.dateBirth} sx={{marginTop: '20px'}}/>
                        <TextField name='phone' onChange={handleInputChange} label="Phone" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.phone} sx={{marginTop: '20px'}}/>
                        <TextField name='mobile' onChange={handleInputChange} label="Mobile" size="small"
                                   fullWidth InputLabelProps={{shrink: true,}} maxLenght={12}
                                   type="text" value={values.mobile} sx={{marginTop: '20px'}}/>
                        <TextField name='linkedin' onChange={handleInputChange} label="Linkedin" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.linkedin} sx={{marginTop: '20px'}}/>
                    </Grid>
                </Grid>
            </GenericCard>
            <GenericCard title="Datos de la compañia">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField name='accountName' onChange={handleInputChange} label="Account Name" size="small"
                                   InputLabelProps={{shrink: true,}}
                                   fullWidth type="text" value={values.accountName}
                                   sx={{marginTop: '10px'}}/>
                        <TextField name='industry' onChange={handleInputChange} label="Industry" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.industry} sx={{marginTop: '20px'}}/>
                        <TextField name='website' onChange={handleInputChange} label="Website" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.website} sx={{marginTop: '20px'}}/>
                        <TextField name='employees' onChange={handleInputChange} label="Employees" size="small"
                                   InputLabelProps={{shrink: true,}}
                                   fullWidth type="text" value={values.employees} sx={{marginTop: '20px'}}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name='country' onChange={handleInputChange} label="Country" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.country} sx={{marginTop: '20px'}}/>
                        <TextField name='city' onChange={handleInputChange} label="City" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.city} sx={{marginTop: '20px'}}/>
                        <TextField name='street' onChange={handleInputChange} label="Street" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.street} sx={{marginTop: '20px'}}/>
                        <TextField name='moneda' onChange={handleInputChange} label="Moneda" size="small" fullWidth
                                   InputLabelProps={{shrink: true,}}
                                   type="text" value={values.moneda} sx={{marginTop: '20px'}}/>
                    </Grid>
                </Grid>
            </GenericCard>
            <GenericCard title="Pedido">
                <Grid container spacing={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Nro.</TableHeaderCell>
                                <TableHeaderCell>Proveedor</TableHeaderCell>
                                <TableHeaderCell>Familia</TableHeaderCell>
                                <TableHeaderCell>Categoria</TableHeaderCell>
                                <TableHeaderCell>Producto</TableHeaderCell>
                                <TableHeaderCell>Cantidad</TableHeaderCell>
                                <TableHeaderCell>Estado</TableHeaderCell>
                                <TableHeaderCell>Editar</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(loadingOrder || loading) ?
                                <tr>
                                    <td colSpan={9}>
                                        <div className="flex-center gap-12 m-12"><CircularProgress/>Cargando
                                            información...
                                        </div>
                                    </td>
                                </tr>
                                :
                                newOrders?.length === 0 &&
                                <TableRow>
                                    <TableCell>01</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{company?.Industry ?? ""}</TableCell>
                                    <TableCell>{company?.Categories ?? ""}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{Q_Volume}</TableCell>
                                    <TableCell>Pedido sin realizar</TableCell>
                                    <TableCell>
                                    <span style={{display: 'inline-block', marginRight: '10px'}}>
                                        <IconButton sx={{display: 'inline-block'}} onClick={() => {
                                            setShowOrder(true);
                                            setTypeAction("edit")
                                        }}><EditIcon color={'primary'}/></IconButton>
                                    </span>
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                (newOrders?.length > 0) && newOrders?.map((order, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{order.product?.supplier?.name}</TableCell>
                                        <TableCell>{order.product?.family}</TableCell>
                                        <TableCell>{order.product?.category}</TableCell>
                                        <TableCell>{order.product?.name + " - " + order.product?.reference}</TableCell>
                                        <TableCell>{order.quantity}</TableCell>
                                        <TableCell><p style={{fontSize: '11px'}}>{getDate(order.creationDate)}</p>
                                        </TableCell>
                                        <span style={{display: 'inline-block', marginRight: '11px'}}>
                                            <IconButton sx={{display: 'inline-block'}}
                                                        onClick={() => handleEditOrder(order)}><EditIcon
                                                color={'primary'}/></IconButton>
                                        </span>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </GenericCard>
            <Button variant="outlined" type='submit' sx={{marginTop: '20px'}} disabled={loading}>Guardar</Button>
            <Button variant="outlined" onClick={closeHandler} color="secondary"
                    sx={{marginTop: '20px', marginLeft: '20px'}}>Cerrar</Button>
        </form>
    </Box>
}

export default ViewCompany;