import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {getFamilyCommodities, createFamilyCommodity, updateFamilyCommodity, deleteFamilyCommodity} from "../api/familyComodities";
import ListFamilyCommodities from "../components/FamilyCommodities/ListFamilyCommodities";
import NewFamilyCommodity from "../components/FamilyCommodities/NewFamilyCommodity";
import EditFamilyCommodity from "../components/FamilyCommodities/EditFamilyCommodity";

const FamilyCommodities = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [familyCommodities, setFamilyCommodities] = useState([])
    const [page, setPage] = useState('listFamilyCommodities');
    const [selectedFamilyCommodity, setSelectedFamilyCommodity] = useState(null);
    const {data: dataFamilyCommodities, isLoading: isLoadingFamilyCommodities} = useQuery('familyCommodities', getFamilyCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: createFamilyCommodityMutation, isLoading: isLoadingCreateFamilyCommodity} = useMutation(createFamilyCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se guardó correctamente', severity: 'success'})
            setFamilyCommodities([...familyCommodities, data])
            queryClient.invalidateQueries('familyCommodities')
            setPage('listFamilyCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: updateFamilyCommodityMutation, isLoading: isLoadingUpdateFamilyCommodity} = useMutation(updateFamilyCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyFamilyCommodities = [...familyCommodities]
            const index = copyFamilyCommodities.findIndex(p => p.id === data.id)
            copyFamilyCommodities[index] = data
            setFamilyCommodities(copyFamilyCommodities)
            queryClient.invalidateQueries('familyCommodities')
            setPage('listFamilyCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {mutate: deleteFamilyCommodityMutation, isLoading: isLoadingDeleteFamilyCommodity} = useMutation(deleteFamilyCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se eliminó correctamente', severity: 'success'})
            let copyFamilyCommodities = [...familyCommodities]
            const index = copyFamilyCommodities.findIndex(p => p.id === data.id)
            copyFamilyCommodities.splice(index, 1)
            setFamilyCommodities(copyFamilyCommodities)
            queryClient.invalidateQueries('familyCommodities')
            setPage('listFamilyCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if(dataFamilyCommodities){
            setFamilyCommodities(dataFamilyCommodities)
        }
    }, [dataFamilyCommodities]);

    const createFamilyCommodityHandle = (familyCommodity) => {
        const answer = window.confirm(`¿Está seguro de agregar familyCommodity "${familyCommodity.name}"?`);
        if (!answer) {return}
        createFamilyCommodityMutation(familyCommodity)
    }

    const updateFamilyCommodityHandle = (familyCommodity) => {
        const answer = window.confirm(`¿Está seguro de actualizar familyCommodity "${familyCommodity.name}"?`);
        if (!answer) {return}
        updateFamilyCommodityMutation(familyCommodity)
    }

    const deleteFamilyCommodityHandle = (familyCommodity) => {
        const answer = window.confirm(`¿Está seguro de eliminar familyCommodity "${familyCommodity.name}"?`);
        if (!answer) {return}
        deleteFamilyCommodityMutation(familyCommodity)
    }

    return (
        <>
            {page === 'listFamilyCommodities' &&
                <ListFamilyCommodities familyCommodities={dataFamilyCommodities} isLoading={isLoadingFamilyCommodities || isLoadingDeleteFamilyCommodity} setPage={setPage} setSelectedFamilyCommodity={setSelectedFamilyCommodity} deleteFamilyCommodityHandle={deleteFamilyCommodityHandle} />
            }
            {page === 'newFamilyCommodity' &&
                <NewFamilyCommodity setPage={setPage} createFamilyCommodityHandle={createFamilyCommodityHandle} isLoading={isLoadingCreateFamilyCommodity}/>
            }
            {page === 'editFamilyCommodity' &&
                <EditFamilyCommodity familyCommodity={selectedFamilyCommodity} setPage={setPage} updateFamilyCommodityHandle={updateFamilyCommodityHandle} isLoading={isLoadingUpdateFamilyCommodity}/>
            }
        </>
    )
}

export default FamilyCommodities;