import { useState, Fragment } from "react"
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableFooter,
    Box,
    TableBody,
    Button,
    Alert, Snackbar
} from '@mui/material';
import TableHeaderCell from '../UI/TableHeaderCell';
import RowFamily from "./RowFamily";
import ViewFamily from "./ViewFamily";
import EditFamily from "./EditFamily";
import NewFamily from "./NewFamily";
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from "../../firebase/firebaseApp";

const functions = getFunctions(firebaseApp);
const ListFamilies = (props) => {
    const {families, mode, supplierId, generalFamilies} = props
    const [openedDialog, setOpenedDialog] = useState(false)
    const [openedNewDialog, setOpenedNewDialog] = useState(false)
    const [familyValue, setFamilyValue] = useState("")
    const [selectedFamily, setSelectedFamily] = useState("")
    const [categories, setCategories] = useState([])
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});
    const [newFamilies, setNewFamilies] = useState(families)
    const [loading, setLoading] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState("")
    const [categoryList, setCategoryList] = useState([]);
    const [newFamily, setNewFamily] = useState(false);

    const editFamilyHandler = (selectedFamily, i) => {
        setSelectedIndex(i)
        setOpenedDialog(true)
        setFamilyValue(selectedFamily.name)
        setSelectedFamily(selectedFamily.name)
        setCategories(selectedFamily.categories)
    }

    const updateFamilyHandler =  () => {
        setLoading(true)
        const request = {
            id: supplierId,
            newName: familyValue,
            oldName: selectedFamily,
            categories: categories
        }
        const updateFamily = httpsCallable(functions, 'updateFamilyV2');
        updateFamily(request).then(response => {
            if (response.data.status === false) {
                setOpenedSnack({show: true, message: response.data.message, severity: 'error'})
            } else {
                setOpenedSnack({show: true, message: 'Se actualizó correctamente', severity: 'success'})
                setOpenedDialog(false)
                const copyFamilies = [...newFamilies]
                copyFamilies[selectedIndex] = {name: familyValue, categories: categories}
                setNewFamilies(copyFamilies)
                setOpenedDialog(false)
            }
            setLoading(false)
        });
    }

    const saveFamilyHandler =  () => {
        setLoading(true)
        const request = {
            id: supplierId,
            name: familyValue,
            categories: categories.filter(item => item !== "")
        }

        const createFamily = httpsCallable(functions, 'createFamily');

        createFamily(request).then(response => {
            if (response.data.status === false) {
                setOpenedSnack({show: true, message: response.data.message, severity: 'error'})
            } else {
                setOpenedSnack({show: true, message:'Se agregó correctamente', severity: 'success'})
                const copyFamilies = [...newFamilies]
                copyFamilies.push({name: familyValue, categories: categories})
                setNewFamilies(copyFamilies)
                setOpenedNewDialog(false)
            }
            setLoading(false)
        });
    }

    const deleteFamilyHandler =  () => {
        setLoading(true)
        const request = {
            id: supplierId,
            name: familyValue,
        }
        const answer = window.confirm(`¿Está seguro de eliminar la famila ${familyValue}?`);
        if (!answer) {
            setLoading(false)
            return
        }

        const deleteFamily = httpsCallable(functions, 'deleteFamily');
        deleteFamily(request).then(response => {
            if (response.data.status === false) {
                setOpenedSnack({show: true, message: response.data.message, severity: 'error'})
            } else {
                setOpenedSnack({show: true, message:'Se eliminó correctamente', severity: 'success'})
                const copyFamilies = [...newFamilies]
                const index = copyFamilies.findIndex(family => family.name === familyValue)
                copyFamilies.splice(index, 1)
                setNewFamilies(copyFamilies)
                setOpenedDialog(false)
            }
            setLoading(false)
        });
    }

    const modalNewFamily = () => {
        setNewFamily(true)
        setCategories([])
        setFamilyValue("")
        setOpenedNewDialog(true)
    }

    const addCategories = value => {
        setCategories([...categories, ""])
    }

    const handleChangeCategory = (e, action) => {
        const newCategories = [...categories]
        newCategories[action] = e.target.value;
        setCategories(newCategories)
    }

    const deleteCategoryHandler = index => {
        const newCategories = [...categories]
        newCategories.splice(index, 1)
        setCategories(newCategories)
    }

    const handleFamily = (e, action) => {
        if(familyValue !== '') {
            setCategories([])
        }
        setFamilyValue(e.target.value);
        setCategoryList(action?.props?.data?.categories || []);
    };


    const handleCloseNewFamily = () => {
        setFamilyValue('')
        setCategoryList([])
        setLoading(false)
        setOpenedNewDialog(false)
    }

    const modalFamily= {
        "view": <ViewFamily categories={categories} openedDialog={openedDialog} setOpenedDialog={setOpenedDialog} familyValue={familyValue} handleChangeCategory={handleChangeCategory} deleteCategoryHandler={deleteCategoryHandler} mode={mode} />,
        "edit": <EditFamily generalFamilies={generalFamilies} loading={loading} addCategories={addCategories} categories={categories} openedDialog={openedDialog} setOpenedDialog={setOpenedDialog} familyValue={familyValue} handleChangeCategory={handleChangeCategory} deleteCategoryHandler={deleteCategoryHandler} mode={mode} updateFamilyHandler={updateFamilyHandler} setFamilyValue={setFamilyValue} deleteFamilyHandler={deleteFamilyHandler}/>,
        "new": <NewFamily handleFamily={handleFamily} handleCloseNewFamily={handleCloseNewFamily} categoryList={categoryList} generalFamilies={generalFamilies} loading={loading} addCategories={addCategories} categories={categories} openedDialog={openedNewDialog} setOpenedDialog={setOpenedNewDialog} familyValue={familyValue} handleChangeCategory={handleChangeCategory} deleteCategoryHandler={deleteCategoryHandler} saveFamilyHandler={saveFamilyHandler} />
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenedSnack(false);
    }

    return <Fragment>
        <Snackbar open={openedSnack.show} autoHideDuration={3000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                {openedSnack.message}
            </Alert>
        </Snackbar>
        <TableContainer component={Box}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Nombre</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newFamilies?.map((family, i) => <RowFamily family={family} key={family+i} familyHandler={editFamilyHandler} mode={mode} index={i}/>)}
                </TableBody>
                <TableFooter>

                </TableFooter>
            </Table>
        </TableContainer>
        {mode === "view" && modalFamily["view"]}
        {mode === "edit" && modalFamily["edit"]}
        {newFamily && modalFamily["new"]}
        {(mode === "edit") && <Button variant="outlined" sx={{marginTop: "20px"}} onClick={() => modalNewFamily()}>Agregar Familia</Button>}
    </Fragment>
}

export default ListFamilies