import React, {useState, useEffect, useRef} from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tab,
    Tabs, Button
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import OpenOrders from "../components/Orders/OpenOrders";
import PaymentOrders from "../components/Orders/PaymentOrders";
import EditLiquitation from "../components/Orders/EditLiquitation";
import {useQuery, useMutation, useQueryClient} from "react-query";
import {getOrders, updateOrder} from "../api/orders";
import {getQuantity} from "../components/Orders/utilsOrdersV2";
import {useSnackbar} from "../components/Snackbar/useSnackbar";
import {useReactToPrint} from "react-to-print";
import {ComponentPrint} from "../components/Orders/ComponentPrint";
import firebaseApp from "../firebase/firebaseApp";

const functions = getFunctions(firebaseApp);

const Orders = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [tabIndex, setTabIndex] = useState("negotiation");
    const [orders, setOrders] = useState([]);
    const [filters, setFilters] = useState([
        { field: "state", operator: "==", value: "open" }
    ])
    const {data: dataOrders, isLoading: isLoadingOrders, refetch} = useQuery(['listOrders', filters], () => getOrders(filters), {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        },
        onSuccess: (data) => {
            if(data.orders?.length > 0){
                let dataQuantity = [];
                data.orders?.forEach(order => {
                    if(order?.monthlyOrder){
                        const {currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, proxQuarterQuantity, totalQuantity, newMonthyOrden, isQuarterDisabled, currentMonthQuantity} = getQuantity(order.monthlyOrder);
                        dataQuantity.push({currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, proxQuarterQuantity, totalQuantity, newMonthyOrden, isQuarterDisabled, currentMonthQuantity})
                    }
                })
                setOrders(data)
                setQuantity(dataQuantity)
            }
        }
    });
    const {mutate: updateOrderMutation, isLoading: isLoadingUpdateOrder} = useMutation(updateOrder, {
            onSuccess: (data) => {
                setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
                let copyProducts = [...products]
                const index = copyProducts.findIndex(p => p.id === data.id)
                copyProducts[index] = data
                setProducts(copyProducts)
                queryClient.invalidateQueries('productsBySupplier')
                setPage('listOrders')
            },
            onError: (error) => {
                setSnackbar({show: true, message: error?.message, severity: 'error'})
            }
    });
    const createPendingFunction = httpsCallable(functions, 'createPendingOrder');
    const {mutate: createPendingOrderMutation, isLoading: isLoadingCreatePendingOrder} = useMutation(createPendingFunction, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se creó correctamente', severity: 'success'})
            queryClient.invalidateQueries(['listOrders', filters])
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const [page, setPage] = useState("listOrders");
    const [products, setProducts] = useState([])
    const [supplier, setSupplier] = useState("");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [quantity, setQuantity] = useState([]);
    const contentToPrint = useRef(null);
    const [isPrint, setIsPrint] = useState(false);
    const [orderToPrint, setOrderToPrint] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => {
            if(dataOrders){
                setOrders(dataOrders)
                setIsLoading(false)
            }
        }, [dataOrders]
    )

    const handleSupplier = (e) => {
        setSupplier(e.target.value);
    };

    const updateOrderHandle = (order, product) => {
        const answer = window.confirm(`¿Está seguro de actualizar el pedido "${product?.name}"?`);
        if (!answer) {return}
        updateOrderMutation(order)
    }

    const createPendingOrderHandle = (order, product, volumenFinal) => {
        const answer = window.confirm(`¿Está seguro de facturar el pedido "${product?.name} con volumen final de ${volumenFinal}"?`);
        if (!answer) {return}
        createPendingOrderMutation(order)
    }

    const handleSearch = () => {
        const operator = tabIndex === "negotiation" ? "==" : "!="

        if(supplier){
            const newFilters = [
                { field: "state", operator, value: "open" },
                { field: "orderOrigin", operator: "==", value: supplier }
            ]
            setFilters(newFilters)
            refetch()
        }
    }

    const changeTab = (tab) => {
        setTabIndex(tab)
        setIsLoading(true)
        const operator = tab === "negotiation" ? "==" : "!="
        const page = tab === "negotiation" ? "listOrders" : "paymentOrders"
        setPage(page)
        const newFilters = [
            { field: "state", operator, value: "open" },
        ]
        setFilters(newFilters)
        setSupplier("")
        refetch()
    }

    const SelectClient = () => (
        <>
            <FormControl fullWidth required sx={{marginRight: "30px", width: "300px"}}>
                <InputLabel id="supplier-select-label" size="small">Cliente</InputLabel>
                <Select
                    labelId="supplier-select-label"
                    id="Supplier-select"
                    value={supplier}
                    label="Cliente"
                    onChange={handleSupplier}
                    size="small"
                >
                    <MenuItem value="myalaxs">My Alaxs</MenuItem>
                    <MenuItem value="zoho">Zoho</MenuItem>
                </Select>
            </FormControl>
            <Button sx={{marginBottom: "30px"}} variant='outlined' onClick={() =>handleSearch()}>Buscar</Button>
        </>
    )

    const backPage = () => {
        setPage(tabIndex === "negotiation" ? "listOrders" : "paymentOrders")
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
          @page { 
            size: landscape
          }
          .hide-print{
            display: none !important;
          }
          .show-print.show{
            display: block !important;
          }
        }`,
        content: () => contentToPrint.current,
        onAfterPrint: () => setIsPrint(false)
    });

    useEffect(() => {
        if(isPrint){
            handlePrint()
        }
    } ,[isPrint])

    return <>
        <Tabs value={tabIndex} onChange={(event, newValue) => changeTab(newValue)}>
            <Tab label="En negociación" value="negotiation"/>
            <Tab label="En facturación" value="payment"/>
        </Tabs>
        {tabIndex === "negotiation" &&
            <>
                {page === "listOrders" &&
                    <OpenOrders data={orders}
                                isLoading={isLoadingOrders || isLoadingCreatePendingOrder || isLoading}
                                SelectClient={SelectClient}
                                quantity={quantity}
                                updateOrderHandle={updateOrderHandle}
                                createPendingOrderHandle={createPendingOrderHandle}
                                setSelectedOrder={setSelectedOrder}
                                setPage={setPage}
                                order={selectedOrder}
                                handlePrint={handlePrint}
                                setIsPrint={setIsPrint}
                                setOrderToPrint={setOrderToPrint}
                    />
                }
                {page === "editLiquitation" &&
                    <EditLiquitation
                        order={selectedOrder}
                        onSubmit={updateOrderHandle}
                        isLoading={isLoadingUpdateOrder}
                        backPage={backPage}
                    />
                }
            </>

        }
        {tabIndex === "payment" &&
            <>
                {page === "paymentOrders" &&
                    <PaymentOrders data={dataOrders}
                                   isLoading={isLoadingOrders}
                                   SelectClient={SelectClient}
                                   quantity={quantity}
                                   updateOrderHandle={updateOrderHandle}
                                   setSelectedOrder={setSelectedOrder}
                                   setPage={setPage}
                                   order={selectedOrder}
                                   handlePrint={handlePrint}
                                   setIsPrint={setIsPrint}
                                   setOrderToPrint={setOrderToPrint}
                    />
                }
                {page === "editLiquitation" &&
                    <EditLiquitation
                        order={selectedOrder}
                        onSubmit={updateOrderHandle}
                        isLoading={isLoadingUpdateOrder}
                        backPage={backPage}
                    />
                }
            </>
        }

        {isPrint && <ComponentPrint ref={contentToPrint} order={selectedOrder} orderToPrint={orderToPrint}/>}
    </>
}

export default Orders;