import { Button, Card, CardContent, CardMedia, CardActions, Typography } from "@mui/material";
import laptopImage from '../../img/laptop.png';
import { useNavigate, Navigate } from "react-router-dom";
import { getAuth, sendEmailVerification } from 'firebase/auth';
import firebaseApp from "../../firebase/firebaseApp";
import { useSelector } from 'react-redux';
import { Fragment } from 'react';

const EmailNotVerified = () => {
    const navigate = useNavigate();
    const emailVerified = useSelector(state => state.user.emailVerified);
    const auth = getAuth(firebaseApp);

    const loginHandler = () => {
        navigate('/login', {replace: true});
    }

    const resendLinkHandler = () => {
        
        if (auth.currentUser)
        {
            sendEmailVerification(auth.currentUser, {
                url: process.env.REACT_APP_EMAIL_URL_CALLBACK
            });
        }
        else {
            console.log('El usuario no se encuentra.');
        }
    }
    return <Fragment>
        {!auth.currentUser && <Navigate to="/login"/>}
        {emailVerified && <Navigate to="/" />}
        <Card sx={{ maxWidth: 345, marginLeft: "auto", marginRight: "auto", marginTop: "50px" }}>
            <CardMedia component="img" height="140" image={laptopImage} alt="email" />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Email no verificado
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Usted no ha verificado su usuario a través del email que se le ha sido enviado.
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={resendLinkHandler}>Reenviar Link</Button>
                <Button size="small" onClick={loginHandler}>Login</Button>
            </CardActions>
        </Card>
    </Fragment>;
}

export default EmailNotVerified;