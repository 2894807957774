import firebaseApp from '../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'
const functions = getFunctions(firebaseApp)

export const getGeneralFamilies =  async() => {
    const listGeneralFamilies = httpsCallable(functions, 'listGeneralFamilies');
    try{
        const result = await listGeneralFamilies();
        return result.data
    }
    catch(error){
        throw new Error("No se pudo obtener las familias")
    }
}