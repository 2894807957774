import FormCommodity from "./FormCommodity";

const EditCommodity = (props) => {
    const {commodity, setPage, updateCommodityHandle, isLoading } = props;

    return (
        <FormCommodity
            typeForm='edit'
            title='Editar Commodity'
            setPage={setPage}
            onSubmit={updateCommodityHandle}
            isLoading={isLoading}
            commodity={commodity}
        />
    )
}

export default EditCommodity