import GenericCard from "../UI/GenericCard"
import React, { useState } from "react"
import {
    Box,
    Grid,
    Button, Snackbar, Alert, TextField, IconButton, Link, Typography
} from "@mui/material"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import firebaseApp from '../../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'
import CircularProgress from "@mui/material/CircularProgress";
import {
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import { storage } from "../../firebase/firebaseApp";
import { v4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import BallotIcon from '@mui/icons-material/Ballot';
import CardHeader from "@mui/material/CardHeader";

const functions = getFunctions(firebaseApp)

const NewGeneralFamily = ({generalFamilies, setGeneralFamilies, cancel}) => {
    const [openedSnack, setOpenedSnack] = useState({show: false, message: '', severity: 'success'});
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState("");
    const [familyName, setFamilyName] = useState("")
    const [tagFamily, setTagFamily] = useState("")

    const handleImagesChange = (e) => {
        setImage({file: e.target.files[0], name: e.target.files[0].name, url: [URL.createObjectURL(e.target.files[0])]})
    }

    const deleteImage = (imageIndex) => {
        setImage("")
    }

    const uploadFiles = async (image) => {
        let fileName = "";
        if (image == null) return fileName

        fileName = v4() + image.name;
        const fileRef = ref(storage, `${fileName}`);
        try {
            uploadBytes(fileRef, image.file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {});
            });
        } catch (error) {
            console.error('Error al subir el archivo:', error);
        }

        return fileName;
    };

    const saveFamily = () => {
        setLoading(true)

        uploadFiles(image).then((imagesUrl) => {
            const request = {
                name: familyName,
                tagFamily,
                image: imagesUrl,
            }
            const createGeneralFamily = httpsCallable(functions, 'createGeneralFamily');
            createGeneralFamily(request).then(response => {
                if (response.data.status === false) {
                    setOpenedSnack({
                        show: true,
                        message: "Hubo un error al guardar, intente nuevamente.",
                        severity: 'error'
                    })
                } else {
                    let copyGeneralFamilies = [...generalFamilies]
                    copyGeneralFamilies.push({...request, id: response.data})
                    setGeneralFamilies(copyGeneralFamilies)
                    setOpenedSnack({show: true, message: 'Se guardó correctamente', severity: 'success'})
                    cancel()
                }
                setLoading(false)
            });
        })
    }

    const closeSnackHandler = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenedSnack(false);
    }

    const closeHandler = () => {
        cancel()
    }


    return <Box sx={{marginTop: '20px'}}>
        <Link component={'button'} onClick={closeHandler} sx={{marginBottom: '20px'}}>{'< Regresar'} </Link>
        <GenericCard title="Agregar familia">
            <Snackbar open={openedSnack.show} autoHideDuration={8000} onClose={closeSnackHandler} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackHandler} severity={openedSnack.severity} sx={{ width: '100%' }} variant="filled">
                    {openedSnack.message}
                </Alert>
            </Snackbar>
            <Box sx={{marginTop: "5px"}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Nombre de la familia"
                            />
                            <CardContent>
                                <TextField size="small" label="Ingrese la nombre" fullWidth value={familyName} sx={{ marginTop: '10px'}} onChange={(e) => setFamilyName(e.target.value)} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="TagFamily"
                            />
                            <CardContent>
                                <TextField size="small" label="Ingrese tagFamily" fullWidth value={tagFamily} sx={{ marginTop: '10px'}} onChange={(e) => setTagFamily(e.target.value)} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                sx={{backgroundColor: "#eceff1"}}
                                avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                title="Agregar imagen"
                            />
                            <CardContent>
                                <Button  sx={{marginBottom: "10px"}}
                                         variant="contained"
                                         component="label"
                                >
                                    Seleccionar imagen
                                    <input
                                        type="file"
                                        onChange={handleImagesChange}
                                        hidden
                                    />
                                </Button>
                                {image &&
                                    <div className="card-image-text-icon">
                                        <img src={image.url} />
                                        <Typography variant="caption">{image.name}</Typography>
                                        <IconButton className="delete" aria-label="settings" onClick={() => deleteImage()}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {loading ?
                    <CircularProgress />:
                    <Button variant="outlined"  sx={{marginTop: "40px", marginRight: "5px"}} onClick={() => saveFamily()} disabled={!familyName}>Guardar Familia</Button>
                }
            </Box>
        </GenericCard>
    </Box>
}

export default NewGeneralFamily