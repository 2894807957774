import RowFamilyCommodity from "./RowFamilyCommodity";
import { Box, Button, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from "@mui/material";
import GenericCard from "../UI/GenericCard";

const ListFamilyCommodities = (props) => {
  const { familyCommodities, isLoading, setPage, setSelectedFamilyCommodity, deleteFamilyCommodityHandle } = props;

  return (
      <GenericCard className='mt-12' title='Lista de Familias Commodities'>
          <Box>
              <Button className sx={{marginBottom: "30px"}} variant='outlined' onClick={() => setPage('newFamilyCommodity')}>Agregar Familia Commodity</Button>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {!isLoading && familyCommodities?.map((familyCommodity, index) => <RowFamilyCommodity familyCommodity={familyCommodity} index={index} key={familyCommodity.id} setSelectedFamilyCommodity={setSelectedFamilyCommodity} setPage={setPage} deleteFamilyCommodityHandle={deleteFamilyCommodityHandle} />)}
                  </TableBody>
              </Table>
          </Box>
          {isLoading && <div className="m-12"><CircularProgress/>Cargando información...</div>}
      </GenericCard>
  )
}

export default ListFamilyCommodities;