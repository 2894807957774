import FormIncoterm from "./FormIncoterm";

const EditIncoterm = (props) => {
    const {incoterm, setPage, updateIncotermHandle, isLoading } = props;

    return (
        <FormIncoterm
            title='Editar Condición de Venta'
            setPage={setPage}
            onSubmit={updateIncotermHandle}
            isLoading={isLoading}
            incoterm={incoterm}
        />
    )
}

export default EditIncoterm