import FormProduct from "./FormProduct";

const EditProduct = (props) => {
    const {product, suppliers, setSnackbar, isLoading, updateProductHandle, RenewPrice, priceRanges, setPriceRanges, renewPrice, setRenewPrice, backPage} = props

    return <FormProduct
            title="Editar Producto"
            onSubmit={updateProductHandle}
            isLoading={isLoading}
            product={product}
            suppliers={suppliers}
            setSnackbar={setSnackbar}
            RenewPrice={RenewPrice}
            priceRanges={priceRanges}
            setPriceRanges={setPriceRanges}
            renewPrice={renewPrice}
            setRenewPrice={setRenewPrice}
            backPage={backPage}
       />

}

export default EditProduct