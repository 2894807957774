import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit} from "@mui/icons-material";

const RowCurrency = ({ currency, index, deleteCurrencyHandle, setSelectedCurrency, setPage}) => {
    const handleEdit = () => {
        setSelectedCurrency(currency);
        setPage('editCurrency');
    }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{currency?.symbol}</TableCell>
      <TableCell>{currency?.code}</TableCell>
      <TableCell>{currency?.name}</TableCell>
      <TableCell>
        <div className="flex-center">
            <IconButton onClick={() => handleEdit(currency)}>
                <Edit color="primary"/>
            </IconButton>
              <IconButton onClick={() => deleteCurrencyHandle(currency)}>
                <Delete color="primary" />
              </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default RowCurrency;