import {TableRow, TableCell,} from '@mui/material';

const RowProductSupplier = (props) => {
    const {product, index} = props;

    return <TableRow>
        <TableCell>{index+1}</TableCell>
        <TableCell>{product?.name}</TableCell>
        <TableCell>{product?.supplier?.name}</TableCell>
        <TableCell>{product?.family}</TableCell>
        <TableCell>{product?.category}</TableCell>
    </TableRow>
}

export default RowProductSupplier