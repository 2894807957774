import FormFamilyCommodity from "./FormFamilyCommodity";

const EditFamilyCommodity = (props) => {
    const {familyCommodity, setPage, updateFamilyCommodityHandle, isLoading } = props;

    return (
        <FormFamilyCommodity
            title='Editar Familia Commodity'
            setPage={setPage}
            onSubmit={updateFamilyCommodityHandle}
            isLoading={isLoading}
            commodity={familyCommodity}
        />
    )
}

export default EditFamilyCommodity