import React, {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import {SnackbarContext} from "./SnackbarContext";
export const SnackbarProvider = ({children}) => {
    const [snackbar, setSnackbar] = useState({
        show: false,
        message: '',
        severity: 'success'
    });

    return (
        <SnackbarContext.Provider value={{snackbar, setSnackbar}}>
            {children}
            <Snackbar open={snackbar.show} onClose={() => setSnackbar({show: false, message: ''})} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
}