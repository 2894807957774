import React, {useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {useSnackbar} from '../components/Snackbar/useSnackbar';
import {updateFamilyCommodity} from "../api/familyComodities";
import EditCategoryCommodity from "../components/CategoryCommodities/EditCategoryCommodity";

const CategoryCommodities = () => {
    const {setSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const [familyCommodities, setFamilyCommodities] = useState([])
    const {mutate: updateFamilyCommodityMutation, isLoading: isLoadingUpdateFamilyCommodity} = useMutation(updateFamilyCommodity, {
        onSuccess: (data) => {
            setSnackbar({show: true, message: 'Se actualizó correctamente', severity: 'success'})
            let copyFamilyCommodities = [...familyCommodities]
            const index = copyFamilyCommodities.findIndex(p => p.id === data.id)
            copyFamilyCommodities[index] = data
            setFamilyCommodities(copyFamilyCommodities)
            queryClient.invalidateQueries('familyCommodities')
        },
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    const updateFamilyCommodityHandle = (familyCommodity) => {
        const answer = window.confirm(`¿Está seguro de actualizar categoria Commodity "${familyCommodity.name}"?`);
        if (!answer) {return}
        updateFamilyCommodityMutation(familyCommodity)
    }

    return (
        <EditCategoryCommodity updateFamilyCommodityHandle={updateFamilyCommodityHandle} isLoading={isLoadingUpdateFamilyCommodity}/>
    )
}

export default CategoryCommodities;