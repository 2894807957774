import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Button, Dialog, DialogTitle, DialogContent, Typography, TextField, Box, MenuItem, InputLabel, FormControl, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from "../../firebase/firebaseApp";

const functions = getFunctions(firebaseApp);

const ListUsers = ({supplierId, users, mode}) => {
    
    const profile = useSelector(state => state.user.profile)
    const [openedDialog, setOpenedDialog] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [profileSupplier, setProfileSupplier] = useState('')
    const [listUsers, setListUsers] = useState(users)

    const addUserHandler = () => {
        setOpenedDialog(true)
    }

    const closeDialogHandler = () => {
        setOpenedDialog(false)
    }

    const handleProfileSupplier = (e) => {
        setProfileSupplier(e.target.value)
    }

    const changeNameHandler = e => {
        setName(e.target.value)
    }

    const changeEmailHandler = e => {
        setEmail(e.target.value)
    }

    const createUserHandler = e => {
        const createSupplierUser = httpsCallable(functions, 'createSupplierUser')
        const the_user = {
            name: name,
            email: email,
            profile: profileSupplier,
            role: 'supplier',
            supplierId: supplierId,
            active: true
        }
        createSupplierUser(the_user).then(response => {
            if (response.data.status === false) {
                console.log(response.data.message)
            } else {
                let newListUsers = []
                if (listUsers) {
                    newListUsers = [...listUsers]
                }
                newListUsers.push(the_user)
                setListUsers(newListUsers)
            }
            closeDialogHandler()
        });
    }

    return <Fragment>
        <TableContainer >
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listUsers && listUsers.map(user => (
                        <TableRow key={user.email}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.profile}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={openedDialog} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
            <DialogTitle><Typography sx={{fontWeight: "bold"}}>Agregar Usuario</Typography></DialogTitle>
            <DialogContent>
                <Box sx={{marginTop: "5px"}}>
                    <TextField label="Nombre" fullWidth size="small" required onChange={changeNameHandler} value={name}/>
                    <TextField label="Correo" fullWidth size="small" sx={{marginTop: "10px"}} required onChange={changeEmailHandler} value={email}/>
                    <FormControl fullWidth sx={{marginTop: '10px'}} required>
                        <InputLabel id="profileSupplier" size="small">Perfil</InputLabel>
                        <Select
                            labelId="profileSupplier"
                            value={profileSupplier}
                            label="Perfil"
                            onChange={handleProfileSupplier}
                            size="small"
                        >
                            <MenuItem value={'administrator'}>administrator</MenuItem>
                            <MenuItem value={'operator'}>operator</MenuItem>
                            <MenuItem value={'reader'}>reader</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="outlined" size="small" sx={{marginTop: "20px"}} onClick={createUserHandler}>Agregar Usuario</Button>
                    <Button variant="outlined" size="small" sx={{marginTop: "20px", marginLeft: "5px"}} onClick={closeDialogHandler} color="secondary">Cancelar</Button>
                </Box>
            </DialogContent>
        </Dialog>
        {(mode === "edit" && (profile === "administrator" || profile === "operator")) && <Button variant="outlined" sx={{marginTop: "20px"}} onClick={addUserHandler}>Agregar Usuario</Button>}
    </Fragment>
}

export default ListUsers;