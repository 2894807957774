import React from "react";
import FormFamilyCommodity from "./FormFamilyCommodity";

const NewFamilyCommodity = (props) => {
    const { setPage, createFamilyCommodityHandle, isLoading } = props;

    return (
        <FormFamilyCommodity
            title='Agregar Family Commodity'
            setPage={setPage}
            onSubmit={createFamilyCommodityHandle}
            isLoading={isLoading}
        >
        </FormFamilyCommodity>

    )
}

export default NewFamilyCommodity