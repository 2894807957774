import React, {useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField, Typography, Button, DialogActions
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useSnackbar} from "../Snackbar/useSnackbar";

const ModalPriceComodity = (props) => {
    const {setSnackbar} = useSnackbar();
    const {commodity, setShowModalPrice, updateCommodityHandle} = props;
    const pricesCommodity = commodity?.prices || []
    const [price, setPrice] = useState(0);
    const [date, setDate] = useState(new Date());

    const handleSubmit = (e) => {
        const formatDate = new Date(date).toISOString()
        let newValues = {price, date: formatDate}
        if (!price && !date) {
            setSnackbar({show: true, message: 'Todos los campos son requeridos', severity: 'error'})
            return
        }
        const copyPrices = [...pricesCommodity, newValues]
        const request = {prices: copyPrices}
        if (commodity?.id) request.id = commodity.id
        updateCommodityHandle(request)
    }

    return <Dialog open={setShowModalPrice} onClose={() => setShowModalPrice(false)}
                   sx={{'& .MuiDialog-paper': {maxWidth: '600px'}}}>
        <DialogTitle>Agregar precio commodity</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography><strong>Comodity: </strong>{commodity?.category?.name + " " + commodity?.category?.description}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography><strong>Familia: </strong>{commodity?.family}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField size="small" label="Ingrese valor" fullWidth value={price}
                               onChange={(e) => setPrice(e.target.value)}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker size="small"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        name: "startDate",
                                        format: "dd/MM/yyyy"
                                    }
                                }}
                                sx={{width: "200px"}}
                                value={date}
                                onChange={(e) => setDate(e)}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowModalPrice(false)}>Cancelar</Button>
            <Button variant='contained' onClick={() => handleSubmit()}>Guardar</Button>
        </DialogActions>
    </Dialog>
}

export default ModalPriceComodity;