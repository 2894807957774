import {db} from '../firebase/firebaseApp'
import {addDoc, collection, getDocs, query, orderBy, updateDoc, doc, deleteDoc} from "firebase/firestore";

export const getCurrencies =  async() => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "currencies"), orderBy('symbol')));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener las monedas")
    }
}

export const createCurrency = async (currency) => {
    try{
        const docRef = await addDoc(collection(db, "currencies"), currency);
        return {...currency, id: docRef.id}
    }
    catch(error){
        throw new Error("No se pudo agregar la moneda")
    }
}

export const updateCurrency = async (currency) => {
    try{
        await updateDoc(doc(db, "currencies", currency.id), currency);
        return currency
    }
    catch(error){
        throw new Error("No se pudo actualizar la moneda")
    }
}

export const deleteCurrency = async (currency) => {
    try{
        await deleteDoc(doc(db, "currencies", currency.id));
        return currency
    }
    catch(error){
        throw new Error("No se pudo eliminar la moneda")
    }
}