import {TableRow, TableCell, IconButton} from '@mui/material';
import {Delete, Edit} from "@mui/icons-material";

const RowFamilyCommodity = ({ familyCommodity, index, deleteFamilyCommodityHandle, setSelectedFamilyCommodity, setPage}) => {
    const handleEdit = () => {
        setSelectedFamilyCommodity(familyCommodity);
        setPage('editFamilyCommodity');
    }

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{familyCommodity?.name}</TableCell>
      <TableCell>
        <div className="flex-center">
            <IconButton onClick={() => handleEdit(familyCommodity)}>
                <Edit color="primary"/>
            </IconButton>
              <IconButton onClick={() => deleteFamilyCommodityHandle(familyCommodity)}>
                <Delete color="primary" />
              </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default RowFamilyCommodity;