import firebaseApp, {db} from '../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {collection, getDocs, query, where, doc, setDoc, orderBy, limit} from "firebase/firestore";
const functions = getFunctions(firebaseApp)

export const getProductsBySupplier =  async(supplierId) => {
    const supplierRef = doc(db, "suppliers", supplierId);
    const currentDate = new Date().toISOString();
    let currentPrice = null;
    let products = [];

    try {
        const querySnapshot = await getDocs(query(
            collection(db, "products"),
            where('supplier.reference', "==", supplierRef),
            orderBy('name')
        ));

        products = querySnapshot.docs.map((doc) => {
            if (doc.data().priceRanges.length > 0) {
                currentPrice = doc.data().priceRanges.find((priceRange) => priceRange.startDate <= currentDate && priceRange.endDate >= currentDate)
            }
            return {...doc.data(), id: doc.id, currentPrice: currentPrice ?? "No hay precio vigente"}

        })
        let totalProducts = await getDocs(query(
            collection(db, "products"),
            where('supplier.reference', "==", supplierRef)
        ));

        totalProducts = totalProducts.docs.length;

        return {products, totalProducts}
    }
    catch(error){
        throw new Error("No se pudo obtener los productos")
    }
}

export const getProductsByCategory =  async(family, category) => {
    try{
        const querySnapshot = await getDocs(query(
            collection(db, "products"),
            where('category', "==",category),
            where('family', "==",family)));
        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    }
    catch(error){
        throw new Error("No se pudo obtener los productos")
    }
}

export const getSuppliersByProduct =  async(product) => {
    try{
        const querySnapshot = await getDocs(query(collection(db, "products"),
            where('name', "==",product.name),
            where('reference', "==", product.reference)
        ));
        return querySnapshot.docs.map((doc) => ({...doc.data().supplier}))
    }
    catch(error){
        throw new Error("No se pudo obtener suppliers")
    }
}

export const createProduct = async (product) => {
    const createProductV2 = httpsCallable(functions, 'createProductV2');
    try{
        const result = await createProductV2(product);
        return {...product, id: result.data}
    }
    catch(error){
        throw new Error("No se pudo agregar el producto")
    }
}

export const updateProduct = async (product) => {
    try{
        product.supplier["reference"] = doc(db, "suppliers", product.supplier.id);
        await setDoc(doc(db, "products", product.id), product);
        return product
    }
    catch(error){
        console.log("error", error)
        throw new Error("No se pudo actualizar el producto")
    }
}


export const deleteProduct = async (product) => {
    const deleteProduct = httpsCallable(functions, 'deleteProduct');
    try{
        await deleteProduct({id: product.id});
        return product
    }
    catch(error){
        throw new Error("No se pudo eliminar el producto")
    }
}