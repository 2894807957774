import styles from './ChangePassword.module.css'
import { useState, Fragment } from 'react';
import alaxs_logo from '../../img/alaxs_logo.png';
import { getAuth, updatePassword, reauthenticateWithCredential, signInWithEmailAndPassword  } from "firebase/auth";
import firebaseApp from '../../firebase/firebaseApp';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const navigate = useNavigate();
    const loggedIn = useSelector(state => state.user.loggedIn);

    if (!loggedIn)
    {
        navigate("/login", {replace:true});
    }

    const submitChangePasswordHandler = async () => {
        if (password.length > 0 && passwordAgain.length > 0 && password === passwordAgain)
        {
            try
            {
                await updatePassword(user, password);
                navigate("/", {replace: true});
            }
            catch(error)
            {
                if (error.code === 'auth/requires-recent-login')
                {
                    const credentials = await signInWithEmailAndPassword(auth, user.email, currentPassword);
                    await reauthenticateWithCredential(user, credentials);
                    updatePassword(user, password);
                    navigate("/", {replace: true});
                }
                else
                {
                    setErrorMessage("Las claves no son válidas.");
                    console.log(error.code, error.message);
                }
            }
        }
        else
        {
            setErrorMessage("Las claves no son válidas.")
        }
    }

    const changePasswordHandler = (e) => {
        setPassword(e.target.value);
    }

    const changePasswordAgainHandler = (e) => {
        setPasswordAgain(e.target.value);
    }

    const changeCurrentPasswordHandler = e => {
        setCurrentPassword(e.target.value);
    }

    return <Fragment>
        <main className={styles.main}>
    <div className={styles.container}>
        <div className={styles.left} />
        <div className={styles.right}>
            <div className={styles.logo}>
                <img src={alaxs_logo} alt="Logo ALAXS" />
            </div>
            <div className={styles.controls}>
                <h5>Cambio de clave</h5>
                <input type="password" placeholder="Clave Actual" className={styles.textinput} onChange={changeCurrentPasswordHandler} />
                <input type="password" placeholder="Nueva Clave" className={styles.textinput} onChange={changePasswordHandler} />
                <input type="password" placeholder="Repita Clave" className={styles.textinput} onChange={changePasswordAgainHandler} />
                <span className={styles.error}>{errorMessage}</span>
                <button className={styles.button} onClick={submitChangePasswordHandler}>Cambiar Clave</button>
            </div>
        </div>
    </div>
    </main></Fragment>
}

export default ChangePassword;