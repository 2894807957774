import {TableRow, TableCell, IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RowUser = ({user, viewUser, disabled}) => {
    const viewUserHandler = () => {
        viewUser(user);
    }

    return <TableRow>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.profile}</TableCell>
        <TableCell>{user.active ? 'Si' : 'No'}</TableCell>
        <TableCell><IconButton onClick={viewUserHandler} disabled={disabled}><VisibilityIcon color={disabled ? '#ccc' : 'primary'} /></IconButton></TableCell>
    </TableRow>;
}

export default RowUser;