import {
    TableRow,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    TextField, TableContainer, Table, TableHead, TableBody
} from '@mui/material';
import TableHeaderCell from "../UI/TableHeaderCell";
import RowCategory from "../Categories/RowCategory";

const ViewFamily = (props) => {
    const {categories, openedDialog, setOpenedDialog, familyValue, handleChangeCategory, deleteCategoryHandler} = props

    return <Dialog open={openedDialog} onClose={() => setOpenedDialog(false)} fullWidth>
        <DialogTitle><Typography sx={{fontWeight: "bold"}}>Actualizar Familia</Typography></DialogTitle>
        <DialogContent>
            <Box sx={{marginTop: "5px"}}>
                <TextField disabled label="Nombre" fullWidth size="small" required value={familyValue}  />
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Categorias</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((category, i) =>
                                <RowCategory category={category} key={'categoryV'+i} deleteCategoryHandler={deleteCategoryHandler} index={i} handleChange={handleChangeCategory} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </DialogContent>
    </Dialog>
}

export default ViewFamily