import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    IconButton,
    CircularProgress,
    Link,
    Grid,
    Card,
    CardHeader,
    CardContent,
    TextField, FormControl, InputLabel, Select, MenuItem, Typography
} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers";
import GenericCard from "../UI/GenericCard";
import BallotIcon from '@mui/icons-material/Ballot';
import {useQuery} from "react-query";
import {getFamilyCommodities} from "../../api/familyComodities";
import {getUnitCommodities} from "../../api/unitComodities";
import {useSnackbar} from "../Snackbar/useSnackbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {parseISO} from "date-fns";

const FormCommodity = (props) => {
    const {commodity, title, setPage, onSubmit, isLoading, typeForm} = props;
    const [description, setDescription] = useState(commodity?.description || '');
    const [familyCommodities, setFamilyCommodities] = useState([])
    const [valor, setValor] = useState(commodity?.valor || '');
    const [unit, setUnit] = useState(commodity?.unit || '');
    const [date, setDate] = useState(commodity?.date ? parseISO(commodity?.date) : null);
    const [familyValue, setFamilyValue] = useState(commodity?.family || '');
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(commodity?.category || "")
    const {setSnackbar} = useSnackbar();
    const { data: dataFamilyCommodities, isLoading: isLoadingFamilyCommodities } = useQuery('familyCommodities', getFamilyCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });
    const {data: dataUnits, isLoading: isLoadingUnits} = useQuery('unitCommodities', getUnitCommodities, {
        staleTime: 3600000,
        onError: (error) => {
            setSnackbar({show: true, message: error?.message, severity: 'error'})
        }
    });

    useEffect(() => {
        if (dataFamilyCommodities) {
            setFamilyCommodities(dataFamilyCommodities)
        }

        if (familyValue !== "" && dataFamilyCommodities?.length > 0) {
            const copyDataGeneralFamilies = [...dataFamilyCommodities]
            const findFamily = copyDataGeneralFamilies.find(family => family.name === familyValue)
            setCategories(findFamily.categories)
        }
    }, [dataFamilyCommodities]);

    const handleSubmit = () => {
        const formatDate = new Date(date).toISOString()
        let values = {description, unit, family: familyValue, category}
        if(typeForm === 'new') values.prices = [{price: valor, date: formatDate}]
        if(commodity?.id) values.id = commodity.id
        onSubmit(values)
    }

    const handleChangeCategory = (e, action) => {
        setCategory(action.props.data)
    }

    const handleFamily = (e, action) => {
        if (familyValue !== '') {
            setCategories([])
        }
        setFamilyValue(e.target.value);
        setCategories(action?.props?.data?.categories || []);
    };

    return (
        <>
            <Link className='mt-12' component='button' onClick={() => setPage('listCommodities')} sx={{marginBottom: '20px'}}>{'< Regresar'}</Link>
            <GenericCard title={title}>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><FilterAltIcon /></IconButton>
                                    }
                                    title="Familia"
                                />
                                <CardContent>
                                    {isLoadingFamilyCommodities ?
                                        <><CircularProgress/><span>Cargando información...</span></> :
                                        <FormControl fullWidth>
                                            <InputLabel id="family" size="small">Familia</InputLabel>
                                            <Select
                                                labelId="family"
                                                id="family-select"
                                                value={familyValue}
                                                label="Familia"
                                                onChange={handleFamily}
                                                size="small"
                                            >
                                                {familyCommodities.map((family, i) => (
                                                    <MenuItem key={'family' + i} data={family} value={family.name}>{family.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{maxWidth: 345}}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon/></IconButton>}
                                    title="Commodity"
                                />
                                <CardContent>
                                    <FormControl fullWidth required>
                                        <InputLabel id="Categoría" size="small">Seleccione</InputLabel>
                                        <Select
                                            id="Category-select"
                                            value={category.name}
                                            label="Categoría"
                                            onChange={handleChangeCategory}
                                            size="small"
                                        >
                                            {categories.map((categoryI, i) =>
                                                <MenuItem key={'catE' + i} value={categoryI.name} data={categoryI}>{categoryI.name}</MenuItem>)}
                                        </Select>
                                        <Typography variant="caption" sx={{ marginTop: '10px'}}>Descripción: {category.description}</Typography>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Descripción"
                                />
                                <CardContent>
                                    <TextField size="small" label="Ingrese descripción" fullWidth value={description} sx={{ marginTop: '10px'}} onChange={(e) => setDescription(e.target.value)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        {typeForm === 'new' &&
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Valor"
                                />
                                <CardContent>
                                    <TextField size="small" label="Ingrese valor" fullWidth value={valor} sx={{ marginTop: '10px'}} onChange={(e) => setValor(e.target.value)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Unidad"
                                />
                                <CardContent>
                                    <FormControl fullWidth>
                                        <InputLabel id="unit" size="small">Unidad</InputLabel>
                                        <Select
                                            labelId="unit"
                                            id="unit-select"
                                            value={unit}
                                            label="Unidad"
                                            onChange={(e) => setUnit(e.target.value)}
                                            size="small"
                                        >
                                            {isLoadingUnits ?
                                                <MenuItem value=""><em>Cargando "Unidades"...</em></MenuItem> :
                                                dataUnits?.map((unit, i) => <MenuItem key={'unit' + i}
                                                                                      value={unit.symbol}>{unit.symbol}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        {typeForm === 'new' &&
                        <Grid item xs={12} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                    sx={{backgroundColor: "#eceff1"}}
                                    avatar={<IconButton aria-label="settings"><BallotIcon /></IconButton>}
                                    title="Fecha"
                                />
                                <CardContent>
                                    <DatePicker size="small"
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        name: "startDate",
                                                        format: "dd/MM/yyyy"
                                                    }
                                                }}
                                                sx={{width: "200px"}}
                                                value={date}
                                                onChange={(e) => setDate(e)}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        }
                    </Grid>
                    {isLoading ?
                        <CircularProgress />:
                        <Button variant="outlined"  sx={{marginTop: "40px", marginRight: "5px"}} onClick={handleSubmit} disabled={!description && !unit}>Guardar Commodity</Button>
                    }
                </Box>
            </GenericCard>
        </>

    )
}

export default FormCommodity