import {TableRow, TableCell, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";

const RowCompany = ({company, index, viewCompany, disabled, deleteCompanyHandle}) => {
    const {name, Billing_Country, Industry, Categories, Q_Volume, updateInfo} = company;

   const getDate = () => {
       let date = 'Sin actualizar'
       if(updateInfo){
           date = 'Actualizado: ' + new Date(updateInfo).toLocaleString('es-ES', {timeZone: 'UTC'})
       }
         return date
   }

    const viewCompanyHandler = () => {
        viewCompany(company);
    }

    return <TableRow>
        <TableCell>{++index}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{Billing_Country ?? '-'}</TableCell>
        <TableCell>{Industry ?? '-'}</TableCell>
        <TableCell>{Categories ?? '-'}</TableCell>
        <TableCell>{Q_Volume ?? '-'}</TableCell>
        <TableCell><p style={{fontSize: '11px'}}>{getDate()}</p></TableCell>
        <TableCell>
            <span style={{display: 'flex'}}>
                <IconButton onClick={viewCompanyHandler} disabled={disabled}><EditIcon color={disabled ? '#ccc' : 'primary'} /></IconButton>
                <IconButton onClick={() => deleteCompanyHandle(company)}><DeleteIcon/>
                </IconButton>
            </span>
        </TableCell>
    </TableRow>;
}

export default RowCompany;