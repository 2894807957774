import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import Message from '../pages/Message';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PasswordIcon from '@mui/icons-material/Password';
import GroupIcon from '@mui/icons-material/Group';
import FactoryIcon from '@mui/icons-material/Factory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { Container } from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BallotIcon from '@mui/icons-material/Ballot';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const emailVerified = useSelector(state => state.user.emailVerified);
  
  const role = useSelector(state => state.user.role);
  const active = useSelector(state => state.user.active);
  const profile = useSelector(state => state.user.profile);
  const subRole = useSelector(state => state.user.subrole);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const userLoggedIn = useSelector(state => state.user.loggedIn);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changePasswordHandler = () => {
    setAnchorEl(null);
    navigate("/changepassword", { replace:true });
  }

  if (role !== undefined && role !== "admin")
  {
    return <Message url="/logout" title="Acceso denegado" message="Su cuenta no tiene acceso al sistema." buttonTitle="Retornar"/>
  }

  if (active !== undefined && !active)
  {
    return <Message url="/logout" title="Cuenta no activa" message="Su cuenta ha sido desactivada." buttonTitle="Retornar"/>
  }

  if (profile !== undefined && profile !== "administrator" && profile !== "operator" && profile !== "reader")
  {
    return <Message url="/logout" title="Sin perfil" message="Su cuenta no tiene asociada un perfil." buttonTitle="Salir" />
  }

  return <Fragment>
    {/*{emailVerified !== undefined && !emailVerified && <Navigate to="/emailnotverified" replace={true}/>}*/}
    {userLoggedIn !== undefined && !userLoggedIn && <Navigate to="login" />}
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1}}>
            ALAXS Platform
          </Typography>
          <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={changePasswordHandler}>
                  <ListItemIcon>
                        <PasswordIcon />
                  </ListItemIcon>
                  <ListItemText>Cambiar clave</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/logout">
                  <ListItemIcon>
                        <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText>Salir</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {profile === 'administrator' && <ListItem key="Users" disablePadding>
              <ListItemButton component={Link} to="/users">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
              </ListItemButton>
            </ListItem>}
            {profile === 'administrator' && <ListItem key="Suppliers" disablePadding>
              <ListItemButton component={Link} to="/suppliers">
                <ListItemIcon>
                  <FactoryIcon />
                </ListItemIcon>
                <ListItemText primary="Suppliers" />
              </ListItemButton>
            </ListItem>}
            {profile === 'administrator' && <ListItem key="Familias" disablePadding>
                <ListItemButton component={Link} to="/families">
                    <ListItemIcon>
                        <BallotIcon />
                    </ListItemIcon>
                    <ListItemText primary="Familias  & Combos" />
                </ListItemButton>
            </ListItem>}
            <ListItem key="Filtros" disablePadding>
                <ListItemButton component={Link} to="/filters">
                    <ListItemIcon>
                        <FilterAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Filtros" />
                </ListItemButton>
            </ListItem>
            <ListItem key="Products" disablePadding>
                <ListItemButton component={Link} to="/products">
                    <ListItemIcon>
                        <ShoppingBagIcon />
                    </ListItemIcon>
                    <ListItemText primary="Productos" />
                </ListItemButton>
            </ListItem>
            <ListItem key="Pedidos" disablePadding>
                <ListItemButton component={Link} to="/orders">
                    <ListItemIcon>
                        <LocalGroceryStoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pedidos" />
                </ListItemButton>
            </ListItem>
            {subRole === 'zohoAdmin' && <ListItem key="Zoho-users" disablePadding>
                <ListItemButton component={Link} to="/zoho-users">
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Zoho Clientes" />
                </ListItemButton>
            </ListItem>}
            <ListItem key="Commodities" disablePadding>
              <ListItemButton component={Link} to="/commodities">
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Commodities" />
              </ListItemButton>
            </ListItem>

           {/* <ListItem key="TradingPlatform" disablePadding>
                <ListItemButton component={Link} to="/tradingplatform">
                    <ListItemIcon>
                        <ReceiptOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trading Platform" />
                </ListItemButton>
            </ListItem>*/}
        </List>
        <Divider />
        <List>
            <ListItem key="Quit" disablePadding>
                <ListItemButton component={Link} to="/logout">
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Salir" />
                </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Container maxWidth='lg'>
          {props.children}
        </Container>
      </Main>
    </Box>
    </Fragment>;
}